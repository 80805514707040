import React, { FC } from "react";

import mediaOverlayService from "@/services/MediaOverlayService";
import sceneService, {
  TMediaArea,
  TMediaAreaData,
} from "@/services/SceneService";
import { downloadMediaAsset } from "@/utils/Azure";

import { colors } from "@/styles/colors";

import {
  StyledMediaItemDefault,
  Icon,
  Content,
  Title,
  Meta,
  Link,
  DownloadButton,
} from "./styles";

import DownloadIcon from "~/public/assets/icons/Download.svg";

interface ItemProps {
  item: TMediaAreaData;
  icon: React.SVGProps<SVGSVGElement>;
  iconColor?: string;
}

const formatSubtitle = (type: TMediaArea) => {
  if (type === "scene_video") return "Video";
  if (type === "scene_stream") return "Stream";
  return type;
};

const MediaItemDefault: FC<ItemProps> = ({
  item,
  icon,
  iconColor = colors.grey.dark.hex,
}) => {
  const handleClick = () => mediaOverlayService.getState().open(item);

  if (!sceneService.getState().isMediaAreaDataValid(item)) return null;

  return (
    <StyledMediaItemDefault>
      <Link onClick={handleClick} title={item.title}>
        <Icon color={iconColor}>{icon}</Icon>
        <Content>
          <Title>{item.title}</Title>
          <Meta>{formatSubtitle(item.type)}</Meta>
        </Content>
      </Link>
      <DownloadButton
        title={
          item.downloadable
            ? "Download asset"
            : "Download of this file is restricted"
        }
        disabled={!item.downloadable}
        onClick={() => downloadMediaAsset(item)}
      >
        <DownloadIcon />
      </DownloadButton>
    </StyledMediaItemDefault>
  );
};

export default MediaItemDefault;
