import styled from "styled-components";

export const StyledChatPanelGroupList = styled.section``;

export const ChatPanelGroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Ul = styled.ul`
  display: grid;
  gap: 1rem;
`;
