import React, { useState, FC, useRef } from "react";

import PopoverLinks from "@/components/Dom/Popover/PopoverLinks";
import { TPopoverLinks, TPopupPosition } from "@/components/Dom/Popover/types";

import useClickOutside from "@/utils/UseClickOutside";

import { colors } from "@/styles/colors";

import { StyledPopover, StyledOptionButton } from "./styles";

import Dots from "~/public/assets/icons/3Dots.svg";

interface IProps {
  links: Array<TPopoverLinks>;
  popupPosition: TPopupPosition;
}

const Popover: FC<IProps> = ({ links, popupPosition = "top-right" }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useRef();

  const toggle = () => setIsActive((state) => !state);
  const close = () => setIsActive(false);

  useClickOutside(ref, close);

  return (
    <StyledPopover ref={ref}>
      <PopoverLinks
        links={links}
        isActive={isActive}
        position={popupPosition}
        onPress={() => setIsActive(false)}
      />
      <StyledOptionButton
        icon={<Dots />}
        iconWidth={4}
        iconHeight={14}
        iconColor={isActive ? colors.white.default : colors.grey.normal.hex}
        backgroundColor={
          isActive ? colors.tangerine.normal.hex : colors.grey.lightest.hex
        }
        big
        onClick={toggle}
        isActive={isActive}
      />
    </StyledPopover>
  );
};

export default Popover;
