import styled, { css } from "styled-components";

export const StyledVrButtonWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 25%;
  width: fit-content;
  height: fit-content;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;

  ${(props) =>
    !props.isVisible &&
    css`
      transform: scale(0);
    `}
`;
