import styled from "styled-components";

import AspectRatio from "@/components/Dom/Common/AspectRatio";
import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";

import { colors } from "@/styles/colors";

export const StyledSpacePanel = styled(FlexAndGrow)`
  width: var(--mainNav-panel-width);
`;

export const StyledAspectRatio = styled(AspectRatio)`
  background-color: ${colors.grey.lightest.hex};
  border-radius: 1rem;
  margin-bottom: 1.6rem;

  svg {
    width: 2.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.grey.normal.hex};
  }
`;
