import styled from "styled-components";

import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";

export const StyledUserSearchPanel = styled(FlexAndGrow)`
  width: var(--mainNav-panel-width);
`;
export const ButtonContainer = styled.div`
  padding: 1rem var(--mainNav-overview-panel-padding)
    var(--mainNav-overview-panel-padding);

  & > button {
    width: 100%;
  }
`;
