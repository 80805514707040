import { FC, HTMLAttributes, useEffect, useState } from "react";

import UserListItem from "@/components/Dom/Common/UserList/UserListItem";
import { IProps as IUserSearchPanelProps } from "@/components/Dom/OverviewPanel/Panels/UserSearchPanel";

import moduleService from "@/services/ModuleService";
import spaceService from "@/services/SpaceService";
import { TUser } from "@/services/UserService/types";

import { StyledUserList } from "./styles";

type TUserList = HTMLAttributes<HTMLButtonElement>;

interface IProps extends Pick<IUserSearchPanelProps, "activeUsers"> {
  users: Array<TUser>;
  handleSelection?: (selectedUsers: Set<number>) => void;
  onlyInCurrentModule: boolean;
}

const UserList: FC<IProps & TUserList> = ({
  activeUsers,
  users,
  onlyInCurrentModule,
  handleSelection,
}) => {
  const [selectedUsers, setSelectedUsers] = useState(new Set<number>());
  const currentSpaceId = spaceService((state) => state.currentSpaceId || 0);
  const currentModuleId = moduleService.getState().currentModuleId;

  const handleClick = (userId: number) => {
    if (selectedUsers.has(userId)) {
      setSelectedUsers((prev) => {
        prev.delete(userId);
        return new Set([...prev]);
      });
    } else {
      setSelectedUsers((prev) => new Set(prev.add(userId)));
    }
  };

  useEffect(() => {
    handleSelection && handleSelection(selectedUsers);
  }, [selectedUsers]);

  const isDisabled = (user: TUser) => {
    // check if user is in the current space
    if (user.currentSpaceId !== currentSpaceId) return true;

    if (onlyInCurrentModule) {
      // check if user is in the current module
      if (user.currentModuleId !== currentModuleId) return true;
    }
    // if user is in current space check if its active user
    return Boolean(activeUsers && !activeUsers.includes(user.id));
  };
  return (
    <StyledUserList>
      {users.map((user) => (
        <UserListItem
          key={user.id}
          user={user}
          handleClick={handleClick}
          isDisabled={isDisabled(user)}
          isActive={selectedUsers.has(user.id)}
        />
      ))}
    </StyledUserList>
  );
};

export default UserList;
