import ChatContent from "@/components/Dom/Chat/ChatContent";
import ChatInput from "@/components/Dom/Chat/ChatInput";
import ChatIcon from "@/components/Dom/Common/Tabs/Icons/Chat.svg";
import InfoIcon from "@/components/Dom/Common/Tabs/Icons/Info.svg";
import MediaIcon from "@/components/Dom/Common/Tabs/Icons/Media.svg";
import ChatPanelProfile from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfile";
import MediaTab from "@/components/Dom/OverviewPanel/PanelContent/MediaTab";
import ModuleInfo from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfo";
import { StyledChatInput } from "@/components/Dom/OverviewPanel/Panels/ChatPanel/styles";

export const spaceSections = [
  {
    icon: <InfoIcon />,
    body: <ModuleInfo key={`space-panel-section-${0}`} />,
    footer: null,
  },
  {
    icon: <ChatIcon />,
    body: <ChatContent key={`space-panel-section-${1}`} />,
    footer: <ChatInput key={`space-panel-section-footer-${1}`} />,
  },
  {
    icon: <MediaIcon />,
    body: <MediaTab key={`space-panel-section-${2}`} />,
    footer: null,
  },
];

export const chatsSection = [
  {
    icon: <InfoIcon />,
    body: <ChatPanelProfile key={`chat-panel-section-${0}`} />,
    footer: null,
  },
  {
    icon: <ChatIcon />,
    body: <ChatContent key={`chat-panel-section-${1}`} />,
    footer: <StyledChatInput key={`chat-panel-section-footer-${1}`} />,
  },
];
