import AddPeopleButton from "@/components/Dom/VideoChat/Common/AddPeopleButton";

import { StyledParticipantsControls } from "./styles";

const ParticipantsControls = () => {
  return (
    <StyledParticipantsControls>
      <AddPeopleButton />
    </StyledParticipantsControls>
  );
};

export default ParticipantsControls;
