import React, { useEffect, useState } from "react";

import { StyledNewMessageCount } from "../../MainNavSection/MainNavSectionCTA/styles";

import navService, { ENavSection } from "@/services/NavService";

import { colors } from "@/styles/colors";
import { MainNavSubtitle, MainNavTitle } from "@/styles/common";

import { StyledAvatar, StyledContent, StyledNavItem } from "./styles";

export interface IProps {
  elemToShow: React.ReactNode | ((props: any) => React.ReactNode);
  id: number;
  isActive?: boolean;
  isPanelOpen?: boolean;
  newMessageCount?: number;
  sectionTitle?: string;
  title: String | React.ReactNode | null;
  subtitle?: String | null;
  onClick: () => void;
  onHoverChange?: (isHovered: boolean) => void;
  section: ENavSection;
  expanded?: boolean;
}

const NavItem: React.FC<IProps> = ({
  id,
  elemToShow,
  isActive = null,
  isPanelOpen,
  newMessageCount,
  sectionTitle,
  title,
  subtitle,
  onClick,
  onHoverChange,
  section,
  expanded,
  ...others
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const currentOverviewPanel = navService.getState().currentOverviewPanel;

  useEffect(() => {
    if (onHoverChange) onHoverChange(isHovered);
    navService.getState().toggleNavSectionActiveItem(isHovered, section);
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setIsHovered(false);
    onClick?.();
  };

  return (
    <StyledNavItem
      data-hovered={isHovered}
      data-overview-open={currentOverviewPanel !== null}
      onMouseLeave={handleMouseLeave}
      {...others}
    >
      <StyledAvatar
        isActive={isActive}
        isPanelOpen={isPanelOpen}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
      >
        {elemToShow}
        {newMessageCount ? (
          newMessageCount > 0 && (
            <StyledNewMessageCount>{newMessageCount}</StyledNewMessageCount>
          )
        ) : (
          <></>
        )}
      </StyledAvatar>
      <StyledContent onClick={handleClick}>
        {expanded && sectionTitle && !currentOverviewPanel && (
          <MainNavTitle
            style={{
              color: colors.grey.normal.hex,
              marginTop: -12,
              marginBottom: 2,
              fontSize: 11,
            }}
            bold
          >
            {sectionTitle}
          </MainNavTitle>
        )}

        <MainNavTitle bold subtitle={subtitle}>
          {title}
        </MainNavTitle>
        {subtitle && <MainNavSubtitle>{subtitle}</MainNavSubtitle>}
      </StyledContent>
    </StyledNavItem>
  );
};

export default NavItem;
