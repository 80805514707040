import { Fragment, useMemo } from "react";

import ChatBubble from "@/components/Dom/Chat/ChatBubble";

import chatService, { TMessage } from "@/services/ChatService";
import { formatGroupedMessageDate } from "@/utils/Date";

import { StyledChatContent, StyledDateTitle } from "./styles";

const ChatContent = () => {
  const messages = chatService((state) => state.messages);

  const groupedMessages = useMemo(() => {
    return messages.reduce((acc, curr: TMessage) => {
      const currentDay = curr.created_at.split("T")[0];
      acc[currentDay] = acc[currentDay] || [];
      acc[currentDay].push(curr);
      return acc;
    }, Object.create(null));
  }, [messages]);

  return (
    <StyledChatContent>
      {groupedMessages &&
        Object.entries(groupedMessages).map(([date, messages]) => (
          <Fragment key={date}>
            <StyledDateTitle>{formatGroupedMessageDate(date)}</StyledDateTitle>
            {(messages as TMessage[]).map((message) => (
              <ChatBubble key={message.id} message={message} />
            ))}
          </Fragment>
        ))}
    </StyledChatContent>
  );
};

export default ChatContent;
