import * as React from "react";

import {
  StyledModule,
} from "@/components/Dom/Common/ModuleImage/styles";
import { StyledAvatar } from "@/components/Dom/Common/AvatarImage/styles";

import { TModule } from "@/services/ModuleService/types";
import { useSasParams } from "@/utils/Azure";
import { getImageUrl } from "@/utils/Strapi";

import Lock from "~/public/assets/icons/Lock.svg";
import { profileColor } from "@/utils/User";

type TModuleBlueprintImage = {
  module: TModule;
  title?: string;
};

const ModuleImage = ({ module, title }: TModuleBlueprintImage) => {
  const imageUrl = getImageUrl(module.previewImage, "thumbnail");
  const initials = (title || module.title)
    .split(" ")
    .map((word, index) => {
      if (index > 1) return;
      return word[0];
    })
    .join("");

  const { urlWithParams: bgImageUrlWithParams } = useSasParams(imageUrl);

  return (
    <StyledModule
      aria-label={`picture of ${module.title}`}
      canAccess={module.canAccess}
      id={module.id}
    >
      {module.canAccess ? (
        <StyledAvatar theme={profileColor(module.id)}>
          <span aria-hidden>{initials}</span>
          {bgImageUrlWithParams && <img src={bgImageUrlWithParams} />}
        </StyledAvatar>
      ) : (
        <Lock />
      )}
    </StyledModule>
  );
};

export default ModuleImage;
