import styled, { css } from "styled-components";

import TextArea from "@/components/Dom/Common/TextArea";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";

export const StyledWrapper = styled.div`
  margin-bottom: var(--mainNav-overview-panel-padding);
  margin-left: var(--mainNav-overview-panel-padding);
  margin-right: var(--mainNav-overview-panel-padding);
  /* division by two was deliberate, just a proportional way to make it smaller */
  margin-top: calc(var(--mainNav-overview-panel-padding) / 2);
`;

export const StyledInput = styled.div`
  --input-height: 4rem;

  align-items: end;
  background-color: ${colors.grey.lightest.hex};
  border-radius: var(--input-chat-radius);
  display: flex;

  &[data-emoji-open="true"] {
    max-height: var(--input-height);
    overflow: hidden;
  }
`;

export const StyledTextArea = styled(TextArea)`
  flex-grow: 1;
  z-index: 6;
`;

const commonButtonStyles = css`
  align-items: center;
  border-radius: 100%;
  height: var(--input-height);
  display: flex;
  justify-content: center;
  position: relative;
  width: var(--input-height);

  &:before {
    content: "";
    border-radius: 100%;
    height: calc(var(--input-height) - 1rem);
    left: 50%;
    position: absolute;
    top: 50%;
    transition: background 0.2s linear;
    transform: translate(-50%, -50%);
    width: calc(var(--input-height) - 1rem);
  }

  > svg {
    transition: color 0.25s linear;
    z-index: 1;
  }
`;

export const StyledEmojiButton = styled.button`
  ${commonButtonStyles}
  color: ${colors.grey.dark.hex};

  &[data-open="true"] {
    color: ${colors.white.default};
    &:before {
      background-color: ${colors.tangerine.normal.hex};
    }
  }

  &[data-open="false"] {
    ${Hover(css`
      color: ${colors.white.default};
      &:before {
        background-color: ${colors.tangerine.normal.hex};
      }
    `)}
  }

  > svg {
    height: 1.2rem;
    width: 1.2rem;
  }
`;

export const StyledButton = styled.button`
  ${commonButtonStyles}
  color: ${colors.orange.hex};

  &:not(:disabled) {
    ${Hover(css`
      color: ${colors.tangerine.normal.hex};
      &:before {
        background-color: ${colors.white.default};
      }
    `)}
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.grey.lighter.hex};
  }

  > svg {
    height: 0.8rem;
    width: 1rem;
  }
`;
