import { UseFormReturn } from "react-hook-form";

import soundService from "@/services/AudioService";

import { MidHeadline } from "@/styles/typography";

import { StyledCheckbox, StyledProfilePanelSettingsSection } from "./styles";

type TProfilePanelSettingsSection = {
  title: string;
  form: UseFormReturn;
};

const ProfilePanelSettingsSection = ({
  title,
  form,
}: TProfilePanelSettingsSection) => {
  const isMuted = soundService((state) => state.settings.muted);
  const audioButtonText = isMuted ? "Disabled" : "Enabled";

  return (
    <StyledProfilePanelSettingsSection>
      <MidHeadline>{title}</MidHeadline>

      <StyledCheckbox
        uniqueId="AudioButton"
        register={form.register("AudioButton")}
        label={`Audio ${audioButtonText}`}
        changeHandler={soundService.getState().toggleMuted}
        value={!isMuted}
      />
    </StyledProfilePanelSettingsSection>
  );
};

export default ProfilePanelSettingsSection;
