import { FC } from "react";

import { StyledTile } from "./styles";

interface ITile {
  imageUrl: string;
  size?: number;
}

const Tile: FC<ITile> = ({ imageUrl, size = 1, children, ...others }) => {
  return (
    <StyledTile imageUrl={imageUrl} size={size} {...others}>
      {children}
    </StyledTile>
  );
};

export default Tile;
