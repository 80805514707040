import styled from "styled-components";

export const StyledSection = styled.div`
  --gap: 1.6rem;

  align-items: start;
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
`;
