import React, { useEffect, useMemo, useState } from "react";

import Search from "@/components/Dom/Common/Search";
import MediaItemDefault from "@/components/Dom/OverviewPanel/PanelContent/MediaTab/Components/MediaItemDefault";
import MediaItemIframe from "@/components/Dom/OverviewPanel/PanelContent/MediaTab/Components/MediaItemIframe";
import MediaItemLink from "@/components/Dom/OverviewPanel/PanelContent/MediaTab/Components/MediaItemLink";
import ImageIcon from "@/components/Dom/OverviewPanel/PanelContent/MediaTab/Icons/Image.svg";
import LinkIcon from "@/components/Dom/OverviewPanel/PanelContent/MediaTab/Icons/Link.svg";
import PdfIcon from "@/components/Dom/OverviewPanel/PanelContent/MediaTab/Icons/Pdf.svg";
import VideoIcon from "@/components/Dom/OverviewPanel/PanelContent/MediaTab/Icons/Video.svg";

import moduleService from "@/services/ModuleService";
import navService, { EOverviewPanel } from "@/services/NavService";
import { TMediaAreaData } from "@/services/SceneService";

import { colors } from "@/styles/colors";

import { MediaItemList, SearchWrapper } from "./styles";

const MediaTab = () => {
  const { module } = navService((state) => ({
    module: state.overviewPanels.get(EOverviewPanel.SPACES),
  }));
  const [media, setMedia] = useState<Array<TMediaAreaData>>([]);
  const [searchText, setSearchText] = useState<string>("");

  useEffect(() => {
    if (!module) return;

    const currentModule = moduleService.getState().getModuleById(module.id);
    if (!currentModule) return;

    const { mediaAreas } = currentModule;
    if (mediaAreas?.length) setMedia(mediaAreas);
  }, [module]);

  const filteredMedia = useMemo(() => {
    if (searchText.length < 1) return media;

    return media.filter(
      (item) =>
        item.title &&
        item.title.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [media, searchText]);

  return (
    <>
      <SearchWrapper>
        <Search text={searchText} setSearchedText={setSearchText} />
      </SearchWrapper>

      <MediaItemList>
        {filteredMedia.map((item) => {
          switch (item.type) {
            case "image":
              return (
                <MediaItemDefault
                  key={item.id}
                  item={item as TMediaAreaData}
                  icon={<ImageIcon />}
                />
              );
            case "video":
            case "immersive_video":
              return (
                <MediaItemDefault
                  key={item.id}
                  item={item as TMediaAreaData}
                  icon={<VideoIcon />}
                  iconColor={colors.red.normal.hex}
                />
              );
            case "pdf":
              return (
                <MediaItemDefault
                  key={item.id}
                  item={item as TMediaAreaData}
                  icon={<PdfIcon />}
                  iconColor={colors.rose.hex}
                />
              );

            case "scene_stream":
              return (
                <MediaItemDefault
                  key={item.id}
                  item={item as TMediaAreaData}
                  icon={<VideoIcon />}
                  iconColor={colors.red.normal.hex}
                />
              );
            case "scene_video":
              return (
                <MediaItemDefault
                  key={item.id}
                  item={item as TMediaAreaData}
                  icon={<VideoIcon />}
                  iconColor={colors.red.normal.hex}
                />
              );

            case "link":
              return (
                <MediaItemLink
                  key={item.id}
                  item={item as TMediaAreaData}
                  icon={<LinkIcon />}
                />
              );
            case "iframe":
              return (
                <MediaItemIframe
                  key={item.id}
                  item={item as TMediaAreaData}
                  icon={<ImageIcon />}
                />
              );
            default:
              return <></>;
          }
        })}
      </MediaItemList>
    </>
  );
};

export default MediaTab;
