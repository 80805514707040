import styled from "styled-components";

import { colors } from "@/styles/colors";

export const StyledPopupOverlay = styled.dialog`
  z-index: 10;
  background-color: initial;
  overflow: visible;

  &::backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(${colors.black.rgb}, 0.3);
  }
`;
