import CallEndButton from "@/components/Dom/VideoChat/Common/CallEndButton";
import CameraButton from "@/components/Dom/VideoChat/Common/CameraButton";
import MicrophoneButton from "@/components/Dom/VideoChat/Common/MicrophoneButton";
import ScreenshareButton from "@/components/Dom/VideoChat/Common/ScreenshareButton";

import { StyledVideoFullscreenControls } from "./styles";

type TVideoFullscreenControls = {
  isVisible: boolean;
};

const VideoFullscreenControls = ({ isVisible }: TVideoFullscreenControls) => {
  return (
    <StyledVideoFullscreenControls isVisible={isVisible}>
      <CameraButton />

      <MicrophoneButton />

      <CallEndButton />

      <ScreenshareButton />
    </StyledVideoFullscreenControls>
  );
};

export default VideoFullscreenControls;
