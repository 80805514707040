import LoadingTitle from "@/components/Dom/LoadingScreen/LoadingTitle";

import {
  StyledLoadingScreenSlide,
  LogoWrapper,
  ContentWrapper,
} from "./styles";

import LogoSvg from "~/public/assets/icons/PwC_wireframe.svg";

const LoadingScreenSlide = ({ viewState, backgroundColor, textColor }) => {
  return (
    <StyledLoadingScreenSlide
      backgroundColor={backgroundColor}
      viewState={viewState}
    >
      <ContentWrapper viewState={viewState}>
        <LoadingTitle viewState={viewState} textColor={textColor} />

        <LogoWrapper textColor={textColor}>
          <LogoSvg />
        </LogoWrapper>
      </ContentWrapper>
    </StyledLoadingScreenSlide>
  );
};

export default LoadingScreenSlide;
