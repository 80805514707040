import React, { ReactElement } from "react";

import SectionIcon from "./SectionIcon";

import navService, { ENavSection } from "@/services/NavService";

import { StyledButton, StyledCta } from "./styles";

type TMainNavSectionCTA = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
  icon: ReactElement;
  newMsgCount: number;
  section: ENavSection;
  hasItems?: boolean;
  onClick?: () => void;
}

const MainNavSectionCTA: React.FC<IProps & TMainNavSectionCTA> = ({
  icon,
  newMsgCount,
  section,
  hasItems = true,
  onClick,
}) => {
  const handleCTAMouseEnter = () => {
    if (hasItems) return;
    navService.getState().openNavSection(ENavSection.CHATS);
  };

  const handleMouseEnter = () => {
    if (navService.getState().navSectionsExpanded.get(section))
      navService.getState().openNavSection(section);
  };

  const handleCTAClick = () => {
    if (hasItems) onClick?.();
  };

  return (
    <StyledCta onMouseEnter={handleCTAMouseEnter}>
      <StyledButton
        disabled={!hasItems}
        aria-controls={`collapse-${section}`}
        onClick={handleCTAClick}
        onMouseEnter={handleMouseEnter}
      >
        <SectionIcon
          icon={icon}
          hasItems={hasItems}
          newMessageCount={newMsgCount}
        />
      </StyledButton>
    </StyledCta>
  );
};

export default MainNavSectionCTA;
