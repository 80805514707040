import React from "react";

import AvatarConfigurator from "@/components/Dom/AvatarConfigurator";

import { StyledAvatarPanel, Header } from "./styles";

type TAvatarPanel = React.HTMLAttributes<HTMLDivElement>;

interface IProps {}

const AvatarPanel: React.FC<IProps & TAvatarPanel> = () => {
  return (
    <StyledAvatarPanel
      header={<Header title="Avatar Creator" />}
      body={<AvatarConfigurator />}
    />
  );
};

export default AvatarPanel;
