import styled, { css } from "styled-components";

import { ease } from "@/styles/ease";

export const StyledSetupImageGallery = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: opacity 0.5s linear;

  ${(props) =>
    !props.isVisible &&
    css`
      pointer-events: none;
      opacity: 0;
    `}
`;

export const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: opacity 0.4s linear, transform 0.8s ${ease.outCubic};

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0;
      transform: scale(1.05);
    `}
`;
