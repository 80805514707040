import * as React from "react";
import { UseFormReturn } from "react-hook-form";

import { toKebabCase } from "@/utils/Text";

import { colors } from "@/styles/colors";
import { MidHeadline } from "@/styles/typography";

import {
  StyledProfilePanelSection,
  Ul,
  Title,
  Input,
  StyledText,
} from "./styles";

type TProfileContent = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
  data: {
    headline: string;
    value: string;
    disabled?: boolean;
  }[];
  title: string;
  form?: UseFormReturn;
  textColor?: string;
}

const ProfilePanelSection: React.FC<IProps & TProfileContent> = ({
  data,
  title,
  form,
  textColor,

  ...others
}) => {
  return (
    <StyledProfilePanelSection {...others}>
      <MidHeadline>{title}</MidHeadline>

      <Ul>
        {data.map(({ headline, value, disabled = false }, index) => (
          <li key={index}>
            {form ? (
              <Input
                register={form.register(toKebabCase(headline).replace("-", ""))}
                borderColor={colors.grey.lighter.hex}
                textColor={textColor}
                placeholder={headline}
                defaultValue={value}
                disabled={disabled}
              />
            ) : (
              <>
                <Title>{headline}</Title>
                <StyledText bold textColor={textColor}>
                  {value || "Not informed"}
                </StyledText>
              </>
            )}
          </li>
        ))}
      </Ul>
    </StyledProfilePanelSection>
  );
};

export default ProfilePanelSection;
