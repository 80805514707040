import React from "react";

import { Text } from "@/styles/typography";

import { StyledPlusButton, StyledMessage } from "./styles";

import { default as PlusIcon } from "~/public/assets/icons/Plus.svg";

interface IProps {
  title: string;
}

const ChatsEmptyTitle: React.FC<IProps> = ({ title }) => (
  <>
    <Text size="mid">{title}</Text>
    <StyledMessage>
      Use{" "}
      <StyledPlusButton>
        <PlusIcon />
      </StyledPlusButton>{" "}
      to start a chat
    </StyledMessage>
  </>
);

export default ChatsEmptyTitle;
