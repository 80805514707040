import styled, { keyframes } from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
const hideToRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-10%);
  }
`;

export const StyledOverviewPanel = styled.div`
  --overview-radius: var(--mainNav-overview-panel-border-radius);
  background-color: ${colors.white.default};
  border-radius: 0rem var(--overview-radius) var(--overview-radius);
  grid-area: overview;
  top: 0;
  position: absolute;

  opacity: 0;
  transform: translateX(-10%);

  &[data-overview-open="true"] {
    opacity: 1;
    transform: translateX(0);
    /* animation: 0.3s ${appearFromLeft} ${ease.inOutCubic} forwards; */
  }
  /* &[data-overview-open="false"] {
    animation: 2s ${hideToRight} ${ease.inOutCubic} forwards;
  } */
`;
