import styled, { css } from "styled-components";

import { StyledAvatarReactionsBigButton } from "@/components/Dom/AvatarReactions/AvatarReactionsBigButton/styles";

import { pxToRem } from "@/utils/Math";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

export const StyledAvatarReactions = styled.div`
  position: fixed;
  bottom: var(--mainNav-margin);
  right: var(--mainNav-margin);

  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-radius: 5rem;
  padding: 0.5rem;
  background-color: rgba(${colors.white.rgba}, 0.6);
  overflow: hidden;
  z-index: 2;
  width: ${(props) => pxToRem(props.width)};

  ${Hover(css`
    background-color: rgba(${colors.white.rgba}, 1);

    ${StyledAvatarReactionsBigButton} {
      background-color: ${colors.tangerine.normal.hex};
    }
  `)}

  transition: width 0.4s ${ease.inOutQuint}, background-color 0.2s linear, transform 0.4s ${ease.inOutQuint} ${(
    props
  ) => props.isOpen && "0.3s"};

  ${(props) =>
    !props.isVisible &&
    css`
      transform: scale(0);
    `}
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
`;
