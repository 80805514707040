import { FC } from "react";
import * as React from "react";

import { StyledAvatar } from "@/components/Dom/Common/AvatarImage/styles";

import { TSpace } from "@/services/SpaceService/types";
import { getInitials, profileColor } from "@/utils/User";

import { StyledMapMarker, Pin, StyledSasImage } from "./styles";

interface IMapMarker {
  space: TSpace;
  isVisible: boolean;
  onMouseEnter: () => void;
}

const MapMarker: FC<IMapMarker> = ({ space, isVisible, onMouseEnter }) => {
  const splitTitle = space.title.split(" ");
  if (!space) return null;

  return (
    <StyledMapMarker isVisible={isVisible} onMouseEnter={onMouseEnter}>
      <Pin />
      <StyledAvatar theme={profileColor(space.id)}>
        <span aria-hidden>{getInitials(splitTitle[0], splitTitle[1])}</span>
        {space.previewImage && <StyledSasImage image={space.previewImage} options={{ aspectRatio: 1 }} />}
      </StyledAvatar>
    </StyledMapMarker>
  );
};

export default MapMarker;
