import onboardingService, {
  TOnboardingStep,
} from "@/services/OnboardingService";

import { StyledSidebarPanel } from "./styles";

const SidebarPanel = ({ children }) => {
  const tutorialMode = onboardingService((state) => state.currentStep);

  const isVisible =
    tutorialMode === TOnboardingStep.NONE ||
    tutorialMode === TOnboardingStep.CONFIGURE;

  return (
    <StyledSidebarPanel isVisible={isVisible}>{children}</StyledSidebarPanel>
  );
};

export default SidebarPanel;
