import {
  StyledVideoExtendedControls,
  Button,
  Ul,
} from "@/components/Dom/VideoChat/VideoExtendedControls/styles";

import videoService from "@/services/VideoService";

import Fullscreen from "~/public/assets/icons/Fullscreen.svg";
import Minify from "~/public/assets/icons/Minify.svg";

const VideoExtendedControls = () => {
  return (
    <StyledVideoExtendedControls>
      <Ul>
        <li>
          <Button onClick={videoService.getState().toggleExtendedScreenShare}>
            <Minify />
          </Button>
        </li>
        <li>
          <Button onClick={videoService.getState().openFullscreen}>
            <Fullscreen />
          </Button>
        </li>
      </Ul>
    </StyledVideoExtendedControls>
  );
};

export default VideoExtendedControls;
