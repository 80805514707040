import {
  StyledInfoOverlay,
  ContentWrapper,
} from "@/components/Dom/InfoOverlay/styles";

import initService from "@/services/InitService";
import spaceService from "@/services/SpaceService";

import { BigMidHeadline } from "@/styles/typography";

const InfoOverlay = () => {
  const isInitialized = initService((state) => state.isInitialized);
  const spaceCount = spaceService((state) => state.spaces?.size);

  if (!isInitialized || spaceCount > 0) return null;

  return (
    <StyledInfoOverlay>
      <ContentWrapper>
        <BigMidHeadline bold>
          Sorry, you have not been added to any Space yet. Please contact your
          event host if the problem persists.
        </BigMidHeadline>
      </ContentWrapper>
    </StyledInfoOverlay>
  );
};

export default InfoOverlay;
