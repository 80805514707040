import styled, { css } from "styled-components";

export const StyledTile = styled.div`
  ${(props) => css`
    width: calc(var(--size) * ${props.size});
    height: calc(var(--size) * ${props.size});
  `};

  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: contain;

  -webkit-user-drag: none;
  user-select: none;
`;
