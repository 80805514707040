import styled from "styled-components";

import { StyledCallEndButton } from "@/components/Dom/VideoChat/Common/CallEndButton/styles";
import { StyledVideoWindowControls } from "@/components/Dom/VideoChat/VideoWindow/VideoWindowControls/styles";

export const OnboardingCallEndButton = styled(StyledCallEndButton)``;

export const FakeButtonWrapper = styled.div`
  opacity: 0.4;
  cursor: not-allowed;

  button {
    pointer-events: none;
  }
`;

export const StyledOnboardingVideoWindowControls = styled(
  StyledVideoWindowControls
)``;
