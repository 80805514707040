import {
  Ul,
  A,
  SubHeadline,
  StyledChatPanelProfileList,
} from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfileList/styles";

import { MidHeadline, Text } from "@/styles/typography";

type TChatPanelProfileList = {
  headline: string;
  data: {
    headline: string;
    text: string;
    link?: string;
  }[];
};
const ChatPanelProfileList = ({
  headline,
  data = [],
}: TChatPanelProfileList) => {
  if (!data?.length) return null;

  return (
    <StyledChatPanelProfileList>
      <MidHeadline>{headline}</MidHeadline>

      <Ul>
        {data.map((el, index) => (
          <li key={index}>
            <SubHeadline as="h3">{el.headline}</SubHeadline>

            {el.link ? (
              <A
                href={el.link}
                target={!el.link.includes("mailto") ? "_blank" : undefined}
                rel="noopener noreferrer"
              >
                <Text bold>{el.text}</Text>
              </A>
            ) : (
              <Text bold>{el.text}</Text>
            )}
          </li>
        ))}
      </Ul>
    </StyledChatPanelProfileList>
  );
};

export default ChatPanelProfileList;
