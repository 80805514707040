import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import LoadingScreenSlide from "./LoadingScreenSlide";

import { ROUTES } from "@/layouts/AppLayout";
import loadingScreenService from "@/services/LoadingScreenService";
import spaceService from "@/services/SpaceService";

import { colors } from "@/styles/colors";

import { StyledLoadingScreen } from "./styles";

const isHome = (url) => url === "/";
const isSpace = (url) => Boolean(spaceService.getState().getSpaceBySlug(url));

const checkLoaderShouldFade = (lastUrl, newUrl) => {
  // fade out if we come from a token login
  if (lastUrl.includes(`${ROUTES.SETUP_SIGN_IN}#`)) {
    return true;
  }
  // fade out loading screen when you are signed out and get redirected from info to signin
  if (
    lastUrl.includes(ROUTES.SETUP_INFO) &&
    newUrl.includes(ROUTES.SETUP_SIGN_IN)
  ) {
    return true;
  }

  if (newUrl.includes(ROUTES.HOST)) {
    return false;
  }

  if (newUrl.includes(ROUTES.SETUP_AVATAR)) return true;

  if (lastUrl.includes(ROUTES.SETUP) && newUrl.includes(ROUTES.SETUP)) {
    return false;
  }

  const toHome = isHome(newUrl);
  const toSpace = isSpace(newUrl);

  if (toHome) return false;
  if (toSpace) return false;

  return true;
};

const LoadingScreen = () => {
  const { viewState, isPageReady } = loadingScreenService((state) => ({
    viewState: state.viewState,
    isPageReady: state.isPageReady,
  }));

  const router = useRouter();

  const [isRouteChangeComplete, setIsRouteChangeComplete] = useState(true);
  const [isLoaderFading, setIsLoaderFading] = useState(true);

  useEffect(() => {
    loadingScreenService.setState({ lastUrl: router.asPath });

    const onRouteChangeStart = (url) => {
      const loaderShouldFade = checkLoaderShouldFade(
        loadingScreenService.getState().lastUrl,
        url
      );

      if (loaderShouldFade) loadingScreenService.getState().fadeIn();
      loadingScreenService.setState({ isPageReady: false });

      setIsRouteChangeComplete(false);
      setIsLoaderFading(loaderShouldFade);
      loadingScreenService.setState({ lastUrl: url });
    };

    const onRouteChangeComplete = () => {
      setIsRouteChangeComplete(true);
    };

    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);

  useEffect(() => {
    if (!isPageReady) return;
    if (!isRouteChangeComplete) return;

    // This prevents the loader from fadingUp when it hasn't fadedIn before
    // In rare occasions the loader can however be faded in so we want the loader
    // to fade out when the viewState is fadeIn
    if (!isLoaderFading && viewState !== "fadeIn") return;
    loadingScreenService.getState().fadeOut();
  }, [isRouteChangeComplete, isPageReady]);

  return (
    <StyledLoadingScreen viewState={viewState}>
      <LoadingScreenSlide
        viewState={viewState}
        backgroundColor={colors.orange.hex}
        textColor={colors.white.default}
      />
    </StyledLoadingScreen>
  );
};

export default LoadingScreen;
