import React, { useEffect, useState, FC } from "react";

import AvatarImage from "@/components/Dom/Common/AvatarImage";
import UserName from "@/components/Dom/Common/UserName";
import NavItem from "@/components/Dom/MainNav/NavItemList/NavItem";

import { TChat } from "@/services/ChatService";
import debugService from "@/services/DebugService";
import initService from "@/services/InitService";
import navService, { EOverviewPanel, ENavSection } from "@/services/NavService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { getChatSubtitle } from "@/utils/Chat";

import { StyledChatNavItem } from "./styles";

type TChatsNavItem = React.HTMLAttributes<HTMLLIElement>;

interface IProps {
  chat: TChat;
}

const ChatsNavItem: FC<IProps & TChatsNavItem> = ({ chat }) => {
  const isInitialized = initService((state) => state.isInitialized);
  const [subtitle, setSubtitle] = useState<String | null>(null);
  const [users, setUsers] = useState<Array<TUser>>([]);

  const isActive = navService(
    (state) =>
      state.currentOverviewPanel === EOverviewPanel.CHATS &&
      state.overviewPanels.get(EOverviewPanel.CHATS)?.id === chat.id
  );

  useEffect(() => {
    if (!isInitialized) return;

    const update = async () => {
      try {
        const usersById = (
          await userService.getState().getUsersByIds(chat.users)
        ) // @ts-ignore
          .filter((user) => user.id !== userService.getState().ownUser.id);

        setUsers(usersById);
      } catch (error) {
        debugService
          .getState()
          .logError(
            `ChatsNavItem::update(): Failed to update chat users = ${error}`
          );
      }
    };
    update();
  }, [chat.users, isInitialized]);

  useEffect(() => {
    setSubtitle(getChatSubtitle(chat));
  }, [chat.newMessageCount]);

  const handleClick = (id: number) => {
    navService.getState().setPanel(EOverviewPanel.CHATS, id, 1);
  };

  if (users?.length < 1) return null;

  return (
    <StyledChatNavItem key={chat.id}>
      <NavItem
        elemToShow={<AvatarImage users={users} />}
        id={chat.id}
        isActive={isActive}
        newMessageCount={chat.newMessageCount}
        title={<UserName bold users={users} applyFontStyle={false} />}
        subtitle={subtitle}
        onClick={() => handleClick(chat.id)}
        section={ENavSection.CHATS}
      />
    </StyledChatNavItem>
  );
};

export default ChatsNavItem;
