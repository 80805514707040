import styled, { css } from "styled-components";

import { ease } from "@/styles/ease";

export const StyledLoadingTitle = styled.div`
  position: absolute;
  text-align: center;
  --textColor: ${(props) => props.textColor};

  ${({ viewState }) => {
    switch (viewState) {
      case "fadeIn":
        return css`
          transition: transform 0.4s ${ease.outCubic};
          transform: translateY(0);
        `;
      case "fadeOut":
        return css`
          transition: transform 0.4s ${ease.inCubic};
          transform: translateY(100%);
        `;
      default:
        return css`
          transform: translateY(-100%);
          opacity: 0;
        `;
    }
  }};
`;

export const Loading = styled.div`
  color: var(--textColor);
  text-transform: uppercase;
  letter-spacing: 0.6rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  margin-right: -0.5rem;
`;

export const LoadingTarget = styled.div``;

export const Line = styled.div`
  position: relative;
  color: var(--textColor);
  font-family: var(--font-serif);
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
`;
// Loading Bar
// &:after {
//   content: "";
//   transition: transform 0.5s linear;
//   ${fullsizeAndAbsolute};
//   background-color: var(--barColor);
//   z-index: -1;
//   transform-origin: center bottom;
//   transform: scaleY(0);
// }
