import styled, { css } from "styled-components";

import AvatarImage from "@/components/Dom/Common/AvatarImage";
import ButtonRound from "@/components/Dom/Common/ButtonRound";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

export const StyledFloatingProfile = styled.div`
  align-self: end;
  border-radius: var(--floating-profile-outer);
  grid-area: profile;
  margin-left: calc((var(--mainNav-width) - var(--floating-profile-outer)) / 2);
  width: fit-content;
  z-index: 1;
  position: relative;
  transition: opacity 0.3s ${ease.inOutCubic};

  &[data-visible="true"] {
    opacity: 1;
  }
  &[data-visible="false"] {
    opacity: 0;
  }
`;

export const StyledFloatingBar = styled.div`
  align-items: center;
  background-color: ${`rgba(${colors.white.rgba}, 0.6)`};
  border-radius: var(--floating-profile-outer);
  display: grid;
  height: var(---floating-profile-outer);
  gap: 2rem;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  max-width: var(--floating-profile-outer);
  overflow: hidden;
  position: relative;
  padding: 0 1rem;
  transition: max-width 600ms ${ease.inOutCubic};

  [data-expanded="true"] & {
    max-width: 999rem;
  }
`;

export const StyledUserProfile = styled.div`
  --avatar-size: 4rem;

  align-items: center;
  box-sizing: content-box;
  border-radius: 100%;
  display: grid;
  grid-template: var(--floating-profile-outer) / var(--floating-profile-outer);
  justify-content: center;
  margin: 0 -1rem 0 -1rem;
  position: relative;
`;

export const StyledAvatar = styled(AvatarImage)`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
`;

export const StyledOnlineIndicator = styled.span`
  background-color: ${colors.grey.text.hex};
  border-radius: 100%;
  height: 1.2rem;
  width: 1.2rem;
  position: absolute;
  bottom: calc(
    (var(--floating-profile-outer) - var(--floating-profile-inner)) / 2
  );
  right: calc(
    (var(--floating-profile-outer) - var(--floating-profile-inner)) / 2
  );
  grid-column: 1 / 2;
  grid-row: 1 / 2;

  &[data-online="true"] {
    background-color: ${colors.green.normal.hex};
  }
`;

export const StyledButtonRound = styled(ButtonRound)`
  background-color: ${colors.grey.normal.hex};
`;

export const StyledPrivacyLinks = styled.div`
  font-size: 0.9rem;
  line-height: 1.5;

  background-color: rgba(${colors.white.rgba}, 0.6);
  border-radius: 0 0 var(--map-grid-image-radius) var(--map-grid-image-radius);
  bottom: 0;
  display: grid;
  gap: 0.8rem;
  grid-auto-flow: column;
  left: 50%;
  position: absolute;
  opacity: 0;
  padding: 0.2rem 1.8rem;
  transform: translate(-50%, 0);
  transition: transform 0.25s ${ease.outCubic}, opacity 0.25s linear;
  transition-delay: 0.25s;
  z-index: -1;

  [data-expanded="true"] & {
    opacity: 1;
    transform: translate(-50%, calc(100% - 0.1rem));
    pointer-events: all;
    z-index: 0;
  }

  a {
    transition: color 0.3s ${ease.inOutCubic};
    white-space: nowrap;

    ${Hover(css`
      color: ${colors.tangerine.normal.hex};
    `)}
  }
`;
