import styled from "styled-components";

import Checkbox from "@/components/Dom/Common/Checkbox";
import { StyledProfilePanelSection } from "@/components/Dom/OverviewPanel/PanelContent/ProfilePanelSection/styles";

export const StyledProfilePanelSettingsSection = styled(
  StyledProfilePanelSection
)``;

export const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0;
`;
