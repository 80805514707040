import { useMemo } from "react";

import { TPanelData } from "@/services/NavService";
import spaceService from "@/services/SpaceService";
import videoService from "@/services/VideoService";
import { useUsersInSpace } from "@/utils/Space";

import { StyledPanelCallButton } from "./styles";

import PhonePlus from "~/public/assets/icons/PhonePlus.svg";
import Phone from "~/public/assets/icons/PhoneRotated.svg";

type TPanelCallButton = {
  data: TPanelData;
};

//From: https://stackoverflow.com/questions/53606337/check-if-array-contains-all-elements-of-another-array
export const arrayIncludesAll = (arr, target) =>
  target?.every((v) => arr?.includes(v));

const PanelCallButton = ({ data }: TPanelCallButton) => {
  const currentSpaceId = spaceService((state) => state.currentSpaceId || 0);
  const usersInSpace = useUsersInSpace(currentSpaceId);

  const { currentVideoRoomId, isStaticRoom, allUsersCallState } = videoService(
    (state) => ({
      currentVideoRoomId: state.currentRoomId,
      isStaticRoom: state.isStaticRoom,
      allUsersCallState: state.allUsersCallState,
    })
  );
  const isDisabled = useMemo(() => {
    if (!data.users?.length) return true;
    if (currentVideoRoomId && isStaticRoom) return true;
    if (!currentSpaceId) return true;

    //Check if one of the users is already in a call
    let allUsersAvailable = true;
    data.users.forEach((userId) => {
      if (allUsersCallState.get(userId)) allUsersAvailable = false;
    });
    if (!allUsersAvailable) return true;

    // Check if all users are in the same space
    const userIdsInSpace = usersInSpace.map((user) => user.id);
    if (!arrayIncludesAll(userIdsInSpace, data.users)) return true;

    return false;
  }, [
    data.users,
    currentVideoRoomId,
    isStaticRoom,
    currentSpaceId,
    allUsersCallState.size,
    usersInSpace,
  ]);

  const onClick = () => {
    if (!data?.users) return;

    if (currentVideoRoomId) {
      videoService.getState().invite(currentVideoRoomId, data.users);
    } else {
      videoService.getState().createRoom(data.users);
    }
  };

  return (
    <StyledPanelCallButton big onClick={onClick} disabled={isDisabled}>
      {currentVideoRoomId ? <PhonePlus /> : <Phone />}
    </StyledPanelCallButton>
  );
};

export default PanelCallButton;
