import { TStrapiImage } from "@/types/StrapiImage";

import { StyledInfoHero, StyledHeroImage } from "./styles";

interface IProps {
  children: React.ReactNode;
  image: TStrapiImage;
  title: string;
}

const InfoHero: React.FC<IProps> = ({ children, image, title, ...others }) => {

  return (
    <StyledInfoHero {...others}>
      <StyledHeroImage
        fallbackText={title}
        image={image}
        options={{ aspectRatio: 105 / 170, size: "small" }}
      />
      {children}
    </StyledInfoHero>
  );
};

export default InfoHero;
