import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";

export const StyledTabSection = styled.div`
  color: ${(props) =>
    props.selected ? colors.tangerine.normal.hex : colors.grey.normal.hex};
  cursor: pointer;
  display: grid;
  height: 3rem;
  place-items: center;
  transition: color 0.3s linear;
  width: 3rem;
      
  ${Hover(css`
    color: ${colors.tangerine.normal.hex};
  `)}

  }
`;
