import styled, { css } from "styled-components";

import { StyledUser } from "@/components/Dom/Common/User/style";

import { colors } from "@/styles/colors";

export const StyledParticipantsInfo = styled.div``;

export const StyledParticipantsListUl = styled.ul`
  display: grid;
  gap: 1rem;
  width: 100%;
  margin: 2rem 0 1rem;
`;

export const ParticipantsListLi = styled.li`
  ${(props) =>
    props.isReconnecting &&
    css`
      ${StyledUser} {
        color: ${colors.white.default};

        &:before {
          opacity: 1;
          background-color: red;
        }
      }
    `}
`;
