import styled, { css } from "styled-components";

export const StyledLoadingScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: grid;
  place-items: center;

  ${({ viewState }) => {
    switch (viewState) {
      case "fadeOutComplete":
        return css`
          visibility: hidden;
          pointer-events: none;
        `;
      case "fadeOut":
        return css`
          visibility: visible;
          pointer-events: all;
        `;
      default:
        return css`
          cursor: wait;
          visibility: visible;
          pointer-events: all;
        `;
    }
  }};
`;
