import React, { useEffect, useState } from "react";

import ButtonRound from "@/components/Dom/Common/ButtonRound";
import User from "@/components/Dom/Common/User";
import AnimatedHeight from "@/components/Dom/MainNav/CollapseSection/AnimatedHeight";
import {
  StyledModuleInfoUserList,
  Title,
  UserList,
  TitleWrapper,
  ListWrapper,
} from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfoUserList/styles";

import badgeService from "@/services/BadgeService";
import { TModule } from "@/services/ModuleService/types";
import { useUsersInModule } from "@/utils/Module";

import Chevron from "~/public/assets/icons/Chevron.svg";

const PartialUserList = ({ users, headline }) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  //When users change toggle the list to adapt to new height
  useEffect(() => {
    if (!isOpen) return;
    setIsOpen(false);

    setTimeout(() => {
      setIsOpen(true);
    }, 1);
  }, [users]);

  if (!users.length) return null;

  return (
    <UserList>
      <TitleWrapper isOpen={isOpen}>
        <ButtonRound
          icon={<Chevron />}
          onClick={() => {
            setIsOpen((isOpen) => !isOpen);
          }}
        />
        <Title>{headline}</Title>
      </TitleWrapper>

      <AnimatedHeight isOpen={isOpen} showScrollbar={false}>
        <ListWrapper>
          {users.map((user) => (
            <li key={user.id}>
              <User user={user} />
            </li>
          ))}
        </ListWrapper>
      </AnimatedHeight>
    </UserList>
  );
};

const ModuleInfoUserList = ({ module }: { module: TModule }) => {
  const badges = badgeService((state) => state.badges);
  const users = useUsersInModule(module.id);

  if (!users.length) return null;

  return (
    <StyledModuleInfoUserList>
      <Title>In this module</Title>

      {badges.map((badge) => (
        <PartialUserList
          key={badge.id}
          users={users.filter((user) => user.badge === badge.id)}
          headline={`${badge.title} users`}
        />
      ))}
    </StyledModuleInfoUserList>
  );
};

export default ModuleInfoUserList;
