import { FC } from "react";

import ProgressIndicator from "@/components/Dom/DiscoverQuest/ProgressIndicator";

import {
  TDiscoverTaskData,
  TDiscoverTaskState,
} from "@/services/DiscoverQuestService/types";

import { BigHeadline, Text } from "@/styles/typography";

import { ProgressIndicatorWrapper, StyledDiscoverProgress } from "./styles";

interface IDiscoverQuestProgress {
  taskData: Map<TDiscoverTaskData["id"], TDiscoverTaskData>;
  taskState: Map<TDiscoverTaskState["id"], TDiscoverTaskState>;
  completedTasks: number;
  wrapperExpanded: boolean;
  onMouseEnter: () => void;
  headline?: string;
}

const DiscoverProgress: FC<IDiscoverQuestProgress> = ({
  taskData = new Map<TDiscoverTaskData["id"], TDiscoverTaskData>(),
  taskState = new Map<TDiscoverTaskState["id"], TDiscoverTaskState>(),
  completedTasks = 0,
  wrapperExpanded,
  onMouseEnter,
  headline,
}) => {
  return (
    <StyledDiscoverProgress onMouseEnter={onMouseEnter}>
      {headline && <BigHeadline bold>{headline}</BigHeadline>}

      <Text>
        {completedTasks
          ? `${completedTasks} of ${taskData.size} discovered`
          : "Start exploring and look for the stars!"}
      </Text>

      <ProgressIndicatorWrapper>
        {Array.from(taskData.values()).map((task, i) => {
          const currentTaskState = taskState.get(task.id);
          return (
            <ProgressIndicator
              key={i}
              index={i}
              taskData={task}
              completed={currentTaskState ? currentTaskState.completed : false}
              wrapperVisible={wrapperExpanded}
            />
          );
        })}
      </ProgressIndicatorWrapper>
    </StyledDiscoverProgress>
  );
};

export default DiscoverProgress;
