import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const StyledVideoWindowParticipant = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  height: var(--videoHeight);
  overflow: hidden;

  ${(props) =>
    props.dominantSpeaker &&
    css`
      border-radius: var(--video-window-radius);
      border: 0.2rem solid ${colors.tangerine.normal.hex};
    `}

  ${(props) =>
    !props.render &&
    css`
      position: absolute;
      visibility: hidden;
    `}
`;

export const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;

  //Flip local video horizontally
  ${(props) => props.isLocal && "transform: scale(-1, 1);"}

  ${(props) => !props.visible && "visibility: hidden;"}
`;

export const VideoPlaceholder = styled.div`
  --avatar-size: 4rem;

  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: ${colors.grey.lightest.hex};
  z-index: 1;

  // Remove cursor: pointer on AvatarImage
  div {
    cursor: initial;
  }
`;

export const RaisedHand = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 1.6rem;
  background-color: ${colors.grey.dark.hex};
  margin-right: 0.5rem;
  display: none;
  color: ${colors.white.default};
  align-items: center;
  justify-content: center;
  svg {
    width: 1rem;
    height: 1rem;
  }
  ${(props) =>
    props.isVisible &&
    css`
      display: flex;
    `};
`;

export const MutedWrapper = styled.div`
  position: absolute;
  width: 2.1rem;
  height: 2.1rem;
  bottom: 1rem;
  right: 1rem;
  z-index: 2;
  border-radius: 50%;
  background-color: rgba(${colors.white.rgba}, 0.4);
  color: ${colors.grey.normal.hex};
  display: grid;
  place-items: center;

  svg {
    width: 0.8rem;
    height: 1rem;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.2rem;
    height: 0.1rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: ${colors.grey.normal.hex};
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  text-align: center;
  background-color: rgba(${colors.white.rgba}, 0.4);
  border-radius: 2.1rem;
  padding: 0.4rem 1rem;
  z-index: 4;
`;

export const ReconnectWarning = styled(Text)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.4rem 1rem;
  z-index: 4;
  background-color: ${colors.red.normal.hex};
  border-radius: 2.1rem;
  color: ${colors.white.default};
`;
