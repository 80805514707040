import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { TextStyles } from "@/styles/typography";

export const StyledEmojiPicker = styled.div`
  bottom: var(--mainNav-overview-panel-padding);
  left: var(--mainNav-overview-panel-padding);
  position: absolute;

  .emoji-picker-react {
    --emoji-button-size: 2.5rem;
    --emoji-image-size: 2rem;

    background: ${colors.grey.lightest.hex};
    border-color: ${colors.grey.lighter.hex} !important;
    border-radius: 1rem 1rem 2rem 2rem;
    box-shadow: none;
    height: unset;
    margin-left: 0 !important;
    padding: 1rem 0.9rem 4.5rem;
    width: calc(var(--mainNav-panel-width) - 2 * var(--flex-grow-body-padding));

    // since we can't disable the categories through the component configuration,
    // we're hiding them with css
    .emoji-categories,
    .active-category-indicator-wrapper {
      display: none;
      visibility: hidden;
    }

    // the emojis are separated in different <ul>s, this causes them break like at end of categories
    // since we're hiding the categories, we need to pull up the display flex to the wrapper and use
    // display contents on the <ul>s to parse them as a long unique list
    .emoji-scroll-wrapper {
      display: flex;
      flex-flow: row wrap;
      height: calc(6 * var(--emoji-button-size));
      justify-content: space-between;
      padding-right: 0.6rem;
    }

    // display contents makes the dom ignore the existence of the html tag for styling purposes
    .emoji-group {
      display: contents;

      &:before {
        content: none;
      }
    }

    .content-wrapper {
      margin-top: 0.7rem;

      &:before {
        content: none;
      }
    }

    input.emoji-search {
      border-color: ${colors.grey.normal.hex} !important;
      border-radius: 0.5rem;
      margin: 0;
      padding: 0.9rem;
      width: 100%;
      ${TextStyles}
    }

    .emoji {
      button {
        height: var(--emoji-button-size) !important;
        width: var(--emoji-button-size) !important;

        ${Hover(css`
          background-color: ${colors.grey.lighter.hex} !important;
        `)}

        // remove skin variation arrown on bottom right
        &:before {
          content: none !important;
        }
      }
    }

    img.emoji-img {
      height: var(--emoji-image-size) !important;
      margin: 0 !important;
      width: var(--emoji-image-size) !important;
    }
  }
`;
