import styled from "styled-components";

import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";
import PanelHeader from "@/components/Dom/OverviewPanel/PanelHeader";

export const StyledProfilePanel = styled(FlexAndGrow)`
  width: var(--mainNav-panel-width);
`;

export const Header = styled(PanelHeader)`
  padding-bottom: 2.3rem;
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem var(--mainNav-overview-panel-padding);
`;
