import styled from "styled-components";

import { colors } from "@/styles/colors";
import { TextStyles } from "@/styles/typography";

export const StyledSetupLinks = styled.div`
  position: fixed;
  top: var(--mainNav-margin);
  right: var(--mainNav-margin);
  display: grid;
  gap: 2rem;
  grid-auto-flow: column;
`;

export const A = styled.a`
  color: ${colors.white.default};
  ${TextStyles};
`;
