import styled from "styled-components";

import { colors } from "@/styles/colors";

export const StyledInfoOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 14;

  background-color: rgba(0, 0, 0, 0.75);
`;

export const ContentWrapper = styled.div`
  display: grid;
  gap: 1rem;
  max-width: 64rem;
  padding: 2rem;
  color: ${colors.white.default};
  text-align: center;
`;
