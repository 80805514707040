import { FC, useEffect, useRef, useState } from "react";

import AvatarReactionButton from "@/components/Dom/AvatarReactions/AvatarReactionButton";
import AvatarReactionsBigButton from "@/components/Dom/AvatarReactions/AvatarReactionsBigButton";
import { TReaction } from "@/components/Scene/Avatar/ReactionController";

import inputService from "@/services/InputService";
import navService, { EOverviewPanel } from "@/services/NavService";
import { playerService } from "@/services/PlayerService";
import sceneService from "@/services/SceneService";

import { ButtonsWrapper, StyledAvatarReactions } from "./styles";

import LightBulb from "~/public/assets/icons/LightBulb.svg";
import Note from "~/public/assets/icons/Note.svg";
import ThumbsUp from "~/public/assets/icons/ThumbsUp.svg";
import Wave from "~/public/assets/icons/Wave.svg";

type TButton = {
  reaction: TReaction;
  icon: any;
};

const buttons: TButton[] = [
  { icon: <LightBulb />, reaction: "IDEA" },
  { icon: <Note />, reaction: "DANCE" },
  { icon: <ThumbsUp />, reaction: "THUMBS" },
  { icon: <Wave />, reaction: "WAVING" },
];

const AvatarReactions: FC = () => {
  const buttonWrapperRef = useRef<HTMLDivElement>();
  const isProfileOpen = navService(
    (state) => state.currentOverviewPanel === EOverviewPanel.PROFILE
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isSceneInitialized = sceneService((state) => state.isSceneInitialized);

  useEffect(() => {
    if (isProfileOpen) setIsOpen(false);
  }, [isProfileOpen]);

  useEffect(() => {
    const onKeyUp = (e) => {
      if (inputService.getState().locked) return;
      const number = parseInt(e.key);

      if (!number) return;
      const button = buttons[number - 1];

      if (button?.reaction) {
        playerService.getState().reactionController?.start(button.reaction);
      }
    };

    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  });

  return (
    <StyledAvatarReactions
      isVisible={!isProfileOpen && isSceneInitialized}
      isOpen={isOpen}
      width={
        isOpen && buttonWrapperRef.current
          ? buttonWrapperRef.current.offsetWidth + 80 //Open width
          : 60 //Closed width
      }
    >
      <ButtonsWrapper ref={buttonWrapperRef}>
        {buttons.map((button, i) => (
          <AvatarReactionButton key={i} index={i} isOpen={isOpen} {...button} />
        ))}
      </ButtonsWrapper>
      <AvatarReactionsBigButton onClick={() => setIsOpen((state) => !state)} />
    </StyledAvatarReactions>
  );
};

export default AvatarReactions;
