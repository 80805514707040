import styled, { css } from "styled-components";

import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";
import { StyledTabSection } from "@/components/Dom/Common/Tabs/TabSection/styles";
import { StyledOnboardingCallButton } from "@/components/Dom/Onboarding/OnboardingCallButton/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

export const StyledOnboardingProfile = styled(FlexAndGrow)`
  position: fixed;
  top: var(--mainNav-margin);
  left: var(--mainNav-margin);
  z-index: 2;
  background-color: ${colors.white.default};
  height: fit-content;
  border-radius: var(--mainNav-overview-panel-border-radius);
  width: 28rem;

  transition: transform 0.6s ${ease.inOutCubic};
  transform: translateX(
    ${(props) =>
      props.isVisible ? "0" : "calc(-108% - var(--mainNav-margin))"}
  );

  ${StyledOnboardingCallButton} {
    position: absolute;
    top: var(--mainNav-overview-panel-padding);
    right: var(--mainNav-overview-panel-padding);
  }

  ${StyledTabSection} {
    &:not(:first-child) {
      opacity: 0.4;
      cursor: not-allowed;

      ${Hover(css`
        color: ${colors.grey.normal.hex};
      `)}
    }
  }
`;
