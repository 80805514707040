import { useEffect, useRef, useState } from "react";
import {
  LocalParticipant,
  RemoteParticipant,
  RemoteTrack,
  VideoTrack,
} from "twilio-video";

import ShareParticipantControls from "@/components/Dom/VideoChat/VideoWindow/ShareParticipantControls";

import debugService from "@/services/DebugService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import videoService from "@/services/VideoService";
import { EMessageType } from "@/services/VideoService/types";

import { StyledScreenshareParticipant, Video } from "./styles";

// ToDo Move this into video service
export const findShareParticipant: any = () => {
  const { currentRoom, shareParticipantSid } = videoService.getState();

  if (!currentRoom || !shareParticipantSid) return null;

  return (
    currentRoom.participants.get(shareParticipantSid) ||
    currentRoom.localParticipant
  );
};

type TShareParticipant = {
  isVisible: boolean;
  controls?: boolean;
};

const ShareParticipant = ({
  isVisible,
  controls = true,
}: TShareParticipant) => {
  const {
    currentRoom,
    shareParticipantSid,
    isShareActive,
    videoGrid,
  } = videoService((state) => ({
    isShareActive: state.isShareActive,
    currentRoom: state.currentRoom,
    shareParticipantSid: state.shareParticipantSid,
    videoGrid: state.videoGrid,
  }));

  const videoRef = useRef<HTMLVideoElement>(null);
  const [user, setUser] = useState<TUser | null>();
  const [participant, setParticipant] = useState<
    RemoteParticipant | LocalParticipant | null
  >(null);

  useEffect(() => {
    const participant = findShareParticipant();
    setParticipant(findShareParticipant());

    const updateUser = async () => {
      if (participant) {
        try {
          const user = await userService
            .getState()
            .getUserById(parseInt(participant.identity, 10));

          if (user) setUser(user);
        } catch (error) {
          debugService
            .getState()
            .logError(
              `ShareParticipant::useEffect[shareParticipantSid]: ${error}`
            );
          return;
        }
      }
    };

    updateUser();
  }, [shareParticipantSid]);

  useEffect(() => {
    if (!participant) return;

    let screenTrack: VideoTrack | null = null;

    participant.videoTracks.forEach((publication, publicationSid) => {
      if (publication.trackName === "screen" && !screenTrack)
        screenTrack = publication.track;
    });

    // @ts-expect-error
    if (screenTrack && videoRef.current) screenTrack.attach(videoRef.current);
  }, [participant]);

  // ToDo Move this into video service?
  const startScreenShare = (
    track: RemoteTrack,
    shareParticipantSid: string
  ) => {
    if (track.name !== "screen") return;
    videoService.setState({
      isShareActive: true,
      shareParticipantSid,
    });
  };

  useEffect(() => {
    if (!currentRoom) return;

    currentRoom.on("trackPublished", (track, participant) => {
      switch (track.kind) {
        case "data": {
          const {
            currentShareAreaName,
            localParticipant,
          } = videoService.getState();

          if (currentShareAreaName && localParticipant.isSharing) {
            videoService.getState().sendMessage({
              type: EMessageType.SPACE_SHARE_STARTED,
              data: currentShareAreaName,
            });
          }
          break;
        }
        default:
          break;
      }
    });

    currentRoom.on("trackStarted", (track, participant) => {
      switch (track.kind) {
        case "video":
          startScreenShare(track, participant.sid);
          break;
        default:
          break;
      }
    });

    currentRoom.on("trackUnpublished", (publication, participant) => {
      if (publication.trackName === "screen") {
        videoService.getState().closeFullscreen();
        videoService.setState({
          isShareActive: false,
          isSpaceShareActive: false,
          shareParticipantSid: null,
        });
      }
    });

    currentRoom.localParticipant.on("trackPublished", ({ track }) => {
      if (track && track.kind === "video" && track.name === "screen") {
        const {
          localParticipant,
          currentShareAreaName,
        } = videoService.getState();

        if (currentShareAreaName) {
          videoService.getState().sendMessage({
            type: EMessageType.SPACE_SHARE_STARTED,
            data: currentShareAreaName,
          });
        }
        localParticipant.isSharing = true;
        videoService.setState({
          isShareActive: true,
          shareParticipantSid: currentRoom.localParticipant.sid,
          localParticipant,
        });
        videoService.getState().startSpaceShare();
      }
    });

    currentRoom.localParticipant.on("trackStopped", (track) => {
      if (track && track.kind === "video" && track.name === "screen") {
        const { localParticipant } = videoService.getState();
        const { currentShareAreaName } = videoService.getState();
        if (currentShareAreaName) {
          videoService.getState().sendMessage({
            type: EMessageType.SPACE_SHARE_STOPPED,
            data: currentShareAreaName,
          });
        }
        localParticipant.isSharing = false;
        videoService.setState({
          isShareActive: false,
          shareParticipantSid: null,
          localParticipant,
        });
        videoService.getState().closeFullscreen();
        videoService.getState().stopSpaceShare();
      }
    });
  }, [currentRoom]);

  if (!participant) return null;

  return (
    <StyledScreenshareParticipant
      isVisible={isVisible && isShareActive}
      videoGrid={videoGrid}
    >
      <Video ref={videoRef} autoPlay={true} playsInline muted={true} />
      {user && controls && <ShareParticipantControls user={user} />}
    </StyledScreenshareParticipant>
  );
};

export default ShareParticipant;
