import styled from "styled-components";

import { colors } from "@/styles/colors";

export const StyledVideoExtendedControls = styled.div`
  background: ${colors.white.default};
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(calc(50vw - 50%));
`;

export const Ul = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.8rem;
`;

export const Button = styled.button`
  width: 4rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.grey.normal.hex};
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    color: ${colors.black.hex};
  }
`;
