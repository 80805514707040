import { useWindowSize } from "@react-hook/window-size/throttled";
import { useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { button, useControls } from "leva";
import { useRouter } from "next/router";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import SpaceTile from "@/components/Dom/WorldMap/SpaceTile";
import Tile from "@/components/Dom/WorldMap/Tile";

import initService from "@/services/InitService";
import navService, { ENavSection, EOverviewPanel } from "@/services/NavService";
import spaceService from "@/services/SpaceService";
import { TSpace } from "@/services/SpaceService/types";
import { clamp } from "@/utils/Math";

import {
  StyledWorldMap,
  CloudsLayer,
  TilesWrapper,
  MapWrapper,
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  ScaleWrapper,
} from "./styles";

type TWorldMap = {
  render: boolean;
};

const WorldMap: FC<TWorldMap> = ({ render }) => {
  const {
    gap,
    size,
    offset,
    spaceTileScale,
    topLeftCornerScale,
    topRightCornerScale,
    bottomLeftCornerScale,
    bottomRightCornerScale,
  } = useControls("Map", {
    gap: {
      value: 0.1,
      min: 0,
      max: 20,
    },
    offset: {
      value: 0,
      min: 0,
      max: 40,
    },
    size: {
      value: 8,
      min: 1,
      max: 100,
    },

    spaceTileScale: {
      value: 2.3,
      min: 0,
      max: 5,
    },

    topLeftCornerScale: {
      value: 3.5,
      min: 0,
      max: 5,
    },

    topRightCornerScale: {
      value: 3.5,
      min: 0,
      max: 5,
    },

    bottomLeftCornerScale: {
      value: 3,
      min: 0,
      max: 5,
    },

    bottomRightCornerScale: {
      value: 2.5,
      min: 0,
      max: 5,
    },

    centerMap: button(() => centerMapToDiv()),
  });

  const spaces = spaceService((state) => [...state.spaces.values()]);
  const inFittingRoom = navService(
    (state) => state.currentOverviewPanel === EOverviewPanel.PROFILE
  );
  const isInitialized = initService((state) => state.isInitialized);

  const windowSize = useWindowSize();
  const router = useRouter();

  const ref = useRef<HTMLDivElement>();
  const mapRef = useRef<HTMLDivElement>();
  const scaleWrapperRef = useRef<HTMLDivElement>();
  const tilesWrapperRef = useRef<HTMLDivElement>();
  const bounds = useRef({ left: 0, right: 0, top: 0, bottom: 0 });
  const lastPosition = useRef<{ x: number; y: number }>();

  const [isVisible, setIsVisible] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<TSpace["id"] | null>(null);
  const [cloudsLayerSize, setCloudsLayerSize] = useState({
    width: 1024,
    height: 1024,
  });

  const canDrag = useMemo(() => {
    if (!mapRef.current) return false;
    if (selectedSpace) return false;

    const { width, height } = mapRef.current?.getBoundingClientRect();
    const windowWidth = windowSize[0];
    const windowHeight = windowSize[1];

    if (windowWidth >= width && windowHeight >= height) return false;
    return true;
  }, [selectedSpace, windowSize, mapRef.current]);

  const [style, spring] = useSpring(() => ({ x: 0, y: 0 }));

  useDrag(
    ({ offset: [x, y] }) => {
      if (canDrag) spring.start(clampPositionToEdges({ x, y }));
    },
    {
      //@ts-ignore
      target: ref,
      drag: { from: () => [style.x.get(), style.y.get()] },
    }
  );

  const centerMapToDiv = (div = tilesWrapperRef.current) => {
    if (!div) return { x: 0, y: 0 };

    const windowWidth = windowSize[0];
    const windowHeight = windowSize[1];

    const { top, left, width, height } = div.getBoundingClientRect();

    const center = {
      x: left + width * 0.5 - style.x.get(),
      y: top + height * 0.5 - style.y.get(),
    };

    spring.start({
      x: -center.x + windowWidth * 0.5,
      y: -center.y + windowHeight * 0.5,
    });

    return center;
  };

  const clampPositionToEdges = ({ x, y }) => ({
    x: clamp(x, bounds.current.left, bounds.current.right),
    y: clamp(y, bounds.current.top, bounds.current.bottom),
  });

  const onSpaceSelected = (div, space) => {
    if (!div || !scaleWrapperRef.current) return;

    setSelectedSpace(space.id);
    navService.getState().setPanel(EOverviewPanel.SPACES, space.defaultModule);

    lastPosition.current = {
      x: style.x.get(),
      y: style.y.get(),
    };

    const windowWidth = windowSize[0];
    const windowHeight = windowSize[1];

    const center = centerMapToDiv(div);

    const transformOrigin = {
      x: (center.x / windowWidth) * 100,
      y: (center.y / windowHeight) * 100,
    };

    scaleWrapperRef.current.style.cssText = `transform-origin: ${transformOrigin.x}vw ${transformOrigin.y}vh; transform: scale(2.4);`;
  };

  const onSpaceUnselected = () => {
    if (!scaleWrapperRef.current || !lastPosition.current) return;
    setSelectedSpace(null);

    if (!router.query.space) {
      spring.start({ x: 0, y: 0 });
    } else {
      const { x, y } = lastPosition.current;
      spring.start({ x, y });
    }

    scaleWrapperRef.current.style.transform = `scale(1)`;
    spaceService.getState().setCurrentSpace(null);
    navService.getState().closeNavSection(ENavSection.SPACES);
    navService.getState().closeAllPanels();
  };

  //Recalculate the bounds
  useEffect(() => {
    if (!mapRef.current) return;
    const windowWidth = windowSize[0];
    const windowHeight = windowSize[1];
    const { width, height } = mapRef.current?.getBoundingClientRect();

    bounds.current = {
      left: -width + windowWidth,
      right: 0,

      top: -height + windowHeight,
      bottom: 0,
    };
  }, [windowSize, mapRef.current, spaces]);

  //Make sure we stay inside the bounds if we are on the map overview
  useEffect(() => {
    setCloudsLayerSize({
      width: Math.ceil(windowSize[0] / 1024) * 1024 * 2,
      height: Math.ceil(windowSize[1] / 1024) * 1024 * 2,
    });

    spring.start(
      clampPositionToEdges({
        x: style.x.get(),
        y: style.y.get(),
      })
    );
  }, [windowSize]);

  useEffect(() => {
    if (!tilesWrapperRef.current || !isInitialized) return;
    const checkIsVisible = () => render && !inFittingRoom;

    if (!checkIsVisible()) {
      if (inFittingRoom) {
        //When opening the profile immediately hide
        setIsVisible(false);
      } else {
        //When going to a module, delay to wait for the loader to be faded in
        setTimeout(() => {
          if (!checkIsVisible()) setIsVisible(false);
        }, 750);
      }
    } else {
      //Always immediately set to visible
      setIsVisible(true);
    }
  }, [render, inFittingRoom, tilesWrapperRef.current, isInitialized]);

  useEffect(() => {
    if (!router.query.space) onSpaceUnselected();
  }, [router.query.space]);

  useEffect(() => {
    if (!tilesWrapperRef.current || !isVisible) return;

    //Show enter Dialog on first visit
    // if (isVisible && !onboardingService.getState().finishedOnboarding) {
    //   popupOverlayService.getState().open(<EnterDialog />);
    // }

    if (selectedSpace === null) return;

    if (lastPosition.current) {
      spring.start(lastPosition.current);
    } else {
      centerMapToDiv(tilesWrapperRef.current);
    }
  }, [isVisible]);

  return (
    <StyledWorldMap
      ref={ref}
      isVisible={isVisible}
      canDrag={canDrag}
      style={{
        "--size": `${size}vw`,
        "--gap": `${gap}vw`,
      }}
    >
      <MapWrapper ref={mapRef} style={style}>
        <ScaleWrapper ref={scaleWrapperRef}>
          <TopLeft
            imageUrl={"/assets/images/Corner_TopLeft.png"}
            size={topLeftCornerScale}
          />
          <TopRight
            size={topRightCornerScale}
            imageUrl={"/assets/images/Corner_TopRight.png"}
          />
          <BottomLeft
            imageUrl={"/assets/images/Corner_BottomLeft_Water.png"}
            size={bottomLeftCornerScale}
          />
          <BottomRight
            imageUrl={"/assets/images/Corner_BottomRight.png"}
            size={bottomRightCornerScale}
          />

          <TilesWrapper
            tileCount={spaces.length + 2}
            ref={tilesWrapperRef}
            style={{ "--tileScale": spaceTileScale }}
          >
            <Tile
              size={spaceTileScale}
              imageUrl={"/assets/images/Filler_01.png"}
            />

            {spaces.map((space, index) => (
              <SpaceTile
                key={space.id}
                index={index}
                space={space}
                selectedSpace={selectedSpace}
                offset={offset}
                onSelected={onSpaceSelected}
                size={spaceTileScale}
              />
            ))}

            <Tile
              size={spaceTileScale}
              imageUrl={"/assets/images/Filler_03.png"}
            />
          </TilesWrapper>
        </ScaleWrapper>
      </MapWrapper>

      <CloudsLayer
        style={{
          "--width": `${cloudsLayerSize.width}px`,
          "--height": `${cloudsLayerSize.height}px`,
        }}
      />
    </StyledWorldMap>
  );
};

export default WorldMap;
