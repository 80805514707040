import { useState } from "react";

import { Label, CheckboxInput, StyledCheckbox } from "./styles";

interface ICheckboxProps {
  uniqueId: string;
  label: string;
  value?: boolean;
  changeHandler?: (value: boolean) => void;
  register?: Object;
  error?: boolean;
  disabled?: boolean;
}

const Checkbox = ({
  uniqueId,
  label,
  value = false,
  changeHandler,
  register = {},
  error = false,
  disabled = false,
  ...others
}: ICheckboxProps) => {
  const [checked, setChecked] = useState(value);

  const onClick = (e) => {
    setChecked((checked) => !checked);
    changeHandler && changeHandler(e.target.checked);
  };

  return (
    <StyledCheckbox data-disabled={disabled} {...others}>
      <CheckboxInput
        onClick={onClick}
        type="checkbox"
        id={uniqueId}
        name={uniqueId}
        checked={checked}
        error={error}
        disabled={disabled}
        {...register}
      />
      <Label error={error} htmlFor={uniqueId}>
        {label}
      </Label>
    </StyledCheckbox>
  );
};

export default Checkbox;
