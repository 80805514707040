import styled from "styled-components";

import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";
import { BodyContent } from "@/components/Dom/Common/FlexAndGrow/styles";
import { VideoPlaceholder } from "@/components/Dom/VideoChat/VideoWindow/VideoWindowParticipant/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

export const OnboardingFakeVideoWindow = styled(FlexAndGrow)`
  position: fixed;
  top: var(--mainNav-margin);
  right: var(--mainNav-margin);
  z-index: 2;
  background-color: ${colors.white.default};
  height: fit-content;
  border-radius: var(--mainNav-overview-panel-border-radius);

  transform: translateX(
    ${(props) => (props.isVisible ? "0" : "calc(108% + var(--mainNav-margin))")}
  );

  transition: transform 0.6s ${ease.inOutCubic};

  ${BodyContent} {
    overflow: hidden;
    padding: 0;
  }
`;

export const FakeTile = styled.div`
  width: var(--videoWidth);
  height: var(--videoHeight);
  position: relative;

  ${VideoPlaceholder} {
    z-index: -1;
  } ;
`;
