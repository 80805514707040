import { useEffect, useRef, useState } from "react";

import { StyledAnimatedHeight, StyledScrollWrapper } from "./styles";

interface IAnimatedHeight {
  children;
  isOpen: boolean;
  showScrollbar: boolean;
  maxHeight?: number;
}

const AnimatedHeight = ({
  children,
  isOpen,
  showScrollbar = true,
  maxHeight,
}: IAnimatedHeight) => {
  const scrollRef = useRef<HTMLElement>();
  const [height, setHeight] = useState<number>(0);
  const [animate, setAnimate] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  useEffect(() => {
    if (!scrollRef.current) return;

    // @ts-expect-error
    const ownHeight = scrollRef.current.firstChild?.offsetHeight;

    setHeight(() => {
      if (!isOpen) return 0;
      if (maxHeight) return Math.min(ownHeight, maxHeight);
      return ownHeight;
    });
  }, [isOpen, maxHeight]);

  useEffect(() => {
    setMounted(true);
    if (!mounted) return;
    setAnimate(true);
  }, [isOpen]);

  return (
    <StyledAnimatedHeight height={height} animate={animate}>
      <StyledScrollWrapper ref={scrollRef} showScrollbar={showScrollbar}>
        {children}
      </StyledScrollWrapper>
    </StyledAnimatedHeight>
  );
};

export default AnimatedHeight;
