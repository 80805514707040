import { useEffect, useRef, useState } from "react";

import { StyledAnimatedNotification, AnimationHelper } from "./styles";

const AnimatedNotification = ({ children, position, isVisible }) => {
  const [height, setHeight] = useState(0);
  const elementRef = useRef<HTMLDivElement>();

  useEffect(() => {
    process.nextTick(() => {
      if (!elementRef.current) return;

      const bounds = elementRef.current.getBoundingClientRect();

      // in some cases the getBoundingClientRect returns height 0 in this case we add a fallback
      if (bounds) {
        if (bounds.height > 0) {
          setHeight(bounds.height);
        } else {
          setHeight(78);
        }
      }
    });
  }, [elementRef.current]);

  return (
    <StyledAnimatedNotification
      height={height}
      position={position}
      isVisible={isVisible}
    >
      <AnimationHelper ref={elementRef}>{children}</AnimationHelper>
    </StyledAnimatedNotification>
  );
};

export default AnimatedNotification;
