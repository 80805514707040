import navService, { EOverviewPanel } from "@/services/NavService";

import { colors } from "@/styles/colors";

import { StyledAddPeopleButton } from "./styles";

import PersonPlus from "~/public/assets/icons/PersonPlus.svg";

const AddPeopleButton = () => {
  const onClick = () => {
    navService.getState().setPanel(EOverviewPanel.ADD_TO_CALL);
  };

  return (
    <StyledAddPeopleButton
      icon={<PersonPlus />}
      iconWidth={18.6}
      iconHeight={13.3}
      iconColor={colors.grey.normal.hex}
      big={true}
      backgroundColor={colors.grey.lightest.hex}
      onClick={onClick}
    />
  );
};

export default AddPeopleButton;
