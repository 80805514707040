import { useState, FC, HTMLAttributes } from "react";

import NavItem from "../NavItem";

import ModuleImage from "@/components/Dom/Common/ModuleImage";
import ModuleNavItemSubtitle from "@/components/Dom/MainNav/NavItemList/ModuleNavItemSubtitle";

import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import navService, { EOverviewPanel, ENavSection } from "@/services/NavService";

type TModuleNavItem = HTMLAttributes<HTMLLIElement>;

interface IProps {
  module: TModule;
  title?: string;
  expanded?: boolean;
  sectionTitle?: string;
}

const ModuleNavItem: FC<IProps & TModuleNavItem> = ({
  module,
  title,
  sectionTitle,
  expanded,
}) => {
  if (!module) return null;

  const [renderSubtitle, setRenderSubtitle] = useState(false);
  const [subtitle, setSubtitle] = useState<String | null>(null);

  const currentModuleId = moduleService.getState().currentModuleId;

  const { currentOverviewPanel, overviewPanels } = navService((state) => ({
    currentOverviewPanel: state.currentOverviewPanel,
    overviewPanels: state.overviewPanels,
  }));

  const handleClick = () => {
    const initialOpenCurrentModule =
      !currentOverviewPanel && currentModuleId && expanded == false;

    navService
      .getState()
      .setPanel(
        EOverviewPanel.SPACES,
        initialOpenCurrentModule ? currentModuleId : module.id
      );
  };

  return (
    <>
      {renderSubtitle && (
        <ModuleNavItemSubtitle setSubtitle={setSubtitle} module={module} />
      )}

      <NavItem
        id={module.id}
        expanded={expanded}
        elemToShow={<ModuleImage module={module} title={title} />}
        isActive={module.id === currentModuleId}
        isPanelOpen={
          currentOverviewPanel === EOverviewPanel.SPACES &&
          overviewPanels.get(EOverviewPanel.SPACES)?.id === module.id
        }
        title={title || module.title}
        subtitle={subtitle}
        sectionTitle={sectionTitle}
        onClick={handleClick}
        onHoverChange={setRenderSubtitle}
        section={ENavSection.SPACES}
      />
    </>
  );
};

export default ModuleNavItem;
