import { FC, HTMLAttributes, useState } from "react";

import Input from "@/components/Dom/Common/Input";

import { InputContainer, ClearButtonContainer } from "./styles";

import DeleteDot from "~/public/assets/icons/DeleteDot.svg";
import SearchIcon from "~/public/assets/icons/Search.svg";

type TSearch = HTMLAttributes<HTMLButtonElement>;
interface IProps {
  text: string;
  setSearchedText: Function;
}

const Search: FC<IProps & TSearch> = ({ text, setSearchedText }) => {
  const [buttonIcon, setButtonIcon] = useState(<SearchIcon />);

  const handleChange = (value) => {
    if (value.length > 0) {
      setButtonIcon(
        <ClearButtonContainer>
          <DeleteDot />
        </ClearButtonContainer>
      );
    } else {
      setButtonIcon(<SearchIcon />);
    }
    setSearchedText(value);
  };

  return (
    <InputContainer>
      <Input
        placeholder={"Search"}
        text={text}
        showButton
        buttonIcon={buttonIcon}
        handleChange={handleChange}
        handleClick={() => setSearchedText("")}
      />
    </InputContainer>
  );
};

export default Search;
