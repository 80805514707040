import { useRouter } from "next/router";
import { FC, useEffect, useMemo, useRef, useState } from "react";

import BackButton from "@/components/Dom/WorldMap/BackButton";
import MapMarker from "@/components/Dom/WorldMap/MapMarker";
import SpaceInfo from "@/components/Dom/WorldMap/SpaceInfo";
import Tile from "@/components/Dom/WorldMap/Tile";

import moduleService from "@/services/ModuleService";
import { TSpace } from "@/services/SpaceService/types";
import { useSasParams } from "@/utils/Azure";
import { mulberry32, xmur3 } from "@/utils/Math";
import { getImageUrl } from "@/utils/Strapi";

import { StyledSpaceTile, ContentWrapper } from "./styles";

interface ISpaceTile {
  space: TSpace;
  selectedSpace: TSpace["id"] | null;
  index: number;
  offset: number;
  onSelected: (HTMLDivElement, TSpace) => void;
  size: number;
}

const SpaceTile: FC<ISpaceTile> = ({
  space,
  selectedSpace,
  index,
  offset = 0,
  size = 1,
  onSelected,
}) => {
  const seed = useMemo(xmur3(index.toString()), []);
  const randomNumber = useMemo(() => 0.5 - mulberry32(seed) * offset, [offset]);

  const router = useRouter();
  const imageUrl = getImageUrl(space.mapImage, "large");
  const { urlWithParams: bgImageUrlWithParams } = useSasParams(imageUrl);
  const ref = useRef<HTMLDivElement>();

  const [currentStep, setCurrentStep] = useState(0);

  const defaultModule = moduleService
    .getState()
    .getModuleById(space.defaultModule);

  useEffect(() => {
    if (!router.isReady || !ref.current) return;

    if (router.query.space === space.slug && !router.query.module) {
      setCurrentStep(2);
      onSelected(ref.current, space);
    } else {
      setCurrentStep(0);
    }
  }, [router.query.space, router.query.module, router.isReady, ref.current]);

  const onInfoLeave = () => {
    if (currentStep === 1) setCurrentStep(0);
  };

  if (!defaultModule) return null;

  return (
    <StyledSpaceTile
      ref={ref}
      style={{
        "--x": `${randomNumber}vw`,
        "--y": `${1 - randomNumber}vw`,
      }}
      onMouseLeave={onInfoLeave}
    >
      <Tile imageUrl={bgImageUrlWithParams} size={size}>
        <ContentWrapper>
          {selectedSpace === null && (
            <MapMarker
              isVisible={currentStep === 0}
              space={space}
              onMouseEnter={() => {
                setCurrentStep(1);
              }}
            />
          )}
          <SpaceInfo
            isVisible={currentStep === 1}
            space={space}
            onMouseLeave={onInfoLeave}
          />
          <BackButton isVisible={currentStep === 2} />
        </ContentWrapper>
      </Tile>
    </StyledSpaceTile>
  );
};

export default SpaceTile;
