import styled from "styled-components";

import { colors } from "@/styles/colors";
import { TruncateText } from "@/styles/mixins";
import { BigHeadline, Text } from "@/styles/typography";

export const Headline = styled(BigHeadline)`
  ${TruncateText({ maxLines: 3 })};
  word-break: break-word;
`;

export const StyledPanelHeader = styled.div`
  padding: var(--mainNav-overview-panel-padding);
  padding-bottom: 0;
  display: grid;
  grid-gap: 1.8rem;
`;

export const PanelHeaderTop = styled.div`
  align-items: center;
  display: flex;
  min-height: 3.5rem;
`;

export const Left = styled.div`
  margin-right: 0.5rem;
`;

export const Right = styled.div`
  margin-left: 0.5rem;
`;

export const Grow = styled.div`
  flex-grow: 1;
`;

export const StyledOvertitle = styled(Text)`
  color: ${colors.grey.normal.hex};
`;
