import styled from "styled-components";

import { StyledChatPanelProfileList } from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfileList/styles";

import { colors } from "@/styles/colors";

export const StyledChatPanelProfileAchievements = styled(
  StyledChatPanelProfileList
)``;

export const IconWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: ${colors.orange.hex};
  color: ${colors.white.default};
  border-radius: 100%;

  svg {
    width: 0.8rem;
  }
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
