import { useEffect, useState } from "react";

import VideoWindow from "./VideoWindow";

import VideoFullscreen from "@/components/Dom/VideoChat/VideoFullscreen";

import videoService from "@/services/VideoService";

const VideoChat = () => {
  const currentRoomId = videoService((state) => state.currentRoomId);

  const [renderVideo, setRenderVideo] = useState(Boolean(currentRoomId));

  useEffect(() => {
    if (!currentRoomId) {
      // Animate out
      setTimeout(() => {
        if (!videoService.getState().currentRoomId) setRenderVideo(false);
      }, 801);
    } else {
      setRenderVideo(true);
    }
  }, [currentRoomId]);

  if (!renderVideo) return null;

  return (
    <>
      <VideoWindow />
      <VideoFullscreen />
    </>
  );
};

export default VideoChat;
