import styled, { keyframes, css } from "styled-components";

import ButtonPill from "@/components/Dom/Common/ButtonPill";
import { StyledButtonRound } from "@/components/Dom/Common/ButtonRound/styles";
import { IconWrapper } from "@/components/Dom/Host/HostTab/Content/ModuleOverview/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover, MQ } from "@/styles/mixins";

const expand = keyframes`
  0% {
    background-color: rgba(255,255,255,0);
    width: var(--mainNav-width);
  }
  10% {
    background-color: rgba(255,255,255,1);
    width: var(--mainNav-width);
  }
  100% {
    background-color: rgba(255,255,255,1);
    width: calc(var(--mainNav-width) + var(--mainNav-panel-section-width));
  }
`;
const contract = keyframes`
  0% {
    background-color: rgba(255,255,255,1);
    width: calc(var(--mainNav-width) + var(--mainNav-panel-section-width));
  }
  99% {
    background-color: rgba(255,255,255,1);
    width: var(--mainNav-width);
  }
  100% {
    background-color: rgba(255,255,255,0);
    width: var(--mainNav-width);
  }
`;

export const StyledMainNavSection = styled.section`
  border-bottom-left-radius: var(--mainNav-border-radius);
  border-bottom-right-radius: var(--mainNav-border-radius);
  border-top-right-radius: var(--mainNav-border-radius);
  display: flex;
  flex-direction: column;
  max-width: calc(var(--mainNav-width) + var(--mainNav-panel-section-width));
  min-width: var(--mainNav-width);
  overflow: hidden;
  position: relative;
  width: var(--mainNav-width);

  &:last-of-type {
    padding-bottom: 1.2rem;
  }

  &[data-section-open="false"] {
    animation: 0.2s ${contract} ${ease.inOutCubic} forwards;
  }

  &[data-section-open="true"] {
    animation: 0.6s ${expand} ${ease.inOutCubic} forwards;
    margin-top: -2.5rem;
    padding-top: 2.5rem;
  }

  &[data-section-expanded="true"] {
    [class*="StyledChevron"] {
      transform: rotate(-180deg);
    }
  }

  ${MQ.LG`
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  `}
`;

export const StyledHeader = styled.div`
  align-items: start;
  grid-template: auto / var(--mainNav-width) auto 2rem;
  grid-template-areas: "button header";
  justify-content: stretch;
  display: grid;
`;

export const StyledHead = styled.div`
  color: ${colors.grey.light.hex};
  display: grid;
  font-size: 1.1rem;
  gap: 1rem 0;
  grid-template: auto / auto min-content;
  text-transform: uppercase;
  height: 2.2rem;

  ${(props) =>
    !props.showEditorButton &&
    css`
      margin-top: 1rem;
    `}

  > * {
    // title
    &:nth-child(1) {
      align-self: center;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
    // empty message
    &:not(button):nth-child(2) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
    }
    &button {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
    }
  }

  ${MQ.LG`
    font-size: 1.5rem
  `}
`;

export const StyledButton = styled(StyledButtonRound)`
  --button-size: var(--sidebar-panel-button-size);
  --svg-size: 0.733rem;

  ${MQ.LG`
    --svg-size: 0.8rem;
  `}
`;

export const EditorButton = styled(ButtonPill)`
  max-width: initial;
  padding: 0.5rem 0.8rem 0.5rem 0.5rem;

  ${IconWrapper} {
    background-color: ${colors.grey.normal.hex};
    color: ${colors.grey.lightest.hex};
  }

  ${Hover(css`
    ${IconWrapper} {
      background-color: ${colors.white.default};
      color: ${colors.tangerine.normal.hex};
    }
  `)}
`;
