import * as React from "react";
import { useEffect } from "react";

import OnboardingProfile from "@/components/Dom/Onboarding/OnboardingProfile";
import OnboardingVideoWindow from "@/components/Dom/Onboarding/OnboardingVideoWindow";
import SetupLinks from "@/components/Dom/Setup/SetupLinks";
import SidebarPanel from "@/components/Dom/Setup/SidebarPanel";
import SetupImageGallery from "@/components/Dom/SetupImageGallery";
import { eveNpcUser } from "@/components/Pages/Setup/Avatar";

import { TStrapiImage } from "@/types/StrapiImage";

import notificationService from "@/services/NotificationService";
import onboardingService from "@/services/OnboardingService";

import { StyledSetup } from "./styles";

type TSetup = {
  children: any;
  pageProps: { pageData: TStrapiImage[] | {} };
};

const Setup = ({ children, pageProps = { pageData: {} } }: TSetup) => {
  useEffect(() => {
    return () => {
      const { currentNotificationId } = onboardingService.getState();
      if (currentNotificationId)
        notificationService
          .getState()
          .removeNotification(currentNotificationId);
    };
  }, []);

  return (
    <StyledSetup>
      {/*  @ts-expect-error */}
      <SetupImageGallery data={pageProps.pageData?.backgroundImages} />

      <SidebarPanel>{children}</SidebarPanel>

      {eveNpcUser && (
        <>
          <OnboardingProfile user={eveNpcUser} />
          <OnboardingVideoWindow user={eveNpcUser} />
        </>
      )}

      <SetupLinks />
    </StyledSetup>
  );
};

export default Setup;
