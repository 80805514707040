import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

const hiddenStyle = css`
  transform: translate3d(-50%, calc(108% + 2rem), 0);
`;

const visibleStyle = css`
  transition-delay: 0.15s;
  transform: translate3d(-50%, 0, 0);
`;

export const StyledVideoFullscreenControls = styled.div`
  position: fixed;
  bottom: 2rem;
  left: 50%;
  padding: 2rem;
  background-color: rgba(${colors.white.rgba}, 0.6);
  border-radius: 4rem;
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: column;
  z-index: 9;

  transition: transform 0.6s ${ease.inOutCubic}, background-color 0.2s linear;

  ${(props) => (props.isVisible ? visibleStyle : hiddenStyle)};

  ${Hover(`background-color: rgba(${colors.white.rgba}, 1);`)}
`;
