import {
  useWindowHeight,
  useWindowWidth,
} from "@react-hook/window-size/throttled";
import React, { FC, useEffect, useRef, useState } from "react";

import { TPopoverLinks, TPopupPosition } from "@/components/Dom/Popover/types";

import {
  StyledPopover,
  StyledPopoverIcon,
  StyledPopoverButton,
  StyledPopoverTitle,
  StyledPopoverLi,
} from "./styles";

interface IProps {
  links: Array<TPopoverLinks>;
  position: TPopupPosition;
  isActive: boolean;
  onPress: Function;
}

const PopoverLinks: FC<IProps> = ({ links, position, onPress, isActive }) => {
  const ref = useRef<HTMLDivElement>();
  const [isVisible, setIsVisible] = useState(false); // Hide the popup until we measured where to put it
  const [actualPosition, setActualPosition] = useState(position); // Position after the measurements
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();

  const onClick = (item: TPopoverLinks) => {
    onPress();
    item?.handleClick();
  };

  useEffect(() => {
    if (!ref.current) return;
    const bounds = ref.current.getBoundingClientRect();

    const splitPosition = position.split("-");
    let verticalPosition = splitPosition[0];
    let horizontalPosition = splitPosition[1];

    //Too far to the right
    if (bounds.width + bounds.x > windowWidth) {
      horizontalPosition = "left";
    } else if (horizontalPosition === "right") {
      horizontalPosition = "right";
    }

    //Too far to the left
    if (bounds.x - bounds.width < 0) {
      horizontalPosition = "right";
    } else if (horizontalPosition === "left") {
      horizontalPosition = "left";
    }

    //Too far down
    if (bounds.height + bounds.y > windowHeight) {
      verticalPosition = "top";
    } else if (verticalPosition === "bottom") {
      verticalPosition = "bottom";
    }

    //Too far up
    if (bounds.y - bounds.height < 0) {
      verticalPosition = "bottom";
    } else if (verticalPosition === "top") {
      verticalPosition = "top";
    }

    setActualPosition(
      `${verticalPosition}-${horizontalPosition}` as TPopupPosition
    );
    setIsVisible(true);
  }, [position, ref.current, windowWidth, windowHeight, isActive]);

  return (
    <StyledPopover
      ref={ref}
      position={actualPosition}
      isVisible={isActive && isVisible}
    >
      {links.map((item, i) =>
        item.hidden ? (
          <></>
        ) : (
          <StyledPopoverLi key={i}>
            <StyledPopoverButton
              disabled={item.disabled ?? false}
              type="button"
              onClick={() => onClick(item)}
            >
              {item.icon && <StyledPopoverIcon>{item.icon}</StyledPopoverIcon>}

              {item.title && (
                <StyledPopoverTitle bold as={"span"}>
                  {item.toggle ? item.toggleTitle : item.title}
                </StyledPopoverTitle>
              )}
            </StyledPopoverButton>
          </StyledPopoverLi>
        )
      )}
    </StyledPopover>
  );
};

export default PopoverLinks;
