import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";
import { TextStyles } from "@/styles/typography";

export const Icon = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 4rem;
  background-color: ${colors.grey.lightest.hex};
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
`;

export const DownloadButton = styled.button`
  width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: ${colors.white.default};
  background-color: ${colors.grey.normal.hex};
  opacity: 0;
  transition: opacity 0.2s ${ease.outCubic};
  ${Hover(css`
    background-color: ${colors.tangerine.normal.hex};
    color: ${colors.white.default};
  `)}
  &:disabled {
    cursor: not-allowed;
    background-color: ${colors.grey.lighter.hex};
  }
`;

const List = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    opacity: 0;
    background-color: ${colors.grey.lightest.hex};
    border-radius: 4rem;
    height: calc(100% + 1rem);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s linear;
    width: calc(100% + 1rem);
    z-index: -1;
  }
  &:hover {
    ${Icon} {
      background-color: ${colors.tangerine.normal.hex};
      color: ${colors.white.default};
    }
    ${DownloadButton} {
      opacity: 1;
    }
    &:before {
      opacity: 1;
    }
  }
`;

export const StyledMediaItemLink = styled(List)``;

export const StyledMediaItemDefault = styled(List)``;

export const Link = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
`;

export const Title = styled.span`
  ${TextStyles};
  font-weight: 700;
`;

export const Meta = styled.span`
  ${TextStyles};
`;
