import styled from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { BigHeadline } from "@/styles/typography";

export const StyledDiscoverProgress = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: ${colors.white.default};
  padding: 3rem;
  border-radius: 1rem;
  transform: translateY(6rem);
  transition: transform 0.8s ${ease.inOutQuint};

  ${BigHeadline} {
    margin-bottom: 0.5rem;
  }
`;

export const ProgressIndicatorWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
`;
