import {
  StyledLoadingTitle,
  Loading,
  LoadingTarget,
  Line,
} from "@/components/Dom/LoadingScreen/LoadingTitle/styles";

const LoadingTitle = ({ viewState, textColor }) => {
  return (
    <StyledLoadingTitle viewState={viewState} textColor={textColor}>
      <Loading>loading</Loading>

      <LoadingTarget>
        <Line>Virtual</Line>
        <Line>Spaces</Line>
      </LoadingTarget>
    </StyledLoadingTitle>
  );
};

export default LoadingTitle;
