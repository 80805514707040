import { useEffect, useState } from "react";

import { StyledSection } from "../styles";

import CollapseSection from "@/components/Dom/MainNav/CollapseSection";
import SpaceNavItem from "@/components/Dom/MainNav/NavItemList/SpaceNavItem";

import chatService, { EChatType } from "@/services/ChatService";
import moduleService from "@/services/ModuleService";
import navService, { ENavSection } from "@/services/NavService";
import spaceService from "@/services/SpaceService";
import { getNewMessageCount } from "@/utils/Chat";

import { StyledSpaceList } from "./styles";

import Pin from "~/public/assets/icons/Pin.svg";

const SpacesSection = () => {
  const spaces = Array.from(spaceService((state) => state.spaces).values());
  if (!spaces) return null;

  /**
   * The activeSpaceId controls which space is selected in the main nav,
   * indicated by the orange border and the module list expansion
   * It can be different from the currentSpaceId, since the user can be inside
   * of a space and click through the other spaces on the main nav
   */
  const [activeSpaceId, setActiveSpaceId] = useState<number | null>(null);

  const { currentOverviewPanel, spaceNavItemSelected } = navService(
    (state) => ({
      currentOverviewPanel: state.currentOverviewPanel,
      spaceNavItemSelected: state.spaceNavItemSelected,
    })
  );
  const currentSpaceId = spaceService((state) => state.currentSpaceId);

  const chats = chatService((state) =>
    state
      .getChatsByType(EChatType.PUBLIC)
      .sort((chatA, chatB) => chatB.lastActivity - chatA.lastActivity)
  );

  // If the user navigates straight to the space url (eg `/pwc`),
  // the currentSpaceId gets updated and, subsequently, the panel for the space opens
  useEffect(() => {
    // if user is in space, but isn't in the module, expand the space
    if (moduleService.getState().getCurrentModule() === null) {
      if (activeSpaceId !== currentSpaceId) setActiveSpaceId(currentSpaceId);
      return;
    }

    setActiveSpaceId(null);
  }, [currentSpaceId]);

  useEffect(() => {
    if (currentOverviewPanel === null) setActiveSpaceId(null);
  }, [currentOverviewPanel]);

  return (
    <CollapseSection
      icon={<Pin />}
      newMsgCount={getNewMessageCount(chats)}
      title="Spaces"
      section={ENavSection.SPACES}
      hasItems={spaces?.length > 0}
      hasHostButton={true}
    >
      {spaces && (
        <StyledSection>
          <StyledSpaceList>
            {spaces.map((space) => (
              <SpaceNavItem
                key={space.id}
                space={space}
                expanded={spaceNavItemSelected === space.id}
              />
            ))}
          </StyledSpaceList>
        </StyledSection>
      )}
    </CollapseSection>
  );
};

export default SpacesSection;
