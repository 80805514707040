import styled, { keyframes } from "styled-components";

import { colors } from "@/styles/colors";

const loadingAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledLoadingDots = styled.div`
  position: relative;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background-color: ${colors.white.default};
  color: ${colors.white.default};
  animation: ${loadingAnimation} 1s infinite linear alternate;
  animation-delay: 0.5s;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -0.75rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: ${colors.white.default};
    color: ${colors.white.default};
    animation: ${loadingAnimation} 1s infinite alternate;
    animation-delay: 0s;
  }

  &:after {
    left: 0.75rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: ${colors.white.default};
    color: ${colors.white.default};
    animation: ${loadingAnimation} 1s infinite alternate;
    animation-delay: 1s;
  }
`;
