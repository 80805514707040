import styled from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

export const calcIndicatorPosition = (sectionCount, curIndex, totalWidth) => {
  // iconWidth + 2 * iconWrapperPadding
  const iconWrapperWidth = 30;
  const widthBetween =
    (totalWidth - sectionCount * iconWrapperWidth) / (sectionCount + 1);
  const indicatorPosition =
    (curIndex + 1) * (widthBetween + iconWrapperWidth) - 35;
  return `${indicatorPosition / 10.0}rem`;
};

export const StyledTabs = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding-bottom: 0.5rem;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background-color: ${colors.grey.lightest.hex};
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 4rem;
    height: 0.2rem;
    background-color: ${colors.tangerine.normal.hex};
    transition: transform var(--transition-time) ${ease.inOutCubic};
    transform: translate3d(var(--translateX), 0, 0);
  }
`;
