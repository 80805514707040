import { UseFormRegisterReturn } from "react-hook-form";

import { InputWrapper, StyledInput } from "../styles";

import { colors } from "@/styles/colors";

interface IFormInput {
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  error?: any;
  backgroundColor?: string;
  borderColor?: string;
  register?: UseFormRegisterReturn;
  defaultValue?: any;
  value?: any;
  inputmode?: string;
}
const FormInput: React.FC<IFormInput> = ({
  placeholder = "",
  type = "text",
  disabled = false,
  error = false,
  backgroundColor = colors.white.default,
  borderColor = colors.tangerine.normal.hex,
  register = {},
  defaultValue = "",
  value,
  ...others
}) => {
  return (
    <InputWrapper
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      error={error}
      isDisabled={disabled}
      {...others}
    >
      <StyledInput
        {...register}
        type={type}
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
      />
    </InputWrapper>
  );
};

export default FormInput;
