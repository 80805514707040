import { FC, useEffect } from "react";

import Button from "@/components/Dom/Common/Button";
import Select from "@/components/Dom/Common/Select";

import popupOverlayService from "@/services/PopupOverlayService";
import videoService from "@/services/VideoService";

import { BigMidHeadline, Text } from "@/styles/typography";

import {
  StyledVideoSettingsControl,
  StyledSelectLabel,
  StyledIcon,
  Content,
  StyledSelectContainer,
  ButtonWrapper,
} from "./styles";

import Camera from "~/public/assets/icons/Camera.svg";
import Microphone from "~/public/assets/icons/Microphone.svg";
import Speaker from "~/public/assets/icons/Speaker.svg";

type TVideoGridControl = {
  acceptButtonText?: string;
  headline?: string;
  subHeadline?: string;
};

const VideoSettingsControl: FC<TVideoGridControl> = ({
  subHeadline = "Audio & Video",
  headline = "Change your In- & Outputs",
}) => {
  const {
    availableVideoDevices,
    availableAudioInputDevices,
    availableAudioOutputDevices,
    selectedCamera,
    selectedAudioInput,
    selectedAudioOutput,
    getAvailableDevices,
  } = videoService((state) => ({
    availableVideoDevices: state.availableVideoDevices,
    availableAudioInputDevices: state.availableAudioInputDevices,
    availableAudioOutputDevices: state.availableAudioOutputDevices,
    selectedCamera: state.selectedCamera,
    selectedAudioInput: state.selectedAudioInput,
    selectedAudioOutput: state.selectedAudioOutput,
    getAvailableDevices: state.getAvailableDevices,
  }));

  useEffect(() => {
    getAvailableDevices();
  }, []);

  const { close } = popupOverlayService.getState();
  const handleAccept = () => close();
  const handleSwitchCamera = (deviceId: string) => {
    videoService.getState().switchCamera(deviceId);
  };
  const handleSwitchAudioInput = (deviceId: string) => {
    videoService.getState().switchAudioInput(deviceId);
  };
  const handleChangeAudioOutput = (deviceId: string) => {
    videoService.getState().switchAudioOutput(deviceId);
  };
  return (
    <StyledVideoSettingsControl>
      <Text>{subHeadline}</Text>
      <BigMidHeadline serif>{headline}</BigMidHeadline>

      <Content>
        <StyledSelectContainer>
          <StyledSelectLabel>
            <StyledIcon>
              <Camera />
            </StyledIcon>
            Camera Signal:
          </StyledSelectLabel>
          <Select
            handleChange={(e) => handleSwitchCamera(JSON.parse(e.target.value))}
            defaultValue={selectedCamera}
            options={[
              ...availableVideoDevices.map((option) => ({
                value: option.deviceId,
                label: option.label,
              })),
            ]}
          />
        </StyledSelectContainer>

        <StyledSelectContainer>
          <StyledSelectLabel>
            <StyledIcon>
              <Microphone />
            </StyledIcon>
            Microphone Input:
          </StyledSelectLabel>
          <Select
            handleChange={(e) =>
              handleSwitchAudioInput(JSON.parse(e.target.value))
            }
            defaultValue={selectedAudioInput}
            options={[
              ...availableAudioInputDevices.map((option) => ({
                value: option.deviceId,
                label: option.label,
              })),
            ]}
          />
        </StyledSelectContainer>

        <StyledSelectContainer>
          <StyledSelectLabel>
            <StyledIcon>
              <Speaker />
            </StyledIcon>
            Speaker Output:
          </StyledSelectLabel>
          <Select
            handleChange={(e) =>
              handleChangeAudioOutput(JSON.parse(e.target.value))
            }
            defaultValue={selectedAudioOutput}
            options={[
              ...availableAudioOutputDevices.map((option) => ({
                value: option.deviceId,
                label: option.label,
              })),
            ]}
          />
        </StyledSelectContainer>
      </Content>

      <ButtonWrapper>
        <Button text={"Accept"} onClick={handleAccept} />
      </ButtonWrapper>
    </StyledVideoSettingsControl>
  );
};

export default VideoSettingsControl;
