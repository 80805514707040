import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

export const StyledCheckbox = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 3rem;

  &[data-disabled="true"] {
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const Label = styled.label`
  color: ${colors.grey.text.hex};
  cursor: inherit;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  transition: color 0.3s ${ease.inOutCubic};
`;

export const CheckboxInput = styled.input`
  appearance: none;
  border: 0.1rem solid ${colors.orange.hex};
  border-radius: var(--checkbox-radius);
  cursor: inherit;
  height: 2rem;
  position: relative;
  width: 2rem;

  :disabled {
    border-color: ${colors.grey.normal.hex};
  }

  &:after {
    content: "";
    background-color: transparent;
    background-repeat: no-repeat;
    border-radius: 0.3rem;
    display: block;
    height: calc(100% - 0.8rem);
    position: absolute;
    transition: background-color 0.3s ${ease.inOutCubic};
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: calc(100% - 0.8rem);
  }

  &:checked {
    border-color: ${colors.tangerine.normal.hex};

    + ${Label} {
      color: ${colors.tangerine.normal.hex};
    }

    &:after {
      background-color: ${colors.tangerine.normal.hex};
    }

    &:disabled {
      border-color: ${colors.grey.normal.hex};

      + ${Label} {
        color: ${colors.grey.normal.hex};
      }

      &:after {
        background-color: ${colors.grey.normal.hex};
      }
    }
  }

  &:not(:disabled) {
    ${Hover(css`
      + ${Label} {
        color: ${colors.tangerine.normal.hex};
      }
    `)}
  }

  ${(props) =>
    props.error &&
    css`
      border: 0.2rem solid ${colors.red.normal.hex};
      background-color: ${colors.red.light.hex};
    `}
`;
