import styled, { css } from "styled-components";

import { ease } from "@/styles/ease";
import { RemoveScrollBar } from "@/styles/mixins";

export const StyledAnimatedHeight = styled.div`
  overflow: hidden;
  height: ${(props) => `${props.height / 10}rem`};

  ${(props) =>
    props.animate &&
    css`
      transition: height 0.45s ${ease.inOutCubic};
    `}
`;

export const StyledScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden scroll;

  ${({ showScrollbar }) => !showScrollbar && RemoveScrollBar}
`;
