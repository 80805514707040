import React, { useEffect, useMemo, useState } from "react";

import Select from "@/components/Dom/Common/Select";
import {
  StyledModuleNotification,
  StyledNotificationInput,
} from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfoNotification/styles";

import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import permissionService, {
  PERMISSION_ACTION,
} from "@/services/PermissionService";
import rehService from "@/services/RehService";
import spaceService from "@/services/SpaceService";
import { TUser } from "@/services/UserService/types";
import { buildModuleSlug } from "@/utils/Module";

import { MidHeadline } from "@/styles/typography";

import SendIcon from "~/public/assets/icons/Send.svg";

const ModuleInfoNotification = () => {
  const { currentSpaceId, groups } = spaceService((state) => ({
    currentSpaceId: state.currentSpaceId,
    groups: state.getCurrentSpace()?.groups || [],
  }));

  const modules = moduleService((state) =>
    state.getModulesBySpaceId(currentSpaceId || 0)
  );

  const [selectedUserIds, setSelectedUserIds] = useState<TUser["id"][]>([]);
  const [
    selectedNotificationModuleId,
    setSelectedNotificationModuleId,
  ] = useState<TModule["id"]>(NaN);

  const sendNotification = (message: string) => {
    const payload = {
      ...(!isNaN(selectedNotificationModuleId) && {
        btnLabel: "Go to Module",
        moduleSlug: buildModuleSlug(selectedNotificationModuleId),
      }),
    };
    rehService.getState().sendNotification(message, selectedUserIds, payload);
  };

  const userGroups = useMemo(() => {
    return groups.filter((group) =>
      group.accessModules.includes(selectedNotificationModuleId)
    );
  }, [groups, selectedNotificationModuleId]);

  useEffect(() => {
    setSelectedUserIds([]);
  }, [selectedNotificationModuleId]);

  // do not display component if we are not in a space or not a host in current space
  if (
    !currentSpaceId ||
    !permissionService.getState().can(PERMISSION_ACTION.NOTIFICATION_SEND)
  )
    return null;

  return (
    <StyledModuleNotification>
      <MidHeadline>Notifications</MidHeadline>

      <StyledNotificationInput
        placeholder={"Write to all users"}
        showButton
        buttonIcon={<SendIcon />}
        handleClick={sendNotification}
      />

      <Select
        handleChange={(e) =>
          setSelectedNotificationModuleId(Number(e.target.value))
        }
        options={[
          { value: "", label: "Select module" },
          ...modules.map((module) => ({
            value: module.id,
            label: module.title,
          })),
        ]}
      />

      <Select
        handleChange={(e) => setSelectedUserIds(JSON.parse(e.target.value))}
        options={[
          { value: [], label: "Notify everybody" },
          ...userGroups.map((group) => ({
            value: group.userIds,
            label: group.name,
          })),
        ]}
      />
    </StyledModuleNotification>
  );
};

export default ModuleInfoNotification;
