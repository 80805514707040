import styled, { css } from "styled-components";

import { StyledDiscoverProgress } from "@/components/Dom/DiscoverQuest/DiscoverProgress/styles";
import { StarWrapper } from "@/components/Dom/DiscoverQuest/ProgressButton/styles";
import {
  progressIndicatorVisibleStyles,
  StyledProgressIndicator,
} from "@/components/Dom/DiscoverQuest/ProgressIndicator/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

const expandedStyles = css`
  ${StarWrapper} {
    background-color: ${colors.tangerine.normal.hex};
    color: ${colors.white.default};
  }

  ${StyledDiscoverProgress} {
    transform: translateY(0);
  }

  ${StyledProgressIndicator} {
    ${progressIndicatorVisibleStyles};
  }

  transform: translate(-50%, 0);
`;

export const StyledCollectGame = styled.div`
  position: fixed;
  bottom: var(--mainNav-margin);
  left: 50%;
  min-width: 25rem;
  z-index: 12;

  transform: translate(-50%, calc(100% - 6rem));
  transition: transform 0.8s ${ease.inOutQuint};

  ${(props) =>
    !props.isVisible &&
    css`
      transform: translate(-50%, calc(100% + var(--mainNav-margin)));
    `}
  ${(props) => props.isExpanded && expandedStyles}
`;
