import styled, { css } from "styled-components";

import { StyledMainNavSection } from "../../styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

export const StyledIcon = styled.div`
  color: var(--color);
  display: block;
  height: 4rem;
  overflow: unset;
  position: relative;
  transition: fill 0.2s linear, color 0.2s linear;
  width: 4rem;

  > svg {
    height: 100%;
    width: 100%;
  }
`;

export const StyledChevron = styled.div`
  color: ${colors.grey.text.hex};
  display: flex;
  height: 2rem;
  justify-content: center;
  margin: 0;
  opacity: 0;
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform 0.3s ${ease.inOutCubic}, opacity 0.2s linear;
  width: 2rem;

  > svg {
    display: block;
    width: 0.8rem;
  }

  &[data-show="false"] {
    opacity: 0;
    visibility: hidden;
  }

  &[data-show="true"] {
    ${Hover(
      css`
        opacity: 1;
      `,
      StyledMainNavSection
    )}
  }
`;
