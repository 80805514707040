import Link from "next/link";
import { useEffect, useRef } from "react";

import ChatsSection from "./Sections/ChatsSection";

import FloatingProfile from "@/components/Dom/MainNav/FloatingProfile";
import SpacesSection from "@/components/Dom/MainNav/Sections/SpacesSection";
import OverviewPanel from "@/components/Dom/OverviewPanel";

import initService from "@/services/InitService";
import moduleService from "@/services/ModuleService";
import navService from "@/services/NavService";

import {
  StyledMainNav,
  StyledNavSections,
  StyledLogoWrapper,
  StyledSections,
} from "./styles";

import PwcLogo from "~/public/assets/icons/PwC_simple.svg";

export enum ClickOutsideWhitelist {
  SPACE_INFO = "SpaceInfo",
  OVERVIEW_PANEL = "OverviewPanel",
  MAIN_NAV = "MainNav",
}

const MainNav = () => {
  const navRef = useRef<HTMLDivElement>();
  const isInitialized = initService((state) => state.isInitialized);

  const { isNavVisible, currentOverviewPanel } = navService((state) => ({
    isNavVisible: state.isNavVisible,
    currentOverviewPanel: state.currentOverviewPanel,
  }));

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    const nodeName = e.target.nodeName;
    const isWhitelist = Object.values(ClickOutsideWhitelist).some(
      (whitelistComponent) => {
        const closest = e.target.closest(
          `[data-component=${whitelistComponent}]`
        );
        return Boolean(closest);
      }
    );

    if (isWhitelist) return;

    if (moduleService.getState().currentModuleId) {
      const isAvatarCTA =
        nodeName === "CANVAS" && document.body.style.cursor === "pointer";
      if (!isAvatarCTA) {
        navService.getState().closeAllPanels();
      }
      navService.getState().setSpaceNavItemSelected(null);
      return;
    }

    navService.getState().closeAllPanels();
    navService.getState().setSpaceNavItemSelected(null);
  };

  const sections = navService.getState().navSections;
  const hasOpenSection = Array.from(sections.values()).some((value) => value);

  return (
    <div>
      <StyledMainNav
        ref={navRef}
        isVisible={isNavVisible}
        data-overview-open={currentOverviewPanel !== null}
        data-active-section={hasOpenSection}
      >
        <StyledNavSections
          data-component={ClickOutsideWhitelist.MAIN_NAV} // used in the click outside function
        >
          <StyledLogoWrapper href="/" passHref={true}>
            <PwcLogo />
          </StyledLogoWrapper>

          <StyledSections>
            <SpacesSection key={`nav-section-${1}`} />
            <ChatsSection key={`nav-section-${2}`} />
          </StyledSections>
        </StyledNavSections>

        {isInitialized && <FloatingProfile />}

        <OverviewPanel
          data-component={ClickOutsideWhitelist.OVERVIEW_PANEL} // used in the click outside function
        />
      </StyledMainNav>
    </div>
  );
};

export default MainNav;
