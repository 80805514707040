import styled, { css } from "styled-components";

import { StyledScreenshareParticipantControlsHoverStyled } from "@/components/Dom/VideoChat/VideoWindow/ShareParticipantControls/styles";
import { StyledVideoWindowParticipant } from "@/components/Dom/VideoChat/VideoWindow/VideoWindowParticipant/styles";
import { getColumns, getRows } from "@/components/Dom/WorldMap/styles";

import { TVideoGrid } from "@/services/VideoService/types";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

export const StyledVideoWindow = styled.aside`
  //display: flex;
  position: fixed;
  top: var(--mainNav-margin);
  right: var(--mainNav-margin);
  z-index: 13;
  max-height: calc(100vh - var(--mainNav-margin) * 2);
  transition: transform 0.5s ${ease.inOutCubic};
  will-change: transform;

  ${(props) =>
    props.isVisible
      ? css`
          transform: translate3d(0, 0, 0);
        `
      : css`
          transform: translate3d(calc(100% + var(--mainNav-margin)), 0, 0);
        `};

  ${(props) =>
    props.videoGrid === TVideoGrid.LARGE &&
    css`
      --width: calc(
        var(--video-participants-list-width) + var(--mainNav-panel-width) +
          var(--mainNav-width)
      );
      width: ${props.showParticipantsList
        ? props.isOverviewPanelVisible === null
          ? "calc(100% - 4rem - var(--video-participants-list-width))"
          : "calc(100% - 6rem - var(--width))"
        : "calc(100% - 4rem)"};
    `}

  ${StyledScreenshareParticipantControlsHoverStyled};
`;

// export const TestParticipant = styled.div`
//   background-color: pink;
//   display: grid;
//   place-items: center;
//   width: var(--videoWidth);
//   height: var(--videoHeight);
// `;

export const StyledParticipantsCamera = styled.div`
  position: relative;
  z-index: 1;
  background-color: rgba(${colors.white.rgba}, 1);
  ${(props) =>
    props.participantsList
      ? css`
          border-top-right-radius: var(--video-window-radius);
          border-bottom-right-radius: var(--video-window-radius);
        `
      : css`
          border-radius: var(--video-window-radius);
        `};
`;

export const TilesWrapper = styled.div`
  position: relative;
  display: grid;
  grid-gap: 0.2rem;
  max-height: calc(100vh - var(--mainNav-margin) * 2 - 6rem);
  overflow: hidden;
  border-top-left-radius: var(--video-window-radius);
  border-top-right-radius: var(--video-window-radius);

  //Video width
  // By calculating the scale it's possible that the video width gets smaller than the controls at the bottom
  // so we set the min width to 170px which is half of the controls
  grid-template-columns: repeat(${(props) => props.rows}, var(--videoWidth));
  ${(props) =>
    props.videoGrid === TVideoGrid.MEDIUM &&
    css`
      ${StyledVideoWindowParticipant} {
        &:last-child {
          grid-column-end: 3;
        }
      }
    `};
  ${(props) =>
    props.videoGrid === TVideoGrid.LARGE &&
    css`
      height: 100vh;
      align-items: center;
      justify-content: center;
      background-color: ${colors.grey.normal.hex};

      grid-template-rows: repeat(
        ${({ tileCount }) => getRows(tileCount + 1)},
        1fr
      );
      grid-template-columns: repeat(
        ${({ tileCount }) => getColumns(tileCount + 1)},
        1fr
      );
    `};
`;
