import React from "react";

import {
  StyledModuleInfoDescription,
  Title,
} from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfoDescription/styles";

import { TModule } from "@/services/ModuleService/types";

import { Text } from "@/styles/typography";

const ModuleInfoDescription = ({ module }: { module: TModule }) => {
  if (!module.description) return null;

  return (
    <StyledModuleInfoDescription>
      <Title>About</Title>
      <Text>{module.description}</Text>
    </StyledModuleInfoDescription>
  );
};

export default ModuleInfoDescription;
