import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { DarkTheme } from "@/styles/mixins";
import { TextStyles } from "@/styles/typography";

export const StyledFormTextarea = styled.textarea`
  ${TextStyles};
  background-color: transparent;
  color: ${colors.black.hex};
  padding: 1.1rem;

  ${DarkTheme(css`
    border-radius: var(--input-radius);
    color: ${colors.white.default};
    border: 1px solid ${colors.orange.hex};
  `)}
`;
