import { ReactNode } from "react";

import InfoOverlay from "@/components/Dom/InfoOverlay";
import MainNav from "@/components/Dom/MainNav";
import VideoChat from "@/components/Dom/VideoChat";
import WorldMap from "@/components/Dom/WorldMap";

import { Page } from "@/layouts/AppLayout/styles";
import recorderService from "@/services/RecorderService";

export interface IDefaultLayout {
  children: ReactNode;
  enableMap: boolean;
  enableScene: boolean;
}

const DefaultLayout = ({ children, enableMap }: IDefaultLayout) => {
  const isRecording = recorderService((state) => state.isRecording);

  return (
    <>
      <Page isRecording={isRecording}>
        <main>
          {children}
          <WorldMap render={enableMap} />
        </main>

        <InfoOverlay />
        <VideoChat />
        <MainNav />
      </Page>
    </>
  );
};

export default DefaultLayout;
