import { FC, useEffect, useRef, useState } from "react";

import {
  TDiscoverTaskData,
  TDiscoverTaskState,
} from "@/services/DiscoverQuestService/types";
import useClickOutside from "@/utils/UseClickOutside";

import { StyledProgressIndicator } from "./styles";

import Star from "~/public/assets/icons/Star.svg";

interface IProgressIndicator {
  taskData: TDiscoverTaskData;
  completed: TDiscoverTaskState["completed"];
  wrapperVisible: boolean;
  index: number;
}

const ProgressIndicator: FC<IProgressIndicator> = ({
  taskData,
  completed,
  wrapperVisible,
  index,
}) => {
  const ref = useRef();
  const [popoverVisible, setPopoverVisible] = useState(false);

  useClickOutside(ref, () => {
    setPopoverVisible(false);
  });

  useEffect(() => {
    if (!wrapperVisible) setPopoverVisible(false);
  }, [wrapperVisible]);

  const onClick = () => {
    setPopoverVisible((state) => !state);
  };

  return (
    <StyledProgressIndicator
      forwardRef={ref}
      completed={completed}
      popoverVisible={popoverVisible}
      mid
      onClick={onClick}
      icon={<Star />}
      data-content={taskData.description}
      style={{
        "--transition-delay": `${0.35 + index * 0.037}s`,
        "--svg-size": "1.2rem",
      }}
    />
  );
};

export default ProgressIndicator;
