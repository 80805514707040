import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { DarkTheme, Hover, MQ } from "@/styles/mixins";

export const InputWrapper = styled.div`
  min-height: 4rem;
  border-radius: var(--input-radius);
  width: 100%;
  display: flex;
  place-items: center;

  //Default styles
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};

  //Disabled styles
  ${(props) =>
    props.isDisabled &&
    css`
      border: 1px solid ${colors.grey.lighter.hex};
      background-color: ${colors.grey.lightest.hex};
    `}

  //error styles
  ${(props) =>
    props.error &&
    css`
      background-color: ${colors.red.light.hex};
      border: 1px solid ${colors.red.normal.hex};
    `}

  ${DarkTheme(css`
    border: 1px solid ${colors.orange.hex};
    background-color: initial;
  `)}
`;

const placeholderColor = (color) => css`
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${color};
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${color};
  }
`;

export const StyledInput = styled.input`
  background-color: rgba(0, 0, 0, 0);
  border-radius: var(--input-radius);
  color: ${colors.black.hex};
  font-size: 1.1rem;
  line-height: 1.5;
  padding: 1.1rem 0.5rem 1.1rem 1.9rem;
  width: 100%;

  ${MQ.LG`${css`
    font-size: 1.5rem;
  `}
  `};

  ${placeholderColor(colors.grey.text.hex)};

  ${(props) =>
    props.error &&
    css`
      color: ${colors.black.hex};
      ${placeholderColor(colors.black.hex)};
    `};

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${DarkTheme(css`
    color: ${colors.white.default};
    background-color: ${colors.grey.darker.hex};
  `)}
`;

export const InputButton = styled.button`
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 0.5rem;
  border-radius: 100%;
  transition: background-color 0.2s linear;

  ${MQ.LG`
    width: 4rem;
    height: 4rem;
  `}

  svg {
    width: 1.3rem;
    color: ${colors.grey.normal.hex};
  }

  ${Hover(css`
    background-color: ${colors.grey.lightest.hex};
  `)}
`;
