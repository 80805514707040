import styled from "styled-components";

import Button from "@/components/Dom/Common/Button";
import SasImage from "@/components/Dom/Common/SasImage";

export const StyledModuleInfoHero = styled.section``;

export const HeroImage = styled(SasImage)`
  border-radius: var(--map-grid-image-radius);
  overflow: hidden;
`;

export const HeroButton = styled(Button)`
  margin: -2rem auto 0;
`;
