import { useEffect, useState } from "react";
import * as React from "react";

import ChatPanelProfileAchievements from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfileAchievements";
import ChatPanelProfileHero from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfileHero";
import ChatPanelProfileList from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfileList";
import { StyledChatPanelUserProfile } from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelUserProfile/styles";

import debugService from "@/services/DebugService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

type TAboutData = { headline: string; text: string };
type TContactData = { headline: string; text: string; link: string };

const ChatPanelUserProfile = ({ user }: { user: TUser }) => {
  const [aboutData, setAboutData] = useState<TAboutData[]>([]);
  const [contactData, setContactData] = useState<TContactData[]>([]);

  useEffect(() => {
    if (!user?.userData) return;
    const { userData } = user;

    if (!Object.keys(userData).length) {
      userService
        .getState()
        .getUserData(user.id)
        .catch((error) =>
          debugService
            .getState()
            .logError(
              `ChatPanelUserProfile::init(): Failed to get user data = ${error}`
            )
        );
      return;
    }

    setAboutData(() => {
      const { company, position, department } = userData;
      const data: TAboutData[] = [];

      company && data.push({ headline: "Company", text: company });

      position &&
        data.push({
          headline: "Position / Grade",
          text: position,
        });

      department && data.push({ headline: "Department", text: department });

      return data;
    });

    setContactData(() => {
      const { linkedinName, xingName } = userData;
      const data: TContactData[] = [];

      linkedinName &&
        data.push({
          headline: "LinkedIn Profile",
          text: linkedinName.replace("https://", "").replace("https:", ""),
          link: linkedinName,
        });

      xingName &&
        data.push({
          headline: "Xing Profile",
          text: xingName.replace("https://", "").replace("https:", ""),
          link: xingName,
        });

      return data;
    });
  }, [user]);

  return (
    <StyledChatPanelUserProfile>
      <ChatPanelProfileHero user={user} />

      <ChatPanelProfileList headline="About" data={aboutData} />
      <ChatPanelProfileList headline="Contact" data={contactData} />
      <ChatPanelProfileAchievements user={user} />
    </StyledChatPanelUserProfile>
  );
};

export default ChatPanelUserProfile;
