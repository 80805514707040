import { useRouter } from "next/router";
import { FC } from "react";

import ButtonRound from "@/components/Dom/Common/ButtonRound";

import { StyledBackButton } from "./styles";

import Close from "~/public/assets/icons/CloseBold.svg";

type TBackButton = {
  isVisible: boolean;
};

const BackButton: FC<TBackButton> = ({ isVisible }) => {
  const router = useRouter();

  const onClick = () => {
    router.push("/");
  };

  return (
    <StyledBackButton isVisible={isVisible}>
      <ButtonRound icon={<Close />} onClick={onClick} mid />
    </StyledBackButton>
  );
};

export default BackButton;
