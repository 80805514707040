import { FC, HTMLAttributes, useEffect } from "react";

import debugService from "@/services/DebugService";
import userService, { userCanBeRendered } from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

import { StyledUser, StyledUserListItem } from "./styles";

type TUserListItem = HTMLAttributes<HTMLButtonElement>;

interface IProps {
  user: TUser;
  handleClick: Function;
  isDisabled: boolean;
  isActive: boolean;
}

const UserListItem: FC<IProps & TUserListItem> = ({
  user,
  handleClick,
  isDisabled = false,
  isActive = false,
}) => {
  const onClick = () => {
    handleClick(user.id);
  };

  useEffect(() => {
    const init = async () => {
      try {
        await userService.getState().getUserData(user.id);
      } catch (error) {
        debugService
          .getState()
          .logError(
            `UserListItem::useEffect(): Failed to get user data = ${error}`
          );
      }
    };

    init();
  }, []);

  if (!userCanBeRendered(user)) return null;

  return (
    <StyledUserListItem>
      <StyledUser
        user={user}
        onClick={onClick}
        aria-checked={isActive}
        isDisabled={isDisabled}
        isActive={isActive}
        role="checkbox"
      />
    </StyledUserListItem>
  );
};

export default UserListItem;
