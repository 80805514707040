import React, { FC } from "react";
import { RemoteParticipant, LocalParticipant } from "twilio-video";

import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";
import Tabs from "@/components/Dom/Common/Tabs";
import InfoIcon from "@/components/Dom/Common/Tabs/Icons/Info.svg";
import { TTabSectionData } from "@/components/Dom/Common/Tabs/TabSection";
import ParticipantsControls from "@/components/Dom/VideoChat/VideoWindow/ParticipantsControls";
import ParticipantsInfo from "@/components/Dom/VideoChat/VideoWindow/ParticipantsInfo";

import videoService from "@/services/VideoService";

import { StyledParticipantsList } from "./styles";

interface Props {
  participants: (LocalParticipant | RemoteParticipant)[];
  reconnectingParticipants: RemoteParticipant[];
}

const tabSections: Array<TTabSectionData> = [{ icon: <InfoIcon /> }];

const tabIndex = 0;

const ParticipantsList: FC<Props> = ({
  participants,
  reconnectingParticipants,
}) => {
  const isVisible = videoService((state) => state.showParticipantsList);
  const body = [
    <ParticipantsInfo
      key={0}
      participants={participants}
      reconnectingParticipants={reconnectingParticipants}
    />,
  ];

  return (
    <StyledParticipantsList visible={isVisible}>
      <FlexAndGrow
        header={
          <Tabs
            tabSectionIndex={tabIndex}
            onClick={() => false}
            tabSections={tabSections}
          />
        }
        body={body[Number(tabIndex)]}
      />
      <ParticipantsControls />
    </StyledParticipantsList>
  );
};

export default ParticipantsList;
