import styled from "styled-components";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const StyledMessage = styled(Text)`
  text-transform: none;
`;

export const StyledPlusButton = styled.span`
  align-items: center;
  background-color: ${colors.grey.lightest.hex};
  border-radius: 100%;
  color: ${colors.grey.text.hex};
  display: inline-flex;
  height: 2rem;
  justify-content: center;
  width: 2rem;

  > svg {
    height: 0.5rem;
    width: 0.5rem;
  }
`;
