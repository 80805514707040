import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { DarkTheme, Hover } from "@/styles/mixins";
import { TextStyles } from "@/styles/typography";

export const OptionsWrapper = styled.select`
  color: ${colors.grey.normal.hex};
  ${TextStyles};
  font-weight: 700;
  width: 100%;
  cursor: pointer;
  padding: 1.4rem 0 1rem 1rem;

  &:active {
    cursor: auto;
  }

  ${DarkTheme(css`
    background-color: transparent;
  `)}
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0.9rem;
  top: 55%;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  pointer-events: none;
  background-color: ${colors.white.default};
  transition: background-color 0.2s linear;
  transform: translateY(-50%);

  display: grid;
  place-items: center;

  svg {
    transform: rotate(-90deg);
    width: 0.6rem;
    transition: color 0.2s linear;
  }

  ${DarkTheme(css`
    background-color: transparent;
    
    ${Hover(css`
        color: ${colors.orange.hex};
      `), OptionsWrapper}
  `)}

`;

export const StyledOption = styled.option`
  width: 100%;
`;

export const StyledSelect = styled.div`
  position: relative;
  border-bottom: 0.1rem solid ${colors.grey.normal.hex};

  ${Hover(css`
    ${IconWrapper} {
      background-color: ${colors.grey.lightest.hex};

      svg {
        color: ${colors.tangerine.normal.hex};
      }
    }
  `)}

  ${DarkTheme(css`
  ${Hover(css`
  border-color: ${colors.orange.hex};

  ${IconWrapper} {
    background-color: transparent;
  }
  `)}

  `)}
`;
