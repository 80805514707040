import styled, { css } from "styled-components";

import ButtonRound from "@/components/Dom/Common/ButtonRound";

import { colors } from "@/styles/colors";

export const StyledAvatarReactionButton = styled(ButtonRound)`
  color: ${colors.grey.dark.hex};
  background-color: ${colors.grey.lightest.hex};
  transition-delay: calc(var(--index) * 0.055s);

  ${(props) =>
    !props.isOpen &&
    css`
      transform: translateX(130%) rotate(35deg) scale(0.7);
    `}
`;
