import styled, { css } from "styled-components";

import ButtonRound from "@/components/Dom/Common/ButtonRound";
import { StyledScreenshareParticipant } from "@/components/Dom/VideoChat/VideoWindow/ShareParticipant/styles";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";

export const StyledShareParticipantControls = styled.div`
  position: absolute;
  bottom: 1.2rem;
  left: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white.default};
  border-radius: 2rem;
`;

export const LeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
`;

export const StyledExpandButton = styled(ButtonRound)`
  --icon-size: 0.8rem;
  --button-size: 3rem;
  background-color: ${colors.grey.lightest.hex};
  color: ${colors.grey.normal.hex};

  ${Hover(css`
    color: ${colors.white.default};
  `)}
`;

export const ScreenShareIcon = styled.div`
  position: relative;
  margin-right: 1rem;
  width: 1.8rem;
  height: 1.8rem;
  background-color: ${colors.grey.normal.hex};
  display: grid;
  place-items: center;
  border-radius: 50%;

  svg {
    fill: ${colors.white.default};
    width: 0.86rem;
    height: 0.6rem;
  }
`;

export const StyledScreenshareParticipantControlsHoverStyled = css`
  ${StyledScreenshareParticipant} {
    ${StyledShareParticipantControls} {
      background-color: rgba(${colors.white.rgba}, 0);
      transition: background-color 0.25s linear;

      ${LeftWrapper} {
        background-color: rgba(${colors.white.rgba}, 0.6);
        border-radius: 0.9rem;
        padding-right: 0.8rem;
      }
    }

    &:hover {
      ${StyledShareParticipantControls} {
        background-color: rgba(${colors.white.rgba}, 0.6);

        ${LeftWrapper} {
          background-color: rgba(${colors.white.rgba}, 0);
        }
      }
    }
  }
`;
