import { TChat } from "@/services/ChatService";

export const getNewMessageCount = (chats: TChat[]): number => {
  const newMessageCount = chats.reduce((acc, chat) => {
    if (chat.newMessageCount > 0) acc += chat.newMessageCount;
    return acc;
  }, 0);
  return newMessageCount;
};

export const getChatSubtitle = (chat) => {
  if (chat.newMessageCount > 0)
    return `${chat.newMessageCount} new ${
      chat.newMessageCount === 1 ? "message" : "messages"
    }`;
  return null;
};
