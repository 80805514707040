import * as React from "react";

import User from "@/components/Dom/Common/User";
import { StyledChatPanelGroupList } from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelGroupList/styles";

import { TUser } from "@/services/UserService/types";

import { MidHeadline } from "@/styles/typography";

import { ChatPanelGroupHeader, Ul } from "./styles";

const ChatPanelGroupList = ({ users }: { users: TUser[] }) => {
  return (
    <StyledChatPanelGroupList>
      <ChatPanelGroupHeader>
        <MidHeadline>In this group</MidHeadline>

        {/*TODO: make add to group functionality work with new chat system */}
        {/*<ButtonRound mid icon={<GroupAdd />} onClick={() => {}} />*/}
      </ChatPanelGroupHeader>

      <Ul>
        {users.map((user) => (
          <li key={user.id}>
            <User user={user} />
          </li>
        ))}
      </Ul>
    </StyledChatPanelGroupList>
  );
};

export default ChatPanelGroupList;
