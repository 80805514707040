import { Img, StyledSasImage } from "@/components/Dom/Common/SasImage/styles";

import { TStrapiImage } from "@/types/StrapiImage";

import { useSasParams } from "@/utils/Azure";
import { getImageUrl } from "@/utils/Strapi";

export type TSasImage = {
  fallbackText?: string;
  image: TStrapiImage;
  options: {
    size?: "large" | "medium" | "small" | "thumbnail" | null;
    aspectRatio?: number;
    localImage?: boolean;
  };
};

/*
 * Responsive image that automatically attaches sas parameters to load image from secure Azure bucket
 * */
const SasImage = ({
  fallbackText = "",
  image,
  options = {},
  ...others
}: TSasImage) => {
  const { size = null, aspectRatio = null, localImage = false } = options;

  //@ts-expect-error
  const imageUrl: string = localImage ? image : getImageUrl(image, size);

  const { urlWithParams: bgImageUrlWithParams } = useSasParams(imageUrl);

  const hasImage = Boolean(imageUrl || bgImageUrlWithParams);

  return (
    <StyledSasImage
      aspectRatio={aspectRatio || image.width / image.height}
      hasImage={hasImage}
      data-fallback={fallbackText}
      {...others}
    >
      {imageUrl && <Img src={bgImageUrlWithParams} alt={image.caption} />}
    </StyledSasImage>
  );
};

export default SasImage;
