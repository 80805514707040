import React from "react";

import ModuleInfoDescription from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfoDescription";
import ModuleInfoHero from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfoHero";
import ModuleInfoNotification from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfoNotification";
import ModuleInfoUserList from "@/components/Dom/OverviewPanel/PanelContent/ModuleInfoUserList";

import navService, { EOverviewPanel } from "@/services/NavService";

import { StyledModuleInfo } from "./styles";

const ModuleInfo = () => {
  const { module } = navService((state) => ({
    module: state.overviewPanels.get(EOverviewPanel.SPACES),
  }));

  if (!module) return null;

  return (
    <StyledModuleInfo>
      <ModuleInfoHero module={module} />

      <ModuleInfoDescription module={module} />
      <ModuleInfoNotification />
      <ModuleInfoUserList module={module} />
    </StyledModuleInfo>
  );
};

export default ModuleInfo;
