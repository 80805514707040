import Link from "next/link";
import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

const AsideVisibleStyle = css`
  transform: translate3d(0, 0, 0);
`;

const AsideHiddenStyle = css`
  transform: translate3d(calc(-108% - var(--mainNav-margin)), 0, 0);
`;

export const StyledMainNav = styled.aside`
  --background-opacity: 0.6;
  --border-radius: var(--mainNav-border-radius);
  --floating-profile-size: 6rem;
  --vertical-gap: 2rem;
  --main-nav-size: calc(
    100% - var(--floating-profile-size) - var(--vertical-gap)
  );

  &[data-overview-open="true"] {
    --background-opacity: 1;
    --border-radius: var(--mainNav-border-radius) 0 0
      var(--mainNav-border-radius);
  }

  &[data-active-section="true"] {
    --background-opacity: 1;
  }

  align-items: center;
  bottom: var(--mainNav-margin);
  display: grid;
  gap: var(--vertical-gap) 0;
  grid-template-columns: var(--mainNav-width) auto;
  grid-template-rows: auto var(--floating-profile-size);
  grid-template-areas:
    "mainNav overview"
    "profile profile";
  left: var(--mainNav-margin);
  position: fixed;
  top: var(--mainNav-margin);
  z-index: 10;

  transition: transform 0.6s ${ease.inOutCubic};

  ${(props) => (props.isVisible ? AsideVisibleStyle : AsideHiddenStyle)}
`;

export const StyledNavSections = styled.nav`
  --max-height: calc(
    100% - var(--floating-profile-outer) - var(--vertical-gap)
  );
  align-items: start;
  align-self: flex-start;
  border-radius: var(--border-radius);
  display: flex;
  grid-area: mainNav;
  flex-direction: column;
  justify-content: flex-start;
  // 100% - 1rem avoids the main nav to have same size as overview panel
  // so that border-radius don't clash
  max-height: max(calc(100% - 1rem), var(--max-height));
  width: var(--mainNav-width);
  position: relative;

  &:before {
    content: "";
    background-color: rgba(${colors.white.rgba}, var(--background-opacity));
    border-radius: var(--border-radius);
    box-sizing: border-box;
    grid-area: mainNav;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 0.05s ${ease.inOutCubic}, border-radius 0.1s;
    width: var(--mainNav-width);
    z-index: 0;
  }

  ${Hover(`
    --background-opacity: 1;
  `)}
`;

export const StyledLogoWrapper = styled(Link)`
  cursor: pointer;
  display: block;
  padding: 3rem 0 2rem;
  text-align: center;
  width: var(--mainNav-width);
  z-index: 2;
  fill: ${colors.black.hex};

  ${Hover(css`
    fill: ${colors.tangerine.normal.hex};
  `)}

  svg {
    fill: inherit;
    width: var(--mainNav-logo);
    transition: fill 0.2s linear;
  }
`;

export const StyledSections = styled.div`
  display: flex;
  flex-direction: column;
`;
