import styled, { css } from "styled-components";

import { TPopupPosition } from "@/components/Dom/Popover/types";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

const getPosition = (position: TPopupPosition) => {
  const vertical = position.includes("top") ? "bottom: 50%;" : "top: 50%;";
  const horizontal = position.includes("left") ? "right: 50%;" : "left: 50%;";

  return css`
    ${vertical};
    ${horizontal};
  `;
};

export const popoverStyles = css`
  position: absolute;
  border-radius: 0.5rem;
  padding: 1.9rem;
  z-index: 2;
  background-color: ${colors.white.default};
  border: 0.5rem solid rgba(243, 243, 243, 0.6);
  color: ${colors.black.hex};
`;

export const StyledPopover = styled.ul`
  width: 24rem;

  ${popoverStyles};

  ${(props) => getPosition(props.position)}
  ${(props) =>
    !props.isVisible &&
    css`
      visibility: hidden;
      pointer-events: none;
    `}
`;

export const StyledPopoverTitle = styled(Text)`
  color: ${colors.orange.hex};
  transition: color 0.2s linear;
`;

export const StyledPopoverButton = styled.button`
  width: 100%;
  grid-template-columns: 1.5rem auto;
  text-align: left;
  column-gap: 1.7rem;
  display: flex;
  align-items: center;
  color: ${colors.grey.dark.hex};

  &:hover {
    ${StyledPopoverTitle} {
      color: ${colors.grey.dark.hex};
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: ${colors.grey.lighter.hex};
    ${StyledPopoverTitle} {
      color: ${colors.grey.lighter.hex};
    }
  }
`;

export const StyledPopoverLi = styled.li`
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledPopoverIcon = styled.div`
  width: 1.5rem;
`;
