import { FC } from "react";
import { useSpring, animated, config } from "react-spring";

import {
  ProgressCircleWrapper,
  StarWrapper,
  StyledProgressButton,
} from "./styles";

import Star from "~/public/assets/icons/Star.svg";

interface IProgressButton {
  taskCount: number;
  completedTasks: number;
  onMouseEnter: () => void;
}

const ProgressButton: FC<IProgressButton> = ({
  taskCount = 0,
  completedTasks = 0,
  onMouseEnter,
}) => {
  const spring = useSpring({
    stroke: `${(completedTasks / taskCount) * 100}, 100`,
    config: config.gentle,
  });

  return (
    <StyledProgressButton onMouseEnter={onMouseEnter}>
      <ProgressCircleWrapper>
        <svg
          viewBox="0 0 36 36"
          fill="none"
          stroke="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <animated.path
            strokeWidth="1.5"
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
            strokeDasharray={spring.stroke}
          />
        </svg>
      </ProgressCircleWrapper>

      <StarWrapper>
        <Star />
      </StarWrapper>
    </StyledProgressButton>
  );
};

export default ProgressButton;
