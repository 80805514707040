import { FC, useState } from "react";

import { StyledAvatarReactionsBigButton } from "./styles";

import Smiley from "~/public/assets/icons/Smiley.svg";

interface IAvatarReactionsBigButton {
  onClick: () => void;
}

const AvatarReactionsBigButton: FC<IAvatarReactionsBigButton> = ({
  onClick,
}) => {
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [rotateZ, setRotateZ] = useState(0);
  const [rotateX, setRotateX] = useState(0);
  const [scale, setScale] = useState(1);

  const onMouseMove = (e) => {
    const x = e.nativeEvent.layerX - 25;
    const y = e.nativeEvent.layerY - 25;

    setX(x * 0.7);
    setY(y * 0.3);
    setRotateZ(x * 0.8);
    setRotateX(y * 0.4);
  };

  const onMouseLeave = () => {
    setX(0);
    setY(0);
    setRotateZ(0);
    setRotateX(0);
    setScale(1);
  };

  const onMouseEnter = () => {
    setScale(0.9);
  };

  return (
    <StyledAvatarReactionsBigButton
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      large
      icon={<Smiley />}
      onClick={onClick}
      style={{
        "--translateX": `calc(-50% + ${x * 0.2}px)`,
        "--translateY": `calc(-50% + ${y * 0.2}px)`,
        "--rotateZ": `${rotateZ}deg`,
        "--rotateX": `${rotateX}deg`,
        "--scale": scale,
      }}
    />
  );
};

export default AvatarReactionsBigButton;
