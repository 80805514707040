import { FC, useMemo, useState } from "react";

import DiscoverProgress from "@/components/Dom/DiscoverQuest/DiscoverProgress";
import ProgressButton from "@/components/Dom/DiscoverQuest/ProgressButton";

import discoverQuestService from "@/services/DiscoverQuestService";
import mediaOverlayService from "@/services/MediaOverlayService";
import { playerService } from "@/services/PlayerService";

import { StyledCollectGame } from "./styles";

interface ICollectGame {}

const DiscoverQuest: FC<ICollectGame> = () => {
  const { showUi, currentQuest } = discoverQuestService((state) => ({
    showUi: state.showUi,
    currentQuest: state.currentQuestId
      ? state.quests?.get(state.currentQuestId)
      : null,
  }));
  const { currentQuestState } = playerService((state) => ({
    currentQuestState: currentQuest?.id
      ? state.ownPlayerData?.questState.get(currentQuest.id)
      : null,
  }));
  const isMediaOverlayOpen = mediaOverlayService((state) => state.isOpen);

  const [isHover, setIsHover] = useState(false);
  const isExpanded = showUi || isHover;

  const taskState = currentQuestState
    ? Array.from(currentQuestState.tasks.values())
    : [];

  const completedTasks = useMemo(
    () =>
      taskState.reduce(
        (prev, current) => (current.completed ? prev + 1 : prev),
        0
      ),
    [taskState]
  );

  const onMouseEnter = () => {
    setIsHover(true);
  };
  const onMouseLeave = () => {
    setIsHover(false);
  };

  if (!currentQuest || !currentQuestState) return null;

  return (
    <StyledCollectGame
      isVisible={!isMediaOverlayOpen && !showUi}
      isExpanded={isExpanded}
      onMouseLeave={onMouseLeave}
    >
      <ProgressButton
        taskCount={currentQuest.tasks.size}
        completedTasks={completedTasks}
        onMouseEnter={onMouseEnter}
      />

      <DiscoverProgress
        headline={currentQuest.name}
        taskData={currentQuest.tasks}
        taskState={currentQuestState.tasks}
        completedTasks={completedTasks}
        wrapperExpanded={isExpanded}
        onMouseEnter={onMouseEnter}
      />
    </StyledCollectGame>
  );
};

export default DiscoverQuest;
