import { useMemo } from "react";

import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import notificationService from "@/services/NotificationService";
import sceneService from "@/services/SceneService";
import videoService from "@/services/VideoService";
import Camera from "~/public/assets/icons/Camera.svg";

import {
  CommunicationAreaCtaWrapper,
  CommunicationAreaCtaTitle,
  CommunicationAreaCtaDescription,
  CommunicationAreaCtaButton,
  CommunicationAreaCtaButtonRounded,
} from "./styles";

export default function CommunicationAreaCta() {
  const state = sceneService((state) => state.communicationAreaCta);
  const notifications = notificationService((state) => state.notifications);
  const currentModule: TModule | null = moduleService
    .getState()
    .getCurrentModule();
  const onClick = (e) => {
    e.stopPropagation();

    if (!state.status) return;
    if (!currentModule) return;

    const twilioRoomName = `${currentModule.id}_${state.name}`;
    videoService.getState().joinStaticRoom(twilioRoomName);

    sceneService.getState().setTriggerAreaState(state.name, {
      isVisible: false,
      isActive: false,
    });
  };

  const getNotificationState = useMemo(() => {
    if (notifications.length) return true;
    return false;
  }, [notifications]);

  if (!currentModule) return;
  return (
    <CommunicationAreaCtaWrapper
      isVisible={state?.status || false}
      isPushedDown={getNotificationState || false}
    >
      <CommunicationAreaCtaTitle>video call zone</CommunicationAreaCtaTitle>
      <CommunicationAreaCtaDescription>
        You have entered a call zone. <br /> If you like to join, click below.
      </CommunicationAreaCtaDescription>

      <CommunicationAreaCtaButton onClick={onClick}>
        <CommunicationAreaCtaButtonRounded>
          <Camera />
        </CommunicationAreaCtaButtonRounded>
        join call
      </CommunicationAreaCtaButton>
    </CommunicationAreaCtaWrapper>
  );
}
