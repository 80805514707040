import { useMemo } from "react";

import videoService from "@/services/VideoService";

import { colors } from "@/styles/colors";

import {
  StyledParticipantsButton,
  StyledParticipantsButtonSize,
} from "./styles";

import ArrowLeftThick from "~/public/assets/icons/ArrowLeftThick.svg";
import ArrowRight from "~/public/assets/icons/ArrowRight.svg";

const ParticipantsButton = () => {
  const isActive = videoService((state) => state.showParticipantsList);
  const currentRoomActiveParticipants = videoService(
    (state) => state.currentRoomActiveParticipants
  );
  const onClick = () => videoService.getState().toggleParticipantsList();

  const arrow = useMemo(() => {
    return isActive ? (
      <>
        <ArrowRight />
        <StyledParticipantsButtonSize isActive={true}>
          {currentRoomActiveParticipants}
        </StyledParticipantsButtonSize>
      </>
    ) : (
      <>
        <ArrowLeftThick />
        <StyledParticipantsButtonSize isActive={false}>
          {currentRoomActiveParticipants}
        </StyledParticipantsButtonSize>
      </>
    );
  }, [isActive, currentRoomActiveParticipants]);

  return (
    <StyledParticipantsButton
      disabled={false}
      icon={arrow}
      iconWidth={18.6}
      iconHeight={13.3}
      iconColor={isActive ? colors.white.default : colors.grey.normal.hex}
      backgroundColor={
        isActive ? colors.tangerine.normal.hex : colors.grey.lightest.hex
      }
      big={true}
      onClick={onClick}
    ></StyledParticipantsButton>
  );
};

export default ParticipantsButton;
