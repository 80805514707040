import { animated } from "@react-spring/web";
import styled, { css, keyframes } from "styled-components";

import Tile from "@/components/Dom/WorldMap/Tile";

import { ease } from "@/styles/ease";

export const StyledWorldMap = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  touch-action: none;
  ${(props) =>
    props.canDrag &&
    css`
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    `}

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    `}
`;

export const ScaleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s ${ease.outQuint};
  transform: scale(var(--scale));
  min-height: 90rem;
  min-width: 100vw;
  width: fit-content;
  height: fit-content;
  padding: calc(var(--size) * 0.8) calc(var(--size) * 1.5);
  background-image: url("/assets/images/cloudsBaselayer.jpg");
  background-repeat: repeat;
  background-size: 1024px 1024px;

  @media screen and (min-height: 900px) {
    min-height: 100vh;
  }
`;

export const MapWrapper = styled(animated.div)`
  position: absolute;
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  width: fit-content;
  height: fit-content;
`;

export const getRows = (tileCount: number) => Math.floor(Math.sqrt(tileCount));
export const getColumns = (tileCount: number) =>
  Math.ceil(tileCount / getRows(tileCount));

export const TilesWrapper = styled.div`
  display: grid;

  gap: var(--gap);
  grid-template-rows: repeat(
    ${({ tileCount }) => getRows(tileCount)},
    calc(var(--size) * var(--tileScale))
  );
  grid-template-columns: repeat(
    ${({ tileCount }) => getColumns(tileCount)},
    calc(var(--size) * var(--tileScale))
  );

  width: fit-content;
  height: fit-content;
  align-self: center;
  justify-self: center;
`;

const cloudsAnimation = keyframes`
    100% {transform: translate(50%, -50%);}
`;

export const CloudsLayer = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  width: var(--width);
  height: var(--height);
  background-image: url("/assets/images/cloudsOverlay.png");
  z-index: 5;
  animation: ${cloudsAnimation} 50s infinite;
  animation-timing-function: linear;
`;

export const TopLeft = styled(Tile)`
  position: absolute;
  top: 0;
  left: 0;
`;

export const TopRight = styled(Tile)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const BottomLeft = styled(Tile)`
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const BottomRight = styled(Tile)`
  position: absolute;
  bottom: 0;
  right: 0;
`;
