import styled from "styled-components";

import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";
import PanelHeader from "@/components/Dom/OverviewPanel/PanelHeader";

import { colors } from "@/styles/colors";

export const Header = styled(PanelHeader)`
  color: ${colors.grey.lighter.hex};
  height: 7.3rem;
  padding: var(--mainNav-overview-panel-padding)
    calc(var(--mainNav-overview-panel-padding) - 0.4rem);
`;

export const StyledAvatarPanel = styled(FlexAndGrow)`
  --avatar-options-width: 4.1rem;
  width: var(--mainNav-panel-width);
`;
