import styled, { css } from "styled-components";

import { ease } from "@/styles/ease";

const slideTransition = css`
  transition: transform 0.75s ${ease.inOutQuint};
`;

export const StyledLoadingScreenSlide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.backgroundColor};
  overflow: hidden;

  ${({ viewState }) => {
    switch (viewState) {
      case "fadeIn":
        return css`
          ${slideTransition};
          transform: translateY(0);
        `;
      case "fadeOut":
        return css`
          ${slideTransition};
          transform: translateY(-100%);
        `;
      default:
        return css`
          transform: translateY(100%);
        `;
    }
  }};
`;

export const ContentWrapper = styled.div`
  position: relative;
  inset: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;

  ${({ viewState }) => {
    switch (viewState) {
      case "fadeIn":
        return css`
          ${slideTransition};
          transform: translateY(0);
        `;
      case "fadeOut":
        return css`
          ${slideTransition};
          transform: translateY(100%);
        `;
      default:
        return css`
          transform: translateY(-100%);
        `;
    }
  }};
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 6rem;
  left: 6rem;
  width: 8.2rem;
  height: 6.3rem;
  color: ${(props) => props.textColor};
`;
