import { HTMLAttributes, FC } from "react";

import { StyledTabSection } from "./styles";

type TTabsSection = HTMLAttributes<HTMLDivElement>;

export type TTabSectionData = {
  icon: any;
};

interface ITabSectionProps {
  icon: any;
  selected: boolean;
  handleClick: Function;
  index: number;
}

const TabSection: FC<ITabSectionProps & TTabsSection> = ({
  icon,
  selected,
  handleClick,
  index,
}) => {
  return (
    <StyledTabSection
      selected={selected}
      onClick={() => {
        handleClick(index);
      }}
    >
      {icon}
    </StyledTabSection>
  );
};

export default TabSection;
