import React, { useEffect, useState } from "react";

import AddToCallPanel from "./Panels/AddToCallPanel";

import ChatPanel from "@/components/Dom/OverviewPanel/Panels/ChatPanel";
import NewChatPanel from "@/components/Dom/OverviewPanel/Panels/NewChatPanel";
import ProfileAvatarPanel from "@/components/Dom/OverviewPanel/Panels/ProfileAvatarPanel";
import SpacePanel from "@/components/Dom/OverviewPanel/Panels/SpacePanel";

import inputService from "@/services/InputService";
import navService, { EOverviewPanel } from "@/services/NavService";

import { StyledOverviewPanel } from "./styles";

type TOverviewPanel = React.HTMLAttributes<HTMLDivElement>;

interface IProps {}

const OverviewPanel: React.FC<IProps & TOverviewPanel> = ({ ...others }) => {
  const [panel, setPanel] = useState<JSX.Element | null>(null);

  const currentOverviewPanel = navService(
    (state) => state.currentOverviewPanel
  );

  useEffect(() => {
    switch (currentOverviewPanel) {
      case EOverviewPanel.SPACES:
        setPanel(<SpacePanel />);
        break;
      case EOverviewPanel.CHATS:
        setPanel(<ChatPanel />);
        break;
      case EOverviewPanel.NEW_CHAT:
        setPanel(<NewChatPanel />);
        break;
      case EOverviewPanel.PROFILE:
        setPanel(<ProfileAvatarPanel />);
        break;
      case EOverviewPanel.ADD_TO_CALL:
        setPanel(<AddToCallPanel />);
        break;
      default:
        setPanel(null);
        break;
    }
  }, [currentOverviewPanel]);

  useEffect(() => {
    return () => {
      inputService.getState().enableInput();
    };
  }, []);

  return (
    <StyledOverviewPanel data-overview-open={panel !== null} {...others}>
      {panel}
    </StyledOverviewPanel>
  );
};

export default OverviewPanel;
