import AnimatedNotification from "@/components/Dom/Notifications/NotificationCenter/AnimatedNotification";

import notificationService, {
  TNotification,
} from "@/services/NotificationService";

import { StyledNotificationCenter } from "./styles";

type TNotificationCenter = {
  position: TNotification["position"];
};

const NotificationCenter = ({ position }: TNotificationCenter) => {
  const notifications = notificationService(
    (state) => state.notifications
  ).filter((notification) => notification.position === position);

  return (
    <StyledNotificationCenter position={position}>
      {notifications.map((notification) => (
        <AnimatedNotification
          key={notification.id}
          position={position}
          isVisible={notification.isVisible}
        >
          {notification.element}
        </AnimatedNotification>
      ))}
    </StyledNotificationCenter>
  );
};

export default NotificationCenter;
