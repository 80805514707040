import { FC } from "react";

import AvatarImage from "@/components/Dom/Common/AvatarImage";
import OnboardingVideoWindowControls from "@/components/Dom/Onboarding/OnboardingVideoWindowControls";
import { TilesWrapper } from "@/components/Dom/VideoChat/VideoWindow/styles";
import {
  TextWrapper,
  VideoPlaceholder,
} from "@/components/Dom/VideoChat/VideoWindow/VideoWindowParticipant/styles";

import onboardingService from "@/services/OnboardingService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { TVideoGrid } from "@/services/VideoService/types";

import { Text } from "@/styles/typography";

import { OnboardingFakeVideoWindow, FakeTile } from "./styles";

interface IOnboardingVideoWindow {
  user: TUser;
}

const OnboardingVideoWindow: FC<IOnboardingVideoWindow> = ({ user }) => {
  const ownUser = userService((state) => state.ownUser);
  const showCallWindow = onboardingService((state) => state.showCallWindow);

  const tileSize = { width: 32, height: 18.2 };

  if (!ownUser) return null;

  return (
    <OnboardingFakeVideoWindow
      isVisible={showCallWindow}
      gradients={false}
      body={
        <>
          <TilesWrapper
            videoGrid={TVideoGrid.SMALL}
            tilesNeeded={1}
            style={{
              "--videoWidth": `${tileSize.width}rem`,
              "--videoHeight": `${tileSize.height}rem`,
            }}
          >
            <FakeTile>
              <VideoPlaceholder>
                <AvatarImage users={user} />
              </VideoPlaceholder>

              <TextWrapper>
                <Text bold>{userService.getState().buildUserName(user)}</Text>
              </TextWrapper>
            </FakeTile>

            <FakeTile>
              <VideoPlaceholder>
                <AvatarImage users={ownUser} />
              </VideoPlaceholder>
            </FakeTile>
          </TilesWrapper>

          <OnboardingVideoWindowControls />
        </>
      }
    />
  );
};

export default OnboardingVideoWindow;
