import { FC } from "react";

import Tabs from "@/components/Dom/Common/Tabs";
import ChatIcon from "@/components/Dom/Common/Tabs/Icons/Chat.svg";
import InfoIcon from "@/components/Dom/Common/Tabs/Icons/Info.svg";
import { TTabSectionData } from "@/components/Dom/Common/Tabs/TabSection";
import OnboardingCallButton from "@/components/Dom/Onboarding/OnboardingCallButton";
import ChatPanelUserProfile from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelUserProfile";
import PanelHeader from "@/components/Dom/OverviewPanel/PanelHeader";

import onboardingService from "@/services/OnboardingService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

import { StyledOnboardingProfile } from "./styles";

const tabSections: Array<TTabSectionData> = [
  { icon: <InfoIcon /> },
  { icon: <ChatIcon /> },
];

interface IOnboardingProfile {
  user: TUser;
}

const OnboardingProfile: FC<IOnboardingProfile> = ({ user }) => {
  const isVisible = onboardingService((state) => state.showProfile);

  return (
    <StyledOnboardingProfile
      gradients={false}
      isVisible={isVisible}
      header={
        <PanelHeader title={userService.getState().buildUserName(user)}>
          <OnboardingCallButton />
          <Tabs
            tabSectionIndex={0}
            onClick={() => { }}
            tabSections={tabSections}
          />
        </PanelHeader>
      }
      body={<ChatPanelUserProfile user={user} />}
    />
  );
};

export default OnboardingProfile;
