import styled, { css } from "styled-components";

import { TVideoGrid } from "@/services/VideoService/types";

import { colors } from "@/styles/colors";

export const StyledScreenshareParticipant = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${colors.black.hex};

  // on medium grid make the element always two grid width
  ${(props) =>
    props.videoGrid === TVideoGrid.MEDIUM &&
    css`
      width: var(--videoWidth);
      height: var(--videoHeight);
    `}
  // on large grid set height
  ${(props) =>
    props.videoGrid === TVideoGrid.LARGE &&
    css`
      height: var(--videoHeight);
    `}

  ${(props) =>
    props.videoGrid === TVideoGrid.SMALL &&
    css`
      grid-column: 1 / 2;
      grid-row: 1/2;
    `}

  ${(props) =>
    !props.isVisible &&
    css`
      position: absolute;
      visibility: hidden;
    `}
`;

export const Video = styled.video`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
