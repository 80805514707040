import styled from "styled-components";

import { colors } from "@/styles/colors";
import { Text, SmallHeadlineStyles } from "@/styles/typography";

export const StyledVideoGridControl = styled.div`
  padding: 4.5rem 4rem 5rem;
  border-radius: 1rem;
  background-color: ${colors.white.default};
  overflow: visible;
  max-width: 66rem;
  min-width: 31rem;

  ${Text} {
    margin-bottom: 1rem;
    color: ${colors.grey.light.hex};
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const Icon = styled.div`
  width: 9.4rem;
  margin-right: 1.5rem;
  color: ${colors.grey.light.hex};
`;

export const ItemContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const RadioButtonCircle = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: ${colors.white.default};
  border: 0.1rem solid ${colors.grey.normal.hex};
`;

export const Label = styled.label`
  ${SmallHeadlineStyles}
`;

export const Item = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 0.5rem;
  padding: 0.3rem 0.3rem 0 0.3rem;
  &:hover {
    background-color: ${colors.grey.lightest.hex};
    ${Label} {
      color: ${colors.tangerine.normal.hex};
    }
  }
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  margin-right: 0.9rem;
  &:hover + ${RadioButtonCircle} {
    background-color: ${colors.white.default};
    border: 0.1rem solid ${colors.tangerine.normal.hex};
  }
  &:checked + ${RadioButtonCircle} {
    border: 0.1rem solid ${colors.tangerine.normal.hex};
    &::after {
      content: "";
      display: block;
      background-color: ${colors.tangerine.normal.hex};
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 1.2rem;
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }
  &:disabled {
    + ${RadioButtonCircle} {
      cursor: not-allowed;
      background-color: ${colors.white.default};
      border-color: ${colors.grey.veryLight.hex};
    }
    ~ ${Label} {
      cursor: not-allowed;
      color: ${colors.grey.veryLight.hex};
    }
  }
`;
