import styled, { css } from "styled-components";

import { StyledNewMessageCount } from "../../MainNavSection/MainNavSectionCTA/styles";

import { colors } from "@/styles/colors";
import {
  MainNavItemAvatar,
  MainNavSubtitle,
  MainNavTitle,
} from "@/styles/common";
import { Hover, MainNavSectionGrid } from "@/styles/mixins";

export const StyledNavItem = styled.button`
  ${MainNavSectionGrid};
  --avatar-size: var(--mainNav-avatar-size);
  --background-padding: 0.6rem;
  --background-size: calc(
    var(--mainNav-avatar-size) + 2 * var(--background-padding)
  );
  --background-left: calc(
    var(--mainNav-left-spacing) - var(--background-padding)
  );

  cursor: default;
  grid-template-areas:
    "avatar content"
    "avatar content";
  grid-template-rows: calc((var(--mainNav-avatar-size) / 2) + 0.2rem) calc(
      (var(--mainNav-avatar-size) / 2) + 0.2rem
    );
  justify-items: start;
  position: relative;

  &:before {
    content: "";
    border-radius: var(--background-size);
    height: var(--background-size);
    margin-left: var(--background-left);
    position: absolute;
    top: 50%;
    opacity: 0;
    transform: translate(0, -50%);
    width: calc(100% - 2 * var(--background-left));
  }

  > * {
    z-index: 1;
  }

  ${Hover(css`
    color: ${colors.tangerine.normal.hex};
  `)}

  &[data-overview-open="true"] {
    &:before {
      background: ${colors.grey.lightest.hex};
    }
  }

  &[data-hovered="true"] {
    cursor: pointer;
    &:before {
      opacity: 1;
      z-index: 1;
    }
  }

  &[data-overview-open="false"][data-hovered="true"] {
    ${MainNavTitle} {
      color: ${colors.tangerine.normal.hex};
    }
  }

  &[data-overview-open="true"][data-hovered="false"] {
    ${MainNavTitle} {
      opacity: 0;
    }
    ${MainNavSubtitle} {
      opacity: 0;
    }
  }
`;

export const StyledAvatar = styled(MainNavItemAvatar)`
  position: relative;

  ${StyledNewMessageCount} {
    bottom: 0;
    left: 100%;
    transform: translateX(-50%);
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  grid-area: content;
  width: 100%;
`;
