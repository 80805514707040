import styled from "styled-components";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const StyledVideoSettingsControl = styled.div`
  padding: 4.5rem 4rem 5rem;
  border-radius: 1rem;
  background-color: ${colors.white.default};
  overflow: visible;
  max-width: 30rem;
  min-width: 20rem;

  ${Text} {
    margin-bottom: 1rem;
    color: ${colors.grey.light.hex};
  }
`;
export const StyledSelectLabel = styled.label`
  color: ${colors.black.hex};
  font-size: 1.5rem;
  font-weight: 700;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledIcon = styled.div`
  width: 1.1rem;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  color: ${colors.black.hex};
`;

export const StyledSelectContainer = styled.div`
  margin-bottom: 3.5rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 6rem;
`;
