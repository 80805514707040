import styled from "styled-components";

import { MidHeadline } from "@/styles/typography";

export const StyledModuleInfoDescription = styled.section``;

export const Title = styled(MidHeadline)`
  display: block;
  margin-bottom: 0.5rem;
`;
