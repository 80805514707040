import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { SmallHeadlineStyles } from "@/styles/typography";

export const StyledSasImage = styled.div`
  background-color: ${colors.grey.lightest.hex};
  overflow: hidden;
  padding-top: ${(props) => props.aspectRatio * 100}%;
  position: relative;
  width: 100%;

  ${(props) =>
    !props.hasImage &&
    css`
      &:after {
        content: attr(data-fallback);
        color: ${colors.grey.normal.hex};
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        ${SmallHeadlineStyles};
      }
    `}
`;

export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
