import { FC } from "react";

import { TReaction } from "@/components/Scene/Avatar/ReactionController";

import { playerService } from "@/services/PlayerService";

import { StyledAvatarReactionButton } from "./styles";

type TAvatarReactionButton = {
  icon: any;
  reaction: TReaction;
  isOpen: boolean;
  index: number;
};

const AvatarReactionButton: FC<TAvatarReactionButton> = ({
  icon,
  reaction,
  isOpen,
  index,
}) => {
  const onClick = () => {
    playerService.getState().reactionController?.start(reaction);
  };

  return (
    <StyledAvatarReactionButton
      big
      icon={icon}
      onClick={onClick}
      isOpen={isOpen}
      style={{
        "--index": index,
      }}
    />
  );
};

export default AvatarReactionButton;
