import React, { FC, useEffect, useState } from "react";
import { RemoteParticipant, LocalParticipant } from "twilio-video";

import ParticipantsInfoUser from "./ParticipantsInfoUser";

import User from "@/components/Dom/Common/User";

import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import videoService from "@/services/VideoService";

import { BigHeadline } from "@/styles/typography";

import {
  StyledParticipantsListUl,
  StyledParticipantsInfo,
  ParticipantsListLi,
} from "./styles";

interface Props {
  participants: (LocalParticipant | RemoteParticipant)[];
  reconnectingParticipants: RemoteParticipant[];
}

const ParticipantsInfo: FC<Props> = ({
  participants,
  reconnectingParticipants,
}) => {
  const localParticipantHasRaisedHand = videoService(
    (state) => state.localParticipant.hasRaisedHand
  );
  const user: TUser | null = userService.getState().ownUser;
  const [usersRaised, setUsersRaised] = useState<TUser[]>([]);
  const handleUserRaised = (user: TUser) => {
    const isUserPresent = usersRaised.find((users) => users.id === user.id);
    if (isUserPresent) {
      setUsersRaised((prev) => prev.filter((item) => item.id !== user.id));
    } else {
      setUsersRaised((prev) => [...prev, user]);
    }
  };

  useEffect(() => {
    if (user) {
      if (localParticipantHasRaisedHand) {
        setUsersRaised((prev) => [...prev, user]);
      } else {
        setUsersRaised((prev) => prev.filter((item) => item.id !== user.id));
      }
    }
  }, [localParticipantHasRaisedHand]);

  useEffect(() => {
    // checks if hand raised user is still in participants
    usersRaised.map((user) => {
      const found = participants.some(
        (el) => parseInt(el.identity, 10) === user.id
      );
      // hand raised user is not in participants, remove user from usersRaised
      if (!found)
        setUsersRaised((prev) => prev.filter((item) => item.id !== user.id));
    });
  }, [participants, usersRaised]);

  return (
    <StyledParticipantsInfo>
      {usersRaised.length > 0 && (
        <>
          <BigHeadline bold>
            {usersRaised.length}{" "}
            {usersRaised.length === 1 ? "person" : "people"} raised their hand:
          </BigHeadline>

          <StyledParticipantsListUl>
            {usersRaised.map((user) => (
              <ParticipantsListLi
                key={user.id}
                isReconnecting={Boolean(
                  reconnectingParticipants.find(
                    (p) => p.identity == `${user.id}`
                  )
                )}
              >
                <User user={user} />
              </ParticipantsListLi>
            ))}
          </StyledParticipantsListUl>
        </>
      )}

      <BigHeadline bold>
        {participants.length} {participants.length === 1 ? "person" : "people"}{" "}
        in this call:
      </BigHeadline>

      <StyledParticipantsListUl>
        {participants.map((participant) => (
          <ParticipantsListLi
            key={participant.sid}
            isReconnecting={Boolean(
              reconnectingParticipants.find(
                (p) => p.identity == participant.identity
              )
            )}
          >
            <ParticipantsInfoUser
              participant={participant}
              onChange={(user: TUser) => handleUserRaised(user)}
            />
          </ParticipantsListLi>
        ))}
      </StyledParticipantsListUl>
    </StyledParticipantsInfo>
  );
};

export default ParticipantsInfo;
