import styled from "styled-components";

import { StyledButtonRound } from "@/components/Dom/Common/ButtonRound/styles";
import { ToggleStyle } from "@/components/Dom/WorldMap/SpaceTile/styles";

import { colors } from "@/styles/colors";

export const StyledBackButton = styled.div`
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  background-color: ${colors.white.default};
  border-radius: 100%;

  ${StyledButtonRound} {
    --svg-size: 0.8rem;
  }

  ${ToggleStyle}
`;
