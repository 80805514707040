import styled from "styled-components";

import ButtonPill from "@/components/Dom/Common/ButtonPill";

import { colors } from "@/styles/colors";

export const StyledPanelCallButton = styled(ButtonPill)`
  color: ${colors.white.default};
  --svg-size: 1.2rem;

  background-color: ${colors.green.dark.hex};

  &:disabled {
    background-color: ${colors.grey.lighter.hex};
  }
`;
