import styled from "styled-components";

import ButtonRound from "@/components/Dom/Common/ButtonRound";

import { colors } from "@/styles/colors";

export const StyledAvatarReactionsBigButton = styled(ButtonRound)`
  background-color: ${colors.grey.normal.hex};
  z-index: 5;

  transform-style: preserve-3d;
  perspective: 500px;

  svg {
    pointer-events: none;
    transition: transform 0.1s linear;
    transform: scale(var(--scale))
      translate3d(var(--translateX), var(--translateY), 100px)
      rotateZ(var(--rotateZ)) rotateX(var(--rotateX));
  }
`;
