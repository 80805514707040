import styled, { css } from "styled-components";

import { StyledAvatarImage } from "@/components/Dom/Common/AvatarImage/styles";
import User from "@/components/Dom/Common/User";
import { StyledUserName } from "@/components/Dom/Common/User/style";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";

export const StyledUser = styled(User)`
  ${StyledUserName} {
    transition: color 0.2s linear;
  }

  &[aria-checked="true"] {
    ${StyledUserName} {
      color: ${colors.tangerine.normal.hex};
    }
  }

  //Plus / Minus icon
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 1.4rem;
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;

    border-radius: 100%;
    visibility: hidden;

    content: "+";
    background-color: ${colors.tangerine.normal.hex};
    color: ${colors.white.default};
  }

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${colors.grey.light.hex};
      pointer-events: none;

      &:after {
        visibility: visible;
      }
    `}

  ${(props) =>
    props.isActive &&
    css`
      &:before {
        opacity: 1;
      }
      &:after {
        content: "×";
        background-color: ${colors.white.default};
        color: ${colors.red.normal.hex};
        visibility: visible;
      }

      ${StyledAvatarImage} {
        visibility: hidden;
      }
    `}
  
  ${Hover(css`
    &:after {
      visibility: visible;
    }

    ${StyledAvatarImage} {
      visibility: hidden;
    }

    ${StyledUserName} {
      color: ${colors.tangerine.normal.hex};
    }
  `)}
`;

export const StyledUserListItem = styled.li``;
