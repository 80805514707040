import styled, { css } from "styled-components";

import ButtonRound from "@/components/Dom/Common/ButtonRound";
import { popoverStyles } from "@/components/Dom/Popover/PopoverLinks/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { TextStyles } from "@/styles/typography";

export const progressIndicatorVisibleStyles = css`
  transform: translateY(0) rotate(0deg) scale(1);
`;

export const StyledProgressIndicator = styled(ButtonRound)`
  position: relative;
  transform: translateY(300%) rotate(45deg) scale(0);
  transition: transform 0.5s ${ease.outCubic} var(--transition-delay),
    background-color 0.2s linear;
  z-index: 0;

  ${(props) =>
    props.popoverVisible &&
    css`
      z-index: 4;
    `}

  ${(props) =>
    props.completed
      ? css`
          background-color: ${colors.orange.hex};
          color: ${colors.white.default};
        `
      : css`
          background-color: ${colors.grey.lightest.hex};
          color: ${colors.grey.lighter.hex};
        `}

  
  ${TextStyles};

  &:before {
    content: attr(data-content);
    bottom: 50%;
    left: 50%;
    width: 24rem;
    ${popoverStyles};

    ${(props) =>
      !props.popoverVisible &&
      css`
        visibility: hidden;
        pointer-events: none;
      `}
  }
`;
