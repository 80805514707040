import { FC, useEffect, useRef } from "react";

import popupOverlayService from "@/services/PopupOverlayService";

import { StyledPopupOverlay } from "./styles";

const PopupOverlay: FC = () => {
  const dialogRef = useRef<HTMLDialogElement>();
  const element = popupOverlayService((state) => state.element);

  useEffect(() => {
    if (dialogRef.current) {
      popupOverlayService.setState({ dialogRef: dialogRef.current });
    }
  }, [dialogRef.current]);

  return (
    <StyledPopupOverlay role="dialog" ref={dialogRef}>
      {element}
    </StyledPopupOverlay>
  );
};

export default PopupOverlay;
