import { UseFormRegister, FieldValues } from "react-hook-form";

import {
  StyledSelect,
  StyledOption,
  IconWrapper,
  OptionsWrapper,
} from "./styles";

import ArrowLeft from "~/public/assets/icons/ArrowLeft.svg";

interface ISelectProps {
  name?: string;
  options: {
    value: any;
    label: any;
  }[];
  placeholder?: string;
  defaultValue?: any;
  register?: UseFormRegister<FieldValues>;
  stringifyValues?: boolean;
  handleChange?: (e: any) => void;
}

const Select = ({
  defaultValue,
  name,
  placeholder,
  options,
  register,
  handleChange,
  stringifyValues = true,
  ...others
}: ISelectProps) => {
  return (
    <StyledSelect {...others}>
      <IconWrapper>
        <ArrowLeft />
      </IconWrapper>

      <OptionsWrapper
        onChange={handleChange}
        {...(register && name && register(name))}
      >
        {placeholder && (
          <StyledOption disabled selected={!defaultValue} value="">
            {placeholder}
          </StyledOption>
        )}
        {options.map(({ value, label }, i) => (
          <StyledOption
            key={i}
            value={stringifyValues ? JSON.stringify(value) : value}
            selected={value === defaultValue}
          >
            {label}
          </StyledOption>
        ))}
      </OptionsWrapper>
    </StyledSelect>
  );
};

export default Select;
