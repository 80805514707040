import React, { useEffect, useState } from "react";

import { chatsSection } from "../panelsSection";

import Tabs from "@/components/Dom/Common/Tabs";
import UserName from "@/components/Dom/Common/UserName";
import PanelHeader from "@/components/Dom/OverviewPanel/PanelHeader";

import chatService from "@/services/ChatService";
import debugService from "@/services/DebugService";
import navService, { EOverviewPanel } from "@/services/NavService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

import { StyledChatPanel } from "./styles";

const ChatPanel = () => {
  const sections = chatsSection;
  const messages = chatService((state) => state.messages);
  const { currentOverviewPanel, chat, tabIndex } = navService((state) => ({
    currentOverviewPanel: state.currentOverviewPanel,
    chat: state.overviewPanels.get(EOverviewPanel.CHATS),
    tabIndex: state.tabIndex,
  }));

  const [users, setUsers] = useState<TUser[]>([]);

  const title = <UserName users={users} bold applyFontStyle={false} />;

  useEffect(() => {
    if (!chat) return;
    const updateUsers = async () => {
      try {
        const users = await userService.getState().getUsersByIds(chat.users);
        if (users) setUsers(users);
      } catch (error) {
        debugService.getState().logError(`ChatPanel::useEffect[]: ${error}`);
        return;
      }
    };
    updateUsers();
  }, [chat?.users]);

  const handleTabClick = (index) => {
    navService.setState({ tabIndex: index });
  };

  return (
    <>
      {currentOverviewPanel === EOverviewPanel.CHATS && (
        <StyledChatPanel
          header={
            // @ts-expect-error typing issue
            <PanelHeader showCallButton title={title}>
              <Tabs
                tabSectionIndex={tabIndex}
                onClick={handleTabClick}
                tabSections={sections.map((section) => {
                  return { icon: section.icon };
                })}
              />
            </PanelHeader>
          }
          body={sections[tabIndex].body}
          footer={sections[tabIndex].footer}
          updateParameters={[messages, tabIndex]}
          scrollToBottomOnChange
        />
      )}
    </>
  );
};

export default ChatPanel;
