import styled, { css } from "styled-components";

import { StyledButtonRound } from "@/components/Dom/Common/ButtonRound/styles";
import {
  StyledAnimatedHeight,
  StyledScrollWrapper,
} from "@/components/Dom/MainNav/CollapseSection/AnimatedHeight/styles";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { MidHeadline } from "@/styles/typography";

export const StyledModuleInfoUserList = styled.section``;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.9rem;
  margin-top: 1.7rem;

  ${StyledAnimatedHeight} {
    margin-left: -0.5rem;
    width: calc(100% + 1rem);
  }

  ${StyledScrollWrapper} {
    overflow: visible;
  }
`;

export const Title = styled(MidHeadline)`
  display: block;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;

  ${Title} {
    transform: translateY(-10%);
    text-transform: uppercase;
    transition: color 0.25s linear;
  }

  ${StyledButtonRound} {
    width: 4rem;
    height: 4rem;
    background-color: ${colors.white.default};
    color: ${colors.black.hex};

    ${(props) =>
      !props.isOpen &&
      css`
        transform: rotate(-180deg);
      `}
  }

  &:before {
    content: "";
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    transition: background-color 0.25s linear;
    background-color: ${colors.white.default};
    border-radius: 4rem;
  }

  ${Hover(css`
    &:before {
      background-color: ${colors.grey.lightest.hex};
    }
    ${Title} {
      color: ${colors.tangerine.normal.hex};
    }
  `)}
`;

export const ListWrapper = styled.ul`
  display: grid;
  gap: 0.8rem;
  padding: 0.4rem 0;
  width: calc(100% - 1rem);
  margin: 0 auto;
`;
