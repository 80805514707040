import create from "zustand";

import { clamp } from "@/utils/Math";

type TRecorderService = {
  isRecording: boolean;
  animationDuration: number;
  animationProgress: number;

  start: () => void;
  stop: () => void;
  tick: () => void;
};

const RecorderService = create<TRecorderService>((set, get) => {
  let startTime = 0;
  let endTime = 0;
  let animationDurationMs = 0;

  if (typeof window !== "undefined") {
    document.body.addEventListener("keyup", (e) => {
      if (e.key === "Escape") get().stop();
    });
  }

  return {
    isRecording: false,
    animationDuration: 10.0,
    animationProgress: 0,

    start: () => {
      animationDurationMs = get().animationDuration * 1000;
      startTime = Date.now();
      endTime = startTime + animationDurationMs;

      set({ isRecording: true, animationProgress: 0 });
    },

    stop: () => {
      if (!get().isRecording) return;
      set({ isRecording: false });
    },

    tick: () => {
      //TODO: add delta compensation
      const progress = 1 - (endTime - Date.now()) / animationDurationMs;
      const animationProgress = clamp(progress, 0, 1);

      set({ animationProgress });
      document.body.style.cursor = "none";
    },
  };
});

export default RecorderService;
