import styled from "styled-components";

import { StyledSpaceList } from "../Sections/SpacesSection/styles";

export const StyledNavItemList = styled.ul<Record<any, any>>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;

  ${StyledSpaceList} & {
    max-height: calc(5 * var(--mainNav-avatar-size) + 2rem);
    padding-top: 0.3rem;
    overflow-y: auto;
  }
`;
