import { useWindowHeight, useWindowWidth } from "@react-hook/window-size";
import sortBy from "lodash/sortBy";
import { useEffect, useMemo, useRef, useState } from "react";
import { RemoteParticipant } from "twilio-video";

import ParticipantsList from "@/components/Dom/VideoChat/VideoWindow/ParticipantsList";
import ScreenShareParticipant from "@/components/Dom/VideoChat/VideoWindow/ShareParticipant";
import VideoWindowControls from "@/components/Dom/VideoChat/VideoWindow/VideoWindowControls";
import VideoWindowParticipant from "@/components/Dom/VideoChat/VideoWindow/VideoWindowParticipant";

import mediaOverlayService from "@/services/MediaOverlayService";
import navService, { EOverviewPanel } from "@/services/NavService";
import videoService from "@/services/VideoService";
import { TVideoGrid } from "@/services/VideoService/types";
import { CSSVariableReturnTypes, getCSSVariable } from "@/utils/Misc";

import {
  StyledParticipantsCamera,
  StyledVideoWindow,
  TilesWrapper,
} from "./styles";

const calcTileSize = (
  windowHeight: number,
  availableScreenWidth: number,
  rows: number,
  videoGrid: TVideoGrid,
  remoteParticipantsCount: number
) => {
  if (videoGrid === TVideoGrid.LARGE) {
    return {
      width: "100%",
      height: "100%",
      tileSizes: remoteParticipantsCount,
    };
  }

  const reference = {
    outer: 6 + 0.4, // ControlsHeight + 2 x mainNavMargin
    availableScreenHeight: 0,
  };

  reference.availableScreenHeight = windowHeight / 10 - reference.outer;

  const tileWidth = availableScreenWidth / rows;
  const tileHeight = tileWidth / (16 / 9);

  // calculate how many tiles can be rendered in the available screen estate
  const tileSizes =
    Math.floor(reference.availableScreenHeight / tileHeight) * rows - 1; // -1 because the current user is not counted in tilesPreRow

  return { width: tileWidth, height: tileHeight, tileSizes };
};

/**
 * Calculates the available screen space in which the videos can be rendered
 */
const calculateAvailableScreenSpace = (
  videoGrid: TVideoGrid,
  windowWidth: number,
  currentOverviewPanel: EOverviewPanel | null,
  participantsListState: boolean
): number => {
  const mainNavMagin = getCSSVariable(
    "--mainNav-margin",
    CSSVariableReturnTypes.Numeral
  ) as number;


  let mainNavPanelWidth = getCSSVariable(
    "--mainNav-panel-width",
    CSSVariableReturnTypes.Numeral
  ) as number;

  let participantsListWidth = getCSSVariable(
    "--video-participants-list-width",
    CSSVariableReturnTypes.Numeral
  ) as number;

  if (currentOverviewPanel === null) mainNavPanelWidth = 0;
  if (!participantsListState) participantsListWidth = 0;

  const width =
    windowWidth / 10 -
    (mainNavMagin * 3 + participantsListWidth + mainNavPanelWidth);

  switch (videoGrid) {
    case TVideoGrid.SMALL:
      return 34;
    case TVideoGrid.MEDIUM:
      return 60;
    case TVideoGrid.LARGE:
      return width;
    default:
      return 34;
  }
};

const VideoWindow = () => {
  const {
    isShareActive,
    isSpaceShareActive,
    isFullscreen,
    currentRoom,
    callState,
    videoGrid,
    showParticipantsList,
    dominantSpeaker,
  } = videoService((state) => ({
    isShareActive: state.isShareActive,
    isSpaceShareActive: state.isSpaceShareActive,
    isFullscreen: state.isFullscreen,
    currentRoom: state.currentRoom,
    callState: state.callState,
    videoGrid: state.videoGrid,
    showParticipantsList: state.showParticipantsList,
    dominantSpeaker: state.dominantSpeaker,
  }));

  const { currentOverviewPanel } = navService((state) => ({
    currentOverviewPanel: state.currentOverviewPanel,
  }));

  const isMediaOverlayOpen = mediaOverlayService((state) => state.isOpen);
  const mediaAreaData = mediaOverlayService((state) => state.mediaAreaData);

  const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
  const [reconnectingParticipants, setReconnectingParticipants] = useState<
    RemoteParticipant[]
  >([]);
  const [tileSize, setTileSize] = useState({ width: 280, height: 160 });
  const [maxRemoteParticipants, setMaxRemoteParticipants] = useState<number>(3);
  const [renderTiles, setRenderTiles] = useState<boolean>(true);

  const windowHeight = useWindowHeight();
  const windowWidth = useWindowWidth();

  const tilesWrapperRef = useRef<HTMLDivElement>();

  const renderShareParticipant = useMemo(
    () => Boolean(isShareActive && !isSpaceShareActive),
    [isShareActive, isSpaceShareActive]
  );

  const rowsNeeded = useMemo(() => {
    if (!currentRoom) return 1;
    if (videoGrid === TVideoGrid.SMALL) return 1;
    if (videoGrid === TVideoGrid.MEDIUM) return 2;

    //For VideoGrid.Large
    const defaultRows = 4;
    const numberOfParticipants = participants.length + 1;

    // in case of the large grid we need to calc the tiles needed, for eg. when there is only one participant
    if (numberOfParticipants < defaultRows) {
      return numberOfParticipants + (renderShareParticipant ? 1 : 0); // if the user is sharing screen add +1
    }

    return defaultRows;
  }, [videoGrid, participants, renderShareParticipant]);

  useEffect(() => {
    if (!currentRoom) return;

    const onParticipantConnected = (participant) => {
      // eslint-disable-next-line no-console
      console.log("onParticipantConnected", participant);

      setParticipants((prevParticipants) => [
        ...new Set([...prevParticipants, participant]),
      ]);
    };

    const onParticipantDisconnected = (participant) => {
      // eslint-disable-next-line no-console
      console.log("onParticipantDisconnected", participant);

      setParticipants((prevParticipants) => [
        ...new Set([...prevParticipants.filter((p) => p !== participant)]),
      ]);
    };

    const onParticipantReconnect = (participant) => {
      // eslint-disable-next-line no-console
      console.log("onParticipantReconnect", participant);

      setReconnectingParticipants((prevParticipants) => [
        ...new Set([...prevParticipants.filter((p) => p !== participant)]),
      ]);
    };

    const onParticipantReconnecting = (participant) => {
      // eslint-disable-next-line no-console
      console.log("onParticipantReconnecting", participant);

      setReconnectingParticipants((prevParticipants) => [
        ...new Set([...prevParticipants, participant]),
      ]);
    };

    currentRoom.on("participantConnected", onParticipantConnected);
    currentRoom.on("participantDisconnected", onParticipantDisconnected);
    currentRoom.on("participantReconnected", onParticipantReconnect);
    currentRoom.on("participantReconnecting", onParticipantReconnecting);

    currentRoom.participants.forEach(onParticipantConnected);

    return () => {
      currentRoom.off("participantReconnected", onParticipantConnected);
      currentRoom.off("participantDisconnected", onParticipantDisconnected);
      currentRoom.off("participantReconnected", onParticipantReconnect);
      currentRoom.off("participantReconnecting", onParticipantReconnecting);
    };
  }, [currentRoom]);

  useEffect(() => {
    // calculate avaliable ui space so it can be filled with video participants
    const availableWidth = calculateAvailableScreenSpace(
      videoGrid,
      windowWidth,
      currentOverviewPanel,
      showParticipantsList
    );

    // calculate tile size
    const { width, height, tileSizes } = calcTileSize(
      windowHeight,
      availableWidth,
      rowsNeeded,
      videoGrid,
      currentRoom?.participants.size || 0
    );

    // @ts-ignore
    setTileSize({ width, height });

    // set the max participants can be shown in the grid
    // const subtractNr = videoGrid === TVideoGrid.MEDIUM ? 4 : 1; // if grid is medium subtract 4 because it takes up 4 spaces in the grid
    const maxTileSize = renderShareParticipant ? tileSizes - 1 : tileSizes; // if the user is sharing screen remove tiles from grid because sharing takes takes up space aswell

    setMaxRemoteParticipants(() => {
      if (videoGrid === TVideoGrid.LARGE)
        return currentRoom?.participants.size || 0;
      return maxTileSize;
    });
  }, [
    windowHeight,
    rowsNeeded,
    windowWidth,
    currentOverviewPanel,
    showParticipantsList,
    videoGrid,
    renderShareParticipant,
    currentRoom?.participants.size,
  ]);

  // when the media overlay is open we check the type if its iframe we set isOverlayOpen to false
  const isOverlayOpen = useMemo(() => {
    const alwaysShowVideoWindow = Boolean(
      mediaAreaData?.type === "iframe" || mediaAreaData?.type === "pdf"
    );
    if (isMediaOverlayOpen) alwaysShowVideoWindow;
    return false;
  }, [mediaAreaData, isMediaOverlayOpen]);

  const isWindowVisible = useMemo(() => {
    const isVisible =
      isFullscreen || !currentRoom || isOverlayOpen || callState === "leaving"
        ? false
        : true;

    if (!isVisible) videoService.setState({ showParticipantsList: false });
    return isVisible;
  }, [isFullscreen, currentRoom, isOverlayOpen, callState]);

  useEffect(() => {
    if (isFullscreen) {
      setTimeout(() => {
        setRenderTiles(false);
      }, 801);
    } else {
      setRenderTiles(true);
    }
  }, [isFullscreen, isMediaOverlayOpen]);

  return (
    <StyledVideoWindow
      isVisible={isWindowVisible}
      videoGrid={videoGrid}
      showParticipantsList={showParticipantsList}
      isOverviewPanelVisible={currentOverviewPanel}
    >
      {currentRoom?.localParticipant && (
        <ParticipantsList
          participants={[...participants, currentRoom.localParticipant]}
          reconnectingParticipants={reconnectingParticipants}
        />
      )}

      <StyledParticipantsCamera participantsList={showParticipantsList}>
        <TilesWrapper
          ref={tilesWrapperRef}
          videoGrid={videoGrid}
          rows={rowsNeeded}
          tileCount={
            !currentRoom
              ? 0
              : isShareActive
              ? currentRoom.participants.size + 1
              : currentRoom.participants.size
          }
          style={{
            "--videoWidth":
              videoGrid === TVideoGrid.LARGE
                ? tileSize.width
                : `${tileSize.width}rem`,
            "--videoHeight":
              videoGrid === TVideoGrid.LARGE
                ? tileSize.height
                : `${tileSize.height}rem`,
          }}
        >
          <ScreenShareParticipant
            isVisible={isShareActive && renderShareParticipant}
          />

          {sortBy(participants, (item) =>
            item.sid === dominantSpeaker ? 0 : 1
          ).map((participant, index) => (
            <VideoWindowParticipant
              key={participant.sid}
              participant={participant}
              isReconnecting={Boolean(
                reconnectingParticipants.find((p) => p.sid === participant.sid)
              )}
              isVisible={index < maxRemoteParticipants}
              render={renderTiles}
            />
          ))}

          {currentRoom?.localParticipant && (
            <VideoWindowParticipant
              participant={currentRoom.localParticipant}
              local={true}
              isVisible={true}
              render={true}
            />
          )}
        </TilesWrapper>

        <VideoWindowControls />
      </StyledParticipantsCamera>
    </StyledVideoWindow>
  );
};

export default VideoWindow;
