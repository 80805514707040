import { button, Leva, useControls } from "leva";

import debugService from "@/services/DebugService";
import initService from "@/services/InitService";

export default function Debug() {
  const isInitialized = initService((state) => state.isInitialized);
  const isEnabled = debugService((state) => state.isEnabled);

  useControls("Log", () => ({
    dump: button(() => {
      debugService.getState().dumpLogToConsole();
    }),
  }));

  return <Leva hidden={!isInitialized || !isEnabled} />;
}
