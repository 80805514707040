import * as React from "react";
import { useRef, useState } from "react";

import SasImage from "@/components/Dom/Common/SasImage";
import StatusNotification from "@/components/Dom/Notifications/StatusNotification";

import notificationService from "@/services/NotificationService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { getInitials, profileColor } from "@/utils/User";

import {
  ImageButton,
  ProfilePicture,
  Initials,
  StyledProfilePictureUpload,
} from "./styles";

// TODO @Ana: proper type
interface IProps {
  user: {
    firstname?: TUser["firstname"];
    lastname?: TUser["lastname"];
  };
  buttonText?: string;
}

const ProfilePictureUpload: React.FC<IProps> = ({
  user,
  buttonText = "Upload new image",
}) => {
  const ownUser = userService.getState().getOwnUser();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  if (!ownUser) return <></>;
  const { firstname, lastname } = user;

  const openFileDialogue = () => {
    inputRef?.current?.click();
  };

  const handleImageChange = async (e) => {
    if (!inputRef?.current?.files || !inputRef.current.files[0]) return;

    const file = inputRef.current.files[0];
    const fileName = file.name.toLowerCase();

    //Check if the file is really allowed, as it's possible to select all file types in the file dialogue
    if (
      !fileName.endsWith(".jpg") &&
      !fileName.endsWith(".png") &&
      !fileName.endsWith(".gif")
    ) {
      notificationService
        .getState()
        .addNotification(
          <StatusNotification
            text={"Please select a png, jpg or gif file"}
            type="danger"
          />
        );
      return;
    }

    setLoading(true);
    await userService.getState().setOwnProfileImage(file);
    setLoading(false);
  };

  return (
    <StyledProfilePictureUpload>
      <ProfilePicture ratio={1} randomColor={profileColor(ownUser.id)}>
        {ownUser.profileImage?.url ? (
          <SasImage image={ownUser.profileImage} options={{ aspectRatio: 1 }} />
        ) : (
          <Initials>{getInitials(firstname, lastname)}</Initials>
        )}
      </ProfilePicture>

      <input
        hidden
        type="file"
        ref={inputRef}
        accept="image/png, image/gif, image/jpeg"
        onChange={handleImageChange}
      />
      <ImageButton
        outline
        disabled={loading}
        text={buttonText}
        onClick={(e) => {
          e.preventDefault();
          openFileDialogue();
        }}
      />
    </StyledProfilePictureUpload>
  );
};

export default ProfilePictureUpload;
