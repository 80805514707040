import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import {
  StyledChatBubble,
  Name,
  Time,
  ContentWrapper,
  StyledLink,
} from "@/components/Dom/Chat/ChatBubble/styles";
import UserName from "@/components/Dom/Common/UserName";

import { TMessage } from "@/services/ChatService";
import debugService from "@/services/DebugService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import { formatChatBubbleTimestamp } from "@/utils/Date";
import { urlify } from "@/utils/Text";

export type TChatBubble = {
  message: TMessage;
};

const ChatBubble: React.FC<TChatBubble> = ({ message }) => {
  const [user, setUser] = useState<TUser>();
  const isSelf = userService.getState().isSelf(message.userId);

  useEffect(() => {
    const init = async () => {
      try {
        let user: TUser | null = null;

        if (isSelf) user = userService.getState().ownUser;
        else user = await userService.getState().getUserById(message.userId);

        if (user) setUser(user);
      } catch (error) {
        debugService.getState().logError(`ChatBubble::useEffect[]: ${error}`);
        return;
      }
    };

    init();
  }, []);

  if (!user) return null;

  return (
    <StyledChatBubble self={isSelf}>
      <Name>
        {isSelf ? "You" : <UserName users={[user]} applyFontStyle={false} />}
      </Name>
      <Time>{formatChatBubbleTimestamp(message.created_at)}</Time>

      <ContentWrapper>
        <ReactMarkdown
          // declare custom components for html tags
          components={{
            // eslint-disable-next-line react/display-name
            a: (props) => (
              <StyledLink
                href={props.href}
                target="_blank"
                rel="noreferrer nofollow"
              >
                {props.children}
              </StyledLink>
            ),
          }}
        >
          {urlify(message.content)}
        </ReactMarkdown>
      </ContentWrapper>
    </StyledChatBubble>
  );
};

export default ChatBubble;
