import styled from "styled-components";

export const StyledProfilePanelContent = styled.div`
  overflow: hidden;
`;

export const Hero = styled.div`
  margin-bottom: 2.7rem;
`;

export const SectionsWrapper = styled.div`
  display: grid;
  gap: 3rem;
`;
