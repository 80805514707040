import { useMemo } from "react";

import VideoExtendedControls from "@/components/Dom/VideoChat/VideoExtendedControls";
import {
  StyledVideoFullscreen,
  CloseButton,
} from "@/components/Dom/VideoChat/VideoFullscreen/styles";
import VideoFullscreenControls from "@/components/Dom/VideoChat/VideoFullscreenControls";
import ShareParticipant from "@/components/Dom/VideoChat/VideoWindow/ShareParticipant";

import videoService from "@/services/VideoService";

import CloseBold from "~/public/assets/icons/CloseBold.svg";

const VideoFullscreen = () => {
  const isFullscreen = videoService((state) => state.isFullscreen);
  const isExtended = videoService((state) => state.isExtendedScreenShare);

  const shouldRender = useMemo(() => {
    return isFullscreen || isExtended;
  }, [isFullscreen, isExtended]);

  return (
    <>
      <StyledVideoFullscreen
        isFullscreen={isFullscreen}
        isVisible={shouldRender}
        isExtended={isExtended}
      >
        {shouldRender && <ShareParticipant isVisible={true} controls={false} />}
      </StyledVideoFullscreen>

      {isExtended && <VideoExtendedControls />}

      <CloseButton
        icon={<CloseBold />}
        onClick={videoService.getState().closeFullscreen}
        isVisible={isFullscreen}
      />

      <VideoFullscreenControls isVisible={isFullscreen} />
    </>
  );
};

export default VideoFullscreen;
