import { StyledAspectRatio } from "@/components/Dom/OverviewPanel/Panels/SpacePanel/styles";

import { Text } from "@/styles/typography";

import Lock from "~/public/assets/icons/Lock.svg";

interface IProps {
  title: string;
}

const LockedSpacePanel: React.FC<IProps> = ({ title }) => (
  <>
    <StyledAspectRatio ratio={240 / 135}>
      <Lock />
    </StyledAspectRatio>

    <Text>
      You dont have the permission to join this Module. If you want to have
      access please talk to your Space Host.
    </Text>
  </>
);

export default LockedSpacePanel;
