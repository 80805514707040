import { AppProps } from "next/app";
import { ReactNode } from "react";

import Setup from "@/components/Dom/Setup";

import { Page } from "@/layouts/AppLayout/styles";
import recorderService from "@/services/RecorderService";

export interface ISetupLayout {
  pageProps: AppProps["pageProps"];
  children: ReactNode;
}

const SetupLayout = ({ pageProps, children }: ISetupLayout) => {
  const isRecording = recorderService((state) => state.isRecording);

  return (
    <Page isRecording={isRecording}>
      <main>
        <Setup pageProps={pageProps}>{children}</Setup>
      </main>
    </Page>
  );
};

export default SetupLayout;
