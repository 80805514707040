import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { Text } from "@/styles/typography";

export const StyledChatPanelProfileList = styled.section`
  display: grid;
  grid-gap: 1.3rem;

  &:not(:last-of-type) {
    margin-bottom: 3.1rem;
  }
`;

export const SubHeadline = styled(Text)`
  color: ${colors.grey.light.hex};
`;

export const A = styled.a`
  transition: color 0.2s linear;

  ${Hover(css`
    color: ${colors.tangerine.normal.hex};
  `)}
`;

export const Ul = styled.ul`
  display: grid;
  grid-gap: 1.1rem;
`;
