import styled from "styled-components";

import { colors } from "@/styles/colors";

export const StyledProgressButton = styled.div`
  position: relative;
  width: 5.8rem;
  height: 5.8rem;
  margin: 0 auto -2.9rem;
  background-color: ${colors.white.default};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 14;
`;

export const StarWrapper = styled.button`
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey.lightest.hex};
  color: ${colors.grey.lighter.hex};
  border-radius: 100%;
  transition: background-color 0.2s linear;

  svg {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const ProgressCircleWrapper = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: calc(100% - 0.5rem);
  height: calc(100% - 0.5rem);
  color: ${colors.tangerine.normal.hex};

  svg {
    fill: none;
  }
`;
