import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";

export const StyledModule = styled.div`
  border-radius: 100%;
  cursor: pointer;
  height: var(--avatar-size);
  margin: auto;
  overflow: hidden;
  position: relative;
  width: var(--avatar-size);
  z-index: 1;

  ${(props) =>
    !props.canAccess &&
    css`
      background-color: ${colors.grey.diffAreas.hex};
      display: grid;
      place-items: center;

      svg {
        width: 1rem;
        color: ${colors.grey.normal.hex};
      }
    `}
`;

export const StyledImage = styled.div`
  background-image: var(--image-url);
  background-size: cover;
  left: 0;
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  z-index: 1;
  background-position: center;
`;

export const StyledInitials = styled.div`
  align-items: center;

  display: flex;
  font-family: var(--font-sans-serif);
  font-size: 1.2rem;
  font-weight: 700;
  left: 50%;
  line-height: 1em;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  width: 100%;
  z-index: 0;
`;
