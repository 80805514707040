import videoService from "@/services/VideoService";

import { colors } from "@/styles/colors";

import { StyledScreenshareButton } from "./styles";

import ScreenShare from "~/public/assets/icons/ScreenShare.svg";

const ScreenshareButton = () => {
  const { localParticipant, isShareActive } = videoService((state) => ({
    localParticipant: state.localParticipant,
    isShareActive: state.isShareActive,
  }));

  const onClick = () => {
    videoService.getState().toggleShare();
  };

  return (
    <StyledScreenshareButton
      icon={<ScreenShare />}
      iconWidth={18.6}
      iconHeight={13.3}
      iconColor={
        localParticipant.isSharing
          ? colors.white.default
          : colors.grey.normal.hex
      }
      big={true}
      disabled={isShareActive && !localParticipant.isSharing}
      backgroundColor={
        localParticipant.isSharing
          ? colors.grey.normal.hex
          : colors.grey.lightest.hex
      }
      onClick={onClick}
    />
  );
};

export default ScreenshareButton;
