import React from "react";

import AvatarPanel from "@/components/Dom/OverviewPanel/Panels/AvatarPanel";
import ProfilePanel from "@/components/Dom/OverviewPanel/Panels/ProfilePanel";

import navService, { EOverviewPanel } from "@/services/NavService";

import { StyledProfileAvatarPanel } from "./styles";

type TOverviewPanel = React.HTMLAttributes<HTMLDivElement>;

interface IProps {}

const ProfileAvatarPanel: React.FC<IProps & TOverviewPanel> = () => {
  const { currentOverviewPanel } = navService((state) => ({
    currentOverviewPanel: state.currentOverviewPanel,
  }));

  return (
    <>
      {currentOverviewPanel === EOverviewPanel.PROFILE && (
        <StyledProfileAvatarPanel>
          <ProfilePanel />
          <AvatarPanel />
        </StyledProfileAvatarPanel>
      )}
    </>
  );
};

export default ProfileAvatarPanel;
