import React, { ReactElement } from "react";

import { StyledNewMessageCount } from "../styles";

import { StyledIcon, StyledChevron } from "./styles";

import Chevron from "~/public/assets/icons/Chevron.svg";

type TSectionIcon = React.HTMLAttributes<HTMLDivElement>;

export interface IProps {
  icon: ReactElement;
  newMessageCount: number;
  hasItems: boolean;
}

const SectionIcon: React.FC<IProps & TSectionIcon> = ({
  icon,
  newMessageCount,
  hasItems = true,
}) => {
  return (
    <>
      <StyledIcon>
        {icon}
        {newMessageCount > 0 && (
          <StyledNewMessageCount>{newMessageCount}</StyledNewMessageCount>
        )}
      </StyledIcon>
      <StyledChevron data-show={hasItems}>
        <Chevron />
      </StyledChevron>
    </>
  );
};

export default SectionIcon;
