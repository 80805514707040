import styled from "styled-components";

import {
  FlexAndGrowBody,
  FlexAndGrowHeader,
  StyledFlexAndGrow,
} from "@/components/Dom/Common/FlexAndGrow/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

export const StyledParticipantsList = styled.div`
  border-radius: var(--mainNav-overview-panel-border-radius) 0 0
    var(--mainNav-overview-panel-border-radius);
  padding: 1.4rem 0 6rem 0;
  background: ${colors.white.default};
  z-index: 1;
  transition: all 0.5s ${ease.inOutCubic};
  position: absolute;
  width: var(--video-participants-list-width);
  height: 100%;

  ${(props) => {
    if (props.visible) {
      return `
      opacity: 1;
      visibility: visible;
      left: calc(-1 * var(--video-participants-list-width));
    `;
    } else {
      return `
        opacity: 0;
        visibility: hidden;
        left: 0;
    `;
    }
  }}

  ${FlexAndGrowBody} {
    > div {
      padding: var(--flex-grow-body-padding);
    }
  }

  ${StyledFlexAndGrow} {
    height: 100%;
  }

  ${FlexAndGrowHeader} {
    padding: 0 2rem;
  }
`;

export const StyledParticipantsListUl = styled.ul`
  display: grid;
  gap: 1rem;
  width: 100%;
  margin-top: 2.8rem;
`;
