import styled, { css } from "styled-components";

import Input from "@/components/Dom/Common/Input";
import { InputButton, StyledInput } from "@/components/Dom/Common/Input/styles";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { MidHeadline } from "@/styles/typography";

export const StyledModuleNotification = styled.section`
  ${MidHeadline} {
    margin-bottom: 1.2rem;
  }
`;

export const StyledNotificationInput = styled(Input)`
  border-radius: 4rem;
  background-color: ${colors.grey.lightest.hex};
  border: 0.1rem solid ${colors.grey.diffAreas.hex};

  ${StyledInput} {
    padding: 1.1rem 0.5rem 1.1rem 0.9rem;
  }

  svg {
    color: ${colors.orange.hex};
  }

  ${InputButton} {
    ${Hover(css`
      background-color: ${colors.white.default};
      svg {
        color: ${colors.tangerine.normal.hex};
      }
    `)}
  }
`;
