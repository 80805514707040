import styled from "styled-components";

export const MediaItemList = styled.ul`
  display: block;
  margin-bottom: 0.5rem;
`;

export const SearchWrapper = styled.ul`
  margin-bottom: 3rem;
`;
