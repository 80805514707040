import { useRouter } from "next/router";

import InfoHero from "@/components/Dom/Common/InfoHero";

import moduleService from "@/services/ModuleService";
import { TModule } from "@/services/ModuleService/types";
import navService, { EOverviewPanel } from "@/services/NavService";
import vrService from "@/services/VrService";
import { buildModuleSlug } from "@/utils/Module";
import { buildSpaceSlug } from "@/utils/Space";

import { HeroButton } from "./styles";

const ModuleInfoHero = ({ module }: { module: TModule }) => {
  const currentModuleId = moduleService((state) => state.currentModuleId);
  const isInSpace = currentModuleId === module.id;
  const router = useRouter();

  const handleClick = async () => {
    navService.getState().setPanel(EOverviewPanel.SPACES);

    if (isInSpace) {
      await router.push(buildSpaceSlug(module.space as number));
    } else {
      await router.push(buildModuleSlug(module.id));
    }
  };

  if (!module) return null;

  const deviceSupportsVr = vrService.getState().currentDeviceSupportsVr();

  const getButtonText = () => {
    let buttonText = isInSpace ? "Leave " : "Enter ";
    const moduleUsedInVR = module?.supportsVr && deviceSupportsVr;

    buttonText += moduleUsedInVR ? "VR module" : "module";
    return buttonText;
  };

  return (
    <InfoHero image={module.previewImage} title={module.title}>
      <HeroButton outline={true} fullWidth={false} onClick={handleClick}>
        {getButtonText()}
      </HeroButton>
    </InfoHero>
  );
};

export default ModuleInfoHero;
