import { toggleSession } from "@react-three/xr";
import { useRouter } from "next/router";
import { FC, useRef } from "react";

import Button from "@/components/Dom/Common/Button";
import SasImage from "@/components/Dom/Common/SasImage";
import { ClickOutsideWhitelist } from "@/components/Dom/MainNav";

import moduleService from "@/services/ModuleService";
import { TSpace } from "@/services/SpaceService/types";
import vrService from "@/services/VrService";
import { profileColor } from "@/utils/User";

import { MidHeadline, Text } from "@/styles/typography";

import { StyledSpaceInfo, Top, StyledImage } from "./styles";

type TSpaceInfo = {
  space: TSpace;
  isVisible: boolean;
  onMouseLeave: () => void;
};

const SpaceInfo: FC<TSpaceInfo> = ({ space, isVisible, onMouseLeave }) => {
  const ref = useRef();
  const router = useRouter();
  const defaultModule = moduleService
    .getState()
    .getModuleById(space.defaultModule);

  if (!space) return null;

  const deviceSupportsVr = vrService.getState().currentDeviceSupportsVr();

  const getEnterSpaceButtonText = () => {
    const spaceWillBeJoinedInVr = defaultModule?.supportsVr && deviceSupportsVr;
    return spaceWillBeJoinedInVr ? "Enter VR Space" : "Enter Space";
  };

  return (
    <StyledSpaceInfo
      ref={ref}
      isVisible={isVisible}
      data-component={ClickOutsideWhitelist.SPACE_INFO} // used in the click outside function
      onMouseLeave={onMouseLeave}
    >
      <Top>
        <StyledImage theme={profileColor(space.id)}>
          {space.previewImage && (
            <SasImage
              image={space.previewImage}
              options={{
                size: "large",
                aspectRatio: 0.5,
              }}
            />
          )}
          <span aria-hidden>{space.title}</span>
        </StyledImage>

        {defaultModule && (
          <Button
            outline
            text={getEnterSpaceButtonText()}
            onClick={() => {
              if (defaultModule.slug) {
                router.push(`/${space.slug}/${defaultModule.slug}`);
              }
            }}
          />
        )}
      </Top>

      <MidHeadline>{space.title}</MidHeadline>

      {space.description && <Text>{space.description}</Text>}

      <Button
        fullWidth
        text={"Details"}
        onClick={() => {
          router.push(space.slug);
        }}
      />
    </StyledSpaceInfo>
  );
};

export default SpaceInfo;
