import styled, { css } from "styled-components";

import { StyledUserName } from "@/components/Dom/Common/UserName/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { Hover, MQ } from "@/styles/mixins";
import { Text, TextStyles } from "@/styles/typography";

export const BubbleSelf = css`
  background-color: ${colors.white.default};
  border: 0.1rem solid ${colors.grey.lighter.hex};
  align-self: flex-end;
  border-radius: var(--bubble-radius) var(--bubble-radius) 0rem
    var(--bubble-radius);
`;

export const BubbleOther = css`
  background-color: ${colors.grey.lightest.hex};
  border-radius: var(--bubble-radius) var(--bubble-radius) var(--bubble-radius)
    0rem;
`;

export const StyledChatBubble = styled.div`
  ${(props) => (props.self ? BubbleSelf : BubbleOther)};

  display: grid;
  grid-template-areas:
    "name time"
    "content content";
  width: 100%;
  max-width: calc(100% - 1rem);
  padding: 1.5rem;
  margin-bottom: 1rem;
  overflow: hidden;

  ${MQ.LG`
    padding: 2rem 2rem 1.6rem 2rem;
    max-width: calc(100% - 2rem);
  `}

  ${StyledUserName} {
    color: ${colors.grey.light.hex};
  }
`;

export const Name = styled(Text)`
  grid-area: name;
  color: ${colors.grey.light.hex};
`;

export const Time = styled(Text)`
  text-align: right;
  grid-area: time;
  color: ${colors.grey.light.hex};
`;

export const ContentWrapper = styled.div`
  grid-area: content;

  word-break: break-word;
  ${TextStyles};
`;

export const StyledLink = styled.a`
  color: ${colors.orange.hex};
  font-weight: 700;
  transition: color ${ease.inOutCubic} 0.2s;

  ${Hover(css`
    color: ${colors.tangerine.normal.hex};
  `)}
`;
