import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import { DarkTheme, Hover } from "@/styles/mixins";
import { TextStyles } from "@/styles/typography";

export const StyledButtonPill = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 2.8rem;
  border-radius: 2rem;
  background-color: ${colors.grey.lightest.hex};
  color: ${colors.white.default};
  transition: background-color 0.2s linear, border-color 0.2s linear;
  ${TextStyles};
  font-weight: bold;
  text-transform: uppercase;
  color: ${colors.black.hex};

  svg {
    height: var(--svg-size);
    width: var(--svg-size);
  }

  ${DarkTheme(css`
    background-color: ${colors.orange.hex};
    color: ${colors.white.default};
  `)}

  ${Hover(css`
    background-color: ${colors.tangerine.normal.hex};
    color: ${colors.white.default};
  `)}
`;
