import styled, { css } from "styled-components";

export const Page = styled.div`
  ${(props) =>
    props.isRecording &&
    css`
      opacity: 0;
      z-index: -1;
      pointer-events: none;
    `}
`;
