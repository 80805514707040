import styled from "styled-components";
import SasImage from "@/components/Dom/Common/SasImage";

import { ToggleStyle } from "@/components/Dom/WorldMap/SpaceTile/styles";

export const StyledMapMarker = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
  height: 8rem;
  cursor: pointer;
  padding: 1rem;

  ${ToggleStyle}
`;

export const StyledSasImage = styled(SasImage)`
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 100%;
    width: 100%;
`;

export const Pin = styled.div`
  position: absolute;
  top: 0;
  width: 8rem;
  height: 8rem;
  background-color: white;
  transform: rotate(45deg);
  border-radius: 100% 100% 0 100%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
  overflow: hidden;
  background-color: ${(props) => props.themeColor.background};
  color: ${(props) => props.themeColor.text};
`;
