import React from "react";

import Button from "@/components/Dom/Common/Button";
import { StyledVrButtonWrapper } from "@/components/Dom/EnterVrScreen/styles";

import moduleService from "@/services/ModuleService";
import navService, { EOverviewPanel } from "@/services/NavService";
import sceneService from "@/services/SceneService";
import vrService from "@/services/VrService";

const VrButton = () => {
  const currentModuleSupportsVr = moduleService((state) =>
    state.currentModuleSupportsVr()
  );

  const isProfileOpen = navService(
    (state) => state.currentOverviewPanel === EOverviewPanel.PROFILE
  );
  const isSceneInitialized = sceneService((state) => state.isSceneInitialized);
  const deviceSupportsVr = vrService.getState().currentDeviceSupportsVr();

  const shouldDisplayVrButton = () => {
    return (
      !isProfileOpen &&
      isSceneInitialized &&
      currentModuleSupportsVr &&
      deviceSupportsVr
    );
  };

  return (
    <StyledVrButtonWrapper isVisible={shouldDisplayVrButton()}>
      <Button
        type="button"
        onClick={vrService.getState().startVrSession}
        text={"Enter VR"}
      ></Button>
    </StyledVrButtonWrapper>
  );
};

export default VrButton;
