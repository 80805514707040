import { useRouter } from "next/router";
import React, { ReactElement, useEffect } from "react";

import MainNavSectionCTA from "./MainNavSectionCTA";

import { IconWrapper } from "@/components/Dom/Host/HostTab/Content/ModuleOverview/styles";

import navService, { ENavSection, EOverviewPanel } from "@/services/NavService";
import spaceService from "@/services/SpaceService";

import {
  StyledHead,
  StyledHeader,
  StyledMainNavSection,
  StyledButton,
  EditorButton,
} from "./styles";

import Pencil from "~/public/assets/icons/Pencil.svg";
import Plus from "~/public/assets/icons/Plus.svg";

type TMainNavSection = React.HTMLAttributes<HTMLDivElement>;

export interface IProps {
  icon: ReactElement;
  hasPlusButton?: boolean;
  hasHostButton?: boolean;
  newMsgCount: number;
  section: ENavSection;
  hasItems?: boolean;
  title: string | React.ElementType;
  onCTAClick: () => void;
}

const MainNavSection: React.FC<IProps & TMainNavSection> = ({
  icon,
  hasPlusButton = false,
  hasHostButton = false,
  newMsgCount,
  section,
  hasItems,
  title,
  onCTAClick,
  children,
  ...others
}) => {
  const {
    navSections,
    currentOverviewPanel,
    navSectionsExpanded,
    activeItem,
    activeSection,
  } = navService((state) => ({
    navSections: state.navSections,
    currentOverviewPanel: state.currentOverviewPanel,
    navSectionsExpanded: state.navSectionsExpanded,
    activeItem: state.activeItem,
    activeSection: state.activeSection,
  }));
  const router = useRouter();

  useEffect(() => {
    if (activeItem) navService.getState().openNavSection(activeSection);
  }, [activeItem, activeSection]);

  const handlePlusButtonClick = () => {
    navService.getState().setPanel(EOverviewPanel.NEW_CHAT);
  };

  const handleMouseLeave = () => {
    navService.getState().closeNavSection(section);
  };

  const showEditorButton = Boolean(
    hasHostButton && spaceService.getState().getHostedSpaces()?.size
  );

  return (
    <StyledMainNavSection
      data-section-expanded={navSectionsExpanded.get(section)}
      data-section-open={navSections.get(section)}
      data-overview-open={currentOverviewPanel !== null}
      onMouseLeave={handleMouseLeave}
      {...others}
    >
      <StyledHeader>
        <MainNavSectionCTA
          icon={icon}
          newMsgCount={newMsgCount}
          section={section}
          hasItems={hasItems}
          onClick={onCTAClick}
        />
        <StyledHead showEditorButton={showEditorButton}>
          <span>{title}</span>
          {hasPlusButton && (
            <StyledButton onClick={handlePlusButtonClick}>
              <Plus />
            </StyledButton>
          )}
          {showEditorButton && (
            <EditorButton
              onClick={async () => {
                await router.push("/host");
              }}
            >
              <IconWrapper>
                <Pencil />
              </IconWrapper>
              Editor
            </EditorButton>
          )}
        </StyledHead>
      </StyledHeader>

      {children}
    </StyledMainNavSection>
  );
};

export default MainNavSection;
