import styled, { css } from "styled-components";

import { StyledSasImage } from "@/components/Dom/Common/SasImage/styles";
import { ToggleStyle } from "@/components/Dom/WorldMap/SpaceTile/styles";

import { colors } from "@/styles/colors";
import { MidHeadline, Text } from "@/styles/typography";

export const StyledSpaceInfo = styled.div`
  width: 28rem;
  padding: 2rem;
  border-radius: 1rem;
  background-color: ${colors.white.default};
  z-index: 3;

  ${MidHeadline} {
    margin-bottom: 1.2rem;
  }

  ${Text} {
    margin-bottom: 2rem;
  }

  ${ToggleStyle};
`;

export const Top = styled.div`
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledImage = styled.div`
  align-items: center;
  border-radius: 1rem;
  display: grid;
  grid-template: 12rem / 24rem;
  justify-content: center;
  margin-bottom: -2.5rem;
  overflow: hidden;
  width: 100%;

  ${StyledSasImage} {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  span {
    font-family: var(--font-sans-serif);
    font-size: 1.2rem;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-weight: 700;
    line-height: 1em;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
  }

  ${(props) =>
    props.theme &&
    css`
      background: ${props.theme.background};
      color: ${props.theme.text};
    `}
`;
