import { FC, useMemo, useState } from "react";

import Button from "@/components/Dom/Common/Button";

import mediaOverlayService from "@/services/MediaOverlayService";
import popupOverlayService from "@/services/PopupOverlayService";
import videoService from "@/services/VideoService";
import { TVideoGrid } from "@/services/VideoService/types";

import { BigMidHeadline, Text } from "@/styles/typography";

import {
  ButtonWrapper,
  StyledVideoGridControl,
  Content,
  Item,
  Icon,
  RadioButton,
  RadioButtonCircle,
  Label,
  ItemContent,
} from "./styles";

import GridLargeIcon from "~/public/assets/icons/GridLarge.svg";
import GridMediumIcon from "~/public/assets/icons/GridMedium.svg";
import GridSmallIcon from "~/public/assets/icons/GridSmall.svg";

type TVideoGridControl = {
  acceptButtonText?: string;
  headline?: string;
  subHeadline?: string;
};

const VideoGridControl: FC<TVideoGridControl> = ({
  acceptButtonText = "Accept",
  subHeadline = "Video Calls",
  headline = "Choose your Grid",
}) => {
  const { close } = popupOverlayService.getState();
  const { videoGrid } = videoService.getState();
  const [value, setValue] = useState<TVideoGrid>(videoGrid);
  const { mediaAreaData } = mediaOverlayService.getState();

  // when type is iframe we disable grid switching
  const isDisabled = useMemo(() => {
    if (!mediaAreaData) return false;
    return mediaAreaData?.type === "iframe";
  }, [mediaAreaData]);

  const handleAccept = () => {
    videoService.getState().toggleVideoGrid(value);
    close();
  };

  return (
    <StyledVideoGridControl>
      <Text>{subHeadline}</Text>
      <BigMidHeadline serif>{headline}</BigMidHeadline>

      <Content>
        <ul>
          <Item>
            <Icon>
              <GridSmallIcon />
            </Icon>
            <ItemContent>
              <RadioButton
                id="gridSmall"
                type="radio"
                name="radio"
                checked={value === TVideoGrid.SMALL}
                value={TVideoGrid.SMALL}
                onChange={(e) => setValue(e.target.value)}
              />
              <RadioButtonCircle />
              <Label htmlFor="gridSmall">Small</Label>
            </ItemContent>
          </Item>

          <Item>
            <Icon>
              <GridMediumIcon />
            </Icon>
            <ItemContent>
              <RadioButton
                id="gridMedium"
                type="radio"
                checked={value === TVideoGrid.MEDIUM}
                name="radio"
                value={TVideoGrid.MEDIUM}
                disabled={isDisabled}
                onChange={(e) => setValue(e.target.value)}
              />
              <RadioButtonCircle />
              <Label htmlFor="gridMedium">Medium</Label>
            </ItemContent>
          </Item>

          <Item>
            <Icon>
              <GridLargeIcon />
            </Icon>
            <ItemContent>
              <RadioButton
                id="gridLarge"
                type="radio"
                name="radio"
                disabled={isDisabled}
                checked={value === TVideoGrid.LARGE}
                value={TVideoGrid.LARGE}
                onChange={(e) => setValue(e.target.value)}
              />
              <RadioButtonCircle />
              <Label htmlFor="gridLarge">Large</Label>
            </ItemContent>
          </Item>
        </ul>
      </Content>

      <ButtonWrapper>
        <Button text={acceptButtonText} onClick={handleAccept} />
      </ButtonWrapper>
    </StyledVideoGridControl>
  );
};

export default VideoGridControl;
