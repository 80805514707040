import styled, { css } from "styled-components";

import FormInput from "@/components/Dom/Common/Input/FormInput";

import { colors } from "@/styles/colors";
import { Text } from "@/styles/typography";

export const Ul = styled.ul`
  display: grid;
  gap: 1rem;
`;

export const StyledProfilePanelSection = styled.div`
  display: grid;
  gap: 1.3rem;
`;

export const Title = styled(Text)`
  color: ${colors.grey.light.hex};
`;

export const Input = styled(FormInput)`
  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}
`;

export const StyledText = styled(Text)`
  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}
`;
