import React, { FC } from "react";

import mediaOverlayService from "@/services/MediaOverlayService";
import sceneService, {
  TMediaArea,
  TMediaAreaData,
} from "@/services/SceneService";

import { colors } from "@/styles/colors";

import {
  StyledMediaItemDefault,
  Icon,
  Content,
  Title,
  Meta,
  Link,
} from "./styles";

interface ItemProps {
  item: TMediaAreaData;
  icon: React.SVGProps<SVGSVGElement>;
  iconColor?: string;
}

const formatSubtitle = (type: TMediaArea) => {
  if (type === "iframe") return "Iframe";
  return type;
};

const MediaItemIframe: FC<ItemProps> = ({
  item,
  icon,
  iconColor = colors.grey.dark.hex,
}) => {
  const handleClick = () => mediaOverlayService.getState().open(item);

  if (!sceneService.getState().isMediaAreaDataValid(item)) return null;

  return (
    <StyledMediaItemDefault>
      <Link onClick={handleClick} title={item.title}>
        <Icon color={iconColor}>{icon}</Icon>
        <Content>
          <Title>{item.title}</Title>
          <Meta>{formatSubtitle(item.type)}</Meta>
        </Content>
      </Link>
    </StyledMediaItemDefault>
  );
};

export default MediaItemIframe;
