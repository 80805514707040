import React from "react";
import AnimateHeight from "react-animate-height";

import MainNavSection, {
  IProps as IMainNavSectionProps,
} from "@/components/Dom/MainNav/MainNavSection";

import navService from "@/services/NavService";

import { StyledAnimatedHeightWrapper } from "./styles";

type TCollapseSection = React.HTMLAttributes<HTMLDivElement>;

interface IProps
  extends Pick<
    IMainNavSectionProps,
    "icon" | "section" | "hasItems" | "title" | "hasHostButton"
  > {
  newMsgCount: number;
}

const CollapseSection: React.FC<IProps & TCollapseSection> = ({
  icon,
  newMsgCount,
  section,
  hasItems,
  title,
  children,
  ...others
}) => {
  const { navSectionsExpanded, toggleNavSectionExpansion } = navService(
    (state) => ({
      navSectionsExpanded: state.navSectionsExpanded,
      toggleNavSectionExpansion: state.toggleNavSectionExpansion,
    })
  );

  const handleCTAClick = () => {
    toggleNavSectionExpansion(section);
  };

  return (
    <MainNavSection
      icon={icon}
      newMsgCount={newMsgCount}
      section={section}
      hasItems={hasItems}
      title={title}
      onCTAClick={handleCTAClick}
      {...others}
    >
      {children && (
        <StyledAnimatedHeightWrapper>
          <AnimateHeight
            id={`collapse-${section}`}
            duration={500}
            height={navSectionsExpanded.get(section) ? "auto" : 0}
            style={{ flexShrink: 0 }}
          >
            {children}
          </AnimateHeight>
        </StyledAnimatedHeightWrapper>
      )}
    </MainNavSection>
  );
};

export default CollapseSection;
