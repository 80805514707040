import React, { FC, useEffect, useState } from "react";
import { RemoteParticipant, LocalParticipant } from "twilio-video";

import User from "@/components/Dom/Common/User";

import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

interface Props {
  participant: LocalParticipant | RemoteParticipant;
  onChange: (user: TUser) => any;
}

const ParticipantsInfoUser: FC<Props> = ({ participant, onChange }) => {
  const [user, setUser] = useState<TUser | null>(null);
  const [hasRaisedHand, setHasRaisedHand] = useState<boolean>(false);
  const startTrack = (track) => {
    switch (track.kind) {
      case "data":
        track.on("message", (message) => {
          const messageData = JSON.parse(message);
          setHasRaisedHand(messageData.data.status);
        });
        break;
    }
  };
  useEffect(() => {
    const init = async () => {
      const user = await userService
        .getState()
        .getUserById(parseInt(participant.identity, 10));

      if (user) setUser(user);

      participant.on("trackSubscribed", startTrack);
    };
    init();
  }, [participant]);

  useEffect(() => {
    if (user) onChange(user);
  }, [hasRaisedHand]);

  if (!user) return <></>;

  return <User user={user} />;
};

export default ParticipantsInfoUser;
