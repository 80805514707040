import React, { useMemo } from "react";
import { isSafari, isFirefox } from "react-device-detect";

import Popover from "@/components/Dom/Popover/";
import { TPopoverLinks } from "@/components/Dom/Popover/types";
import VideoGridControl from "@/components/Dom/VideoChat/VideoGridControl";
import VideoSettingsControl from "@/components/Dom/VideoChat/VideoSettingsControl";

import popupOverlayService from "@/services/PopupOverlayService";
import videoService from "@/services/VideoService";
import { TVideoGrid } from "@/services/VideoService/types";

import BlurVideoBackground from "~/public/assets/icons/BlurVideoBackground.svg";
import GridIcon from "~/public/assets/icons/Grid.svg";
import KnobIcon from "~/public/assets/icons/Knob.svg";

const { open } = popupOverlayService.getState();

const OptionsButton = () => {
  const {
    hasCamera,
    isSharing,
    blurredVideoBackground,
    videoGrid,
    // imageVideoBackground,
  } = videoService((state) => ({
    hasCamera: state.localParticipant.hasCamera,
    isSharing: state.isExtendedScreenShare,
    blurredVideoBackground: state.localParticipant.blurredVideoBackground,
    videoGrid: state.videoGrid,
    // imageVideoBackground: state.localParticipant.imageVideoBackground,
  }));

  const DATA: Array<TPopoverLinks> = [
    {
      title: "Change Video Grid",
      icon: <GridIcon />,
      handleClick: () => open(<VideoGridControl />),
      disabled: isSharing,
    },
    {
      title: "Enable Background Blur",
      toggleTitle: "Disable Background Blur",
      icon: <BlurVideoBackground />,
      handleClick: () => videoService.getState().toggleBlurredBackground(),
      disabled: !hasCamera,
      hidden: Boolean(isSafari || isFirefox),
      toggle: blurredVideoBackground,
    },
    {
      title: "Audio & Video Settings",
      icon: <KnobIcon />,
      handleClick: () => open(<VideoSettingsControl />),
    },
    // {
    //   title: "Enable Background Image",
    //   toggleTitle: "Disable Background Image",
    //   icon: <BlurVideoBackground />,
    //   handleClick: () => videoService.getState().toggleBackgroundImage(),
    //   disabled: !hasCamera,
    //   toggle: imageVideoBackground,
    // },
  ];

  const popupPosition = useMemo(() => {
    return videoGrid === TVideoGrid.LARGE ? "top-left" : "bottom-left";
  }, [videoGrid]);

  return <Popover links={DATA} popupPosition={popupPosition} />;
};

export default OptionsButton;
