import React from "react";

import AvatarImage from "../AvatarImage";
import { StyledUser, Subtitle, StyledUserName } from "./style";

import chatService from "@/services/ChatService";
import debugService from "@/services/DebugService";
import navService, { EOverviewPanel } from "@/services/NavService";
import userService, {
  isUserValid,
  userCanBeRendered,
} from "@/services/UserService";
import { TUser as TUserData } from "@/services/UserService/types";

type TUser = React.HTMLAttributes<HTMLDivElement>;
interface IProps {
  user: TUserData;
  onClick?: () => void;
}

const User: React.FC<IProps & TUser> = ({
  user,
  onClick = async () => {
    if (userService.getState().isSelf(user.id)) return;
    try {
      const chat = await chatService.getState().getChatByUserIds([user.id]);

      if (!chat) {
        debugService
          .getState()
          .logInfo(
            `User::handleClick(): Failed to open chat for user with id = ${user.id}`
          );
        return;
      }

      navService.getState().setPanel(EOverviewPanel.CHATS, chat.id, 1);
    } catch (error) {
      debugService
        .getState()
        .logInfo(`User::handleClick(): Failed open chat = ${error}`);
    }
  },
  ...others
}) => {
  if (!user || !isUserValid(user) || !userCanBeRendered(user)) return null;
  const subtitle = user.userData?.company;

  return (
    <StyledUser onClick={onClick} {...others}>
      <AvatarImage users={user} />
      <StyledUserName users={[user]} subtitle={subtitle} bold />
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </StyledUser>
  );
};

export default User;
