import styled from "styled-components";

import { colors } from "@/styles/colors";
import { MidHeadlineStyles } from "@/styles/typography";

export const StyledChatContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white.default};
`;

export const StyledDateTitle = styled.h5`
  ${MidHeadlineStyles};
  margin: 0 0 1.5rem;
  text-align: left;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;
