import styled from "styled-components";

import { colors } from "@/styles/colors";
import { center } from "@/styles/mixins";

export const CommunicationAreaCtaWrapper = styled.div`
  background-color: rgba(${colors.white.rgba}, 1);
  position: fixed;
  top: ${(props) => (props.isPushedDown ? "13rem" : "4rem")};
  border-radius: 1rem;
  padding: 2.6rem 2.6rem 3.5rem 2.6rem;
  transition: all 0.4s ease 0s;
  right: ${(props) => (props.isVisible ? "4rem" : "-20rem")};
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  text-align: center;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const CommunicationAreaCtaTitle = styled.div`
  color: ${colors.orange.hex};
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
`;

export const CommunicationAreaCtaButtonRounded = styled.div`
  --button-size: 3rem;
  --svg-size: 1.2rem;

  background-color: ${colors.white.default};
  border-radius: 100%;
  color: ${colors.orange.hex};
  display: grid;
  flex-shrink: 0;
  height: var(--button-size);
  place-items: center;
  position: relative;
  width: var(--button-size);
  margin-right: 1rem;

  svg {
    ${center};

    height: var(--svg-size);
    position: absolute;
    width: var(--svg-size);
  }
`;

export const CommunicationAreaCtaButton = styled.button`
  display: flex;
  border-radius: 2rem;
  padding: 0.5rem 1.1rem 0.5rem 0.5rem;
  background-color: ${colors.orange.hex};
  position: absolute;
  bottom: -20px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: rgba(${colors.white.rgba}, 1);
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: uppercase;
  &:hover {
    background-color: ${colors.tangerine.normal.hex};
    ${CommunicationAreaCtaButtonRounded} {
      color: ${colors.tangerine.normal.hex};
    }
  }
`;

export const CommunicationAreaCtaDescription = styled.div`
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  margin-top: 0.5rem;
`;
