import React, { FC } from "react";

import sceneService, { TMediaAreaData } from "@/services/SceneService";

import { colors } from "@/styles/colors";

import {
  StyledMediaItemLink,
  Icon,
  Content,
  Title,
  Meta,
  Link,
} from "./styles";

interface ItemProps {
  item: TMediaAreaData;
  icon: React.SVGProps<SVGSVGElement>;
  iconColor?: string;
}

const MediaItemLink: FC<ItemProps> = ({
  item,
  icon,
  iconColor = colors.grey.dark.hex,
}) => {
  if (!sceneService.getState().isMediaAreaDataValid(item)) return null;

  return (
    <StyledMediaItemLink>
      <Link href={item.url} target="_blank" title={item.title}>
        <Icon color={iconColor}>{icon}</Icon>
        <Content>
          <Title>{item.title}</Title>
          <Meta>{item.type}</Meta>
        </Content>
      </Link>
    </StyledMediaItemLink>
  );
};

export default MediaItemLink;
