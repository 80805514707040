import styled, { css } from "styled-components";

import Button from "@/components/Dom/Common/Button";
import ButtonPill from "@/components/Dom/Common/ButtonPill";
import { StyledFormTextarea } from "@/components/Dom/Common/FormTextarea/styles";
import { StyledLoadingDots } from "@/components/Dom/LoadingDots/styles";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";
import { BigHeadline } from "@/styles/typography";

export const StyledModuleOverview = styled.div`
  color: ${colors.white.default};

  ${BigHeadline} {
    margin-bottom: 2rem;
  }
`;

export const FormWrapper = styled.form`
  display: grid;
  gap: 1rem;

  ${StyledFormTextarea} {
    height: 11.4rem;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 1rem;
`;

export const ImagesRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 6rem;
`;

export const SaveButton = styled(Button)`
  width: 15rem;
`;

export const DeleteButton = styled(ButtonPill)`
  display: flex;
  justify-content: flex-start;
  height: 4rem;
  margin: 5rem auto 0;
  padding-left: 0.5rem;
  padding-right: 2rem;
  min-width: 16.5rem;

  ${Hover(css`
    svg {
      color: ${colors.tangerine.normal.hex};
    }
  `)}

  ${StyledLoadingDots} {
    margin: 0 auto;
  }
`;

export const IconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: ${colors.white.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  --svg-size: 1.2rem;
  color: ${colors.orange.hex};
`;

export const ImageUpload = styled.input`
  position: absolute;
  top: -1rem;
  left: -1rem;
  width: calc(100% + 2rem);
  height: calc(100% + 2rem);
  border-radius: 1rem;
  opacity: 0;
  z-index: 5;
  cursor: pointer;
`;
