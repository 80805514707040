import { FC, useEffect, useState } from "react";

import { Ul } from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfileList/styles";

import debugService from "@/services/DebugService";
import discoverQuestService from "@/services/DiscoverQuestService";
import { TDiscoverQuestAchievement } from "@/services/DiscoverQuestService/types";
import { TUser } from "@/services/UserService/types";

import { MidHeadline, Text } from "@/styles/typography";

import {
  ListItem,
  StyledChatPanelProfileAchievements,
  IconWrapper,
} from "./styles";

import Star from "~/public/assets/icons/Star.svg";

interface IChatPanelProfileAchievements {
  user: TUser;
}

const ChatPanelProfileAchievements: FC<IChatPanelProfileAchievements> = ({
  user,
}) => {
  const [achievements, setAchievements] = useState<TDiscoverQuestAchievement[]>(
    []
  );

  const getAchievements = async () => {
    try {
      const achievements = await discoverQuestService
        .getState()
        .getUserAchievements(user.id);
      if (!achievements.length) return;

      setAchievements(achievements);
    } catch (error) {
      debugService
        .getState()
        .logError(
          `ChatPanelUserProfile::getAchievements(): Failed to get achievements for user = ${error}`
        );
    }
  };

  useEffect(() => {
    // If you are not logged in, we are using the "fake" user "Eve", who is
    // actually just a virtual asssistant. "Eve" is not actually a user in the
    // system, so getting the achievements will fail, which is why we want
    // to stop here.
    const isVirtualAssistant = user?.badge === 666;
    if(!isVirtualAssistant) {
      getAchievements();
    }
  }, [user]);

  if (!achievements.length) return null;

  return (
    <StyledChatPanelProfileAchievements>
      <MidHeadline>Achievements</MidHeadline>

      <Ul>
        {achievements.map(({ id, name }) => (
          <ListItem key={id}>
            <IconWrapper>
              <Star />
            </IconWrapper>
            <Text bold>{name}</Text>
          </ListItem>
        ))}
      </Ul>
    </StyledChatPanelProfileAchievements>
  );
};

export default ChatPanelProfileAchievements;
