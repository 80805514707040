import styled, { css } from "styled-components";

import { ease } from "@/styles/ease";

export const AnimationHelper = styled.div``;

const fadeIn = css`
  //Notification height + distance between notifications
  height: ${(props) => `${props.height + 10}px`};
  opacity: 1;

  ${AnimationHelper} {
    transform: translate3d(0, 0, 0);
  }
`;

const centerOutStyle = css`
  ${(props) =>
    `${props.isVisible === true && "transform: translate3d(0, -100%, 0);"}`};
`;

const bottomOutStyle = css`
  ${(props) =>
    `${props.isVisible === true && "transform: translate3d(0, 100%, 0);"}`};
`;

const rightOutStyle = css`
  transform: translate3d(100%, 0, 0);
`;

export const StyledAnimatedNotification = styled.div`
  height: 0;
  opacity: 0;
  transition: height 0.6s ${ease.outCubic}, opacity 0.25s linear;

  > div {
    ${(props) =>
      props.position === "top"
        ? centerOutStyle
        : props.position === "bottom"
        ? bottomOutStyle
        : rightOutStyle}
    transition: transform 0.6s ${ease.outCubic};
  }

  ${(props) => props.height > 0 && props.isVisible && fadeIn}
`;
