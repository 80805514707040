import { FC, HTMLAttributes, useEffect, useState } from "react";

import Button from "@/components/Dom/Common/Button";
import Search from "@/components/Dom/Common/Search";
import UserList from "@/components/Dom/Common/UserList";
import StatusNotification from "@/components/Dom/Notifications/StatusNotification";
import PanelHeader from "@/components/Dom/OverviewPanel/PanelHeader";

import debugService from "@/services/DebugService";
import moduleService from "@/services/ModuleService";
import navService from "@/services/NavService";
import notificationService from "@/services/NotificationService";
import userService from "@/services/UserService";
import { TUser, TUserRequestInfo } from "@/services/UserService/types";
import { useUsersInModule } from "@/utils/Module";
import { filterUserByName, sortUsers } from "@/utils/User";

import { ButtonContainer, StyledUserSearchPanel } from "./styles";

type TUserSearchPanel = HTMLAttributes<HTMLButtonElement>;

export interface IProps {
  handleClick: (selectedUserIds: Set<TUser["id"]>) => void;
  title: string;
  onUpdate?: (selectedUserIds: Set<TUser["id"]>) => void;
  isLoading?: boolean;
  disableButton?: boolean;
  ctaText?: string;
  activeUsers?: Array<any>;
  /** true if we should only enable users in the current module */
  onlyInCurrentModule?: boolean;
}

const UserSearchPanel: FC<IProps & TUserSearchPanel> = ({
  activeUsers,
  ctaText,
  isLoading,
  handleClick: onClick,
  title,
  disableButton = false,
  onlyInCurrentModule = false,
  onUpdate,
}) => {
  const currentModuleId = moduleService.getState().currentModuleId;
  // useUsersInModule updates users data with module ids
  onlyInCurrentModule && useUsersInModule(currentModuleId || 0);
  const users = userService((state) => state.cachedUsers);
  const [filteredUsers, setFilteredUsers] = useState<Array<TUser>>(
    new Array<TUser>()
  );
  const [selectedUserIds, setSelectedUserIds] = useState<Set<number>>(
    new Set<number>()
  );
  const [searchUsername, setSearchUsername] = useState<string>("");
  const [updateLock, setUpdateLock] = useState<boolean>(false);

  const updateFilteredUsers = async (unfilteredUsers: Array<TUser>) => {
    try {
      if (unfilteredUsers.length < 1) return;
      const users = sortUsers(
        await filterUserByName(unfilteredUsers, searchUsername)
      );
      setFilteredUsers(users);
    } catch (error) {
      debugService
        .getState()
        .logError(
          `UserSearchPanel::updateFilteredUsers(): Failed filtering users = ${error}`
        );
    }
  };

  useEffect(() => {
    updateFilteredUsers(Array.from(users.values()));
  }, [searchUsername, users]);

  useEffect(() => {
    if (onUpdate) onUpdate(selectedUserIds);
  }, [selectedUserIds]);

  const handleClick = async () => {
    try {
      await onClick?.(selectedUserIds);
    } catch (error) {
      debugService
        .getState()
        .logError(
          `UserSearchPanel::handleClick(): Failed creating new chat = ${error}`
        );

      notificationService
        .getState()
        .addNotification(
          <StatusNotification
            text={
              "Sorry we could not create the chat because the selected users do not share the same space."
            }
            type={"danger"}
          />,
          { position: "right" }
        );

      // reset search
      isLoading = false;
      navService.getState().closeAllPanels();
    }
  };

  const onScrollBottom = async () => {
    if (!updateLock) {
      try {
        setUpdateLock(true);
        await userService.getState().requestUsersByCount(12);
        setTimeout(() => {
          setUpdateLock(false);
        }, 250);
      } catch (error) {
        if (error !== TUserRequestInfo.DATABASE_PARITY) {
          debugService
            .getState()
            .logError(`UserSearchPanel::onScrollBottom(): ${error}`);
        }
      }
    }
  };

  useEffect(() => {
    if (users?.size < 12) onScrollBottom();
  }, [users]);

  return (
    <StyledUserSearchPanel
      header={
        <PanelHeader showBackButton title={title}>
          <Search text={searchUsername} setSearchedText={setSearchUsername} />
        </PanelHeader>
      }
      body={
        <UserList
          onlyInCurrentModule={onlyInCurrentModule}
          activeUsers={activeUsers}
          users={filteredUsers}
          handleSelection={(selectedUsers) => {
            setSelectedUserIds(selectedUsers);
          }}
        />
      }
      footer={
        <ButtonContainer>
          <Button
            text={ctaText}
            handleClick={handleClick}
            fullWidth
            disabled={disableButton || selectedUserIds.size === 0 || isLoading}
          />
        </ButtonContainer>
      }
      onScrollBottom={onScrollBottom}
    />
  );
};

export default UserSearchPanel;
