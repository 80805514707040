import styled, { css } from "styled-components";

import { ease } from "@/styles/ease";
import { Hover } from "@/styles/mixins";

export const StyledSpaceTile = styled.div`
  position: relative;
  transform: translate(var(--x), var(--y));
  width: fit-content;
  height: fit-content;

  ${Hover(css`
    z-index: 2;
  `)}
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
`;

export const ToggleStyle = css`
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  position: absolute;
  transition: all 0.3s ${ease.inOutCubic};

  ${(props) =>
    !props.isVisible &&
    css`
      opacity: 0;
      transform: translate(-50%, 2rem);
      pointer-events: none;
    `}
`;
