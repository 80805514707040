import styled from "styled-components";

import AspectRatio from "@/components/Dom/Common/AspectRatio";
import Button from "@/components/Dom/Common/Button";
import { StyledSasImage } from "@/components/Dom/Common/SasImage/styles";

import { colors } from "@/styles/colors";
import { RandomColor } from "@/styles/mixins";

export const ImageButton = styled(Button)`
  margin: -1rem auto 0;
`;

export const Initials = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  font-weight: 700;
  text-transform: uppercase;
`;

export const ProfilePicture = styled(AspectRatio)`
  ${RandomColor};

  background-color: ${colors.grey.lighter};
  border-radius: 100%;
  margin: 1rem auto 0;
  width: 13rem;

  ${Initials} {
    user-select: none;
  }
`;

export const StyledProfilePictureUpload = styled.div`
  position: relative;

  ${StyledSasImage} {
    border-radius: var(--map-grid-image-radius);
  }
`;
