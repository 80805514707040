import { ReactNode } from "react";

import { StyledCloseButton } from "@/components/Dom/Host/styles";

export interface IHostLayout {
  children: ReactNode;
}

const HostLayout = ({ children }: IHostLayout) => {
  return (
    <main>
      {children}
      <StyledCloseButton />
    </main>
  );
};

export default HostLayout;
