import styled from "styled-components";

import { colors } from "@/styles/colors";

export const StyledSetup = styled.div`
  background: url("${(props) => props.bgImage}");
  background-color: ${colors.grey.veryLight.hex};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
`;
