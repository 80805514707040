import styled, { css } from "styled-components";

import { colors } from "@/styles/colors";
import {
  Indicator,
  IndicatorPanelOpen,
  MQ,
  TruncateText,
} from "@/styles/mixins";
import { Text } from "@/styles/typography";

export const MainNavItemAvatar = styled.div`
  display: flex;
  grid-area: avatar;
  height: 100%;
  justify-self: center;

  ${(props) => props.isPanelOpen && IndicatorPanelOpen}
  ${(props) => props.isActive && Indicator}
`;

export const MainNavTitle = styled.p`
  color: inherit;
  font-size: 0.9rem;
  font-weight: 700;
  transition: color 0.25s linear;
  text-align: left;

  [data-section-open="true"] & {
    width: 100%;
  }

  ${(props) =>
    props.subtitle &&
    css`
      align-self: flex-end;
      margin-bottom: 0.1rem;
    `}

  &:nth-child(2):last-child {
    grid-row: 1 / span 2;
  }

  ${TruncateText};

  ${MQ.MD`font-size: 1.2rem`}
`;
export const MainNavSubtitle = styled.p`
  color: ${colors.grey.normal.hex};
  font-size: 0.9rem;
  ${TruncateText};
  transition: color 0.25s linear;
  align-self: flex-start;
  margin-top: 0.1rem;

  ${MQ.MD`font-size: 1.2rem`}
`;

export const InputError = styled(Text)`
  color: ${colors.red.normal.hex};

  &[data-hidden="true"] {
    opacity: 0;
    visibility: hidden;
  }
`;
