import { FC, ReactNode } from "react";

import { StyledButtonPill } from "./styles";

interface IButtonPill {
  onClick: () => void;
  children: ReactNode;
}

const ButtonPill: FC<IButtonPill> = ({
  onClick = () => {},
  children,
  ...others
}) => {
  return (
    <StyledButtonPill onClick={onClick} {...others}>
      {children}
    </StyledButtonPill>
  );
};

export default ButtonPill;
