import styled from "styled-components";

import { ButtonRoundVideo } from "@/components/Dom/VideoChat/Common/styles";

export const StyledPopover = styled.div`
  position: relative;
`;

export const StyledOptionButton = styled(ButtonRoundVideo)`
  z-index: 2;
`;
