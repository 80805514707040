import styled, { css } from "styled-components";

import CloseEditorButton from "@/components/Dom/Host/CloseEditorButton";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";
import { SmallHeadlineStyles } from "@/styles/typography";

export const LabelStyles = css`
  color: ${colors.white.default};
  display: block;
  font-family: var(--font-serif);
  font-size: 2rem;
  font-weight: 400;
`;

export const StyledLabel = styled.label`
${LabelStyles}
`;

export const StyledRoundedCornerButton = css`
  ${SmallHeadlineStyles};
  align-items: center;
  background: transparent;
  border-radius: 2rem;
  color: ${colors.grey.light.hex};
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  width: 100%;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledButtonIcon = css`
  align-items: center;
  background: ${colors.grey.low.hex};
  border-radius: 100%;
  color: ${colors.grey.light.hex};
  display: flex;
  justify-content: center;
  height: 3rem;
  transition: background 0.3s ${ease.inOutCubic}, color 0.3s ${ease.inOutCubic};
  width: 3rem;
`;

export const StyledHost = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("/assets/images/host_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: min-content auto;
  grid-template-rows: auto 1fr;
  height: 100vh;
  padding: 3rem;
  position: relative;
  width: 100vw;

  &[data-space-selected="true"] {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("/assets/images/host_background.jpg");
    background-size: cover;
  }
`;

export const StyledCloseButton = styled(CloseEditorButton)`
  position: absolute;
  right: 3rem;
  top: 3rem;

  [data-full-width="true"] + & {
    right: 5rem;
    top: 5rem;
  }
`;
