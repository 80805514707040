import { FC, useRef, useEffect, useState } from "react";
import { LocalParticipant, RemoteParticipant } from "twilio-video";

import AvatarImage from "@/components/Dom/Common/AvatarImage";
import UserName from "@/components/Dom/Common/UserName";

import debugService from "@/services/DebugService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import videoService from "@/services/VideoService";
import { TMessage, EMessageType } from "@/services/VideoService/types";

import {
  StyledVideoWindowParticipant,
  Video,
  TextWrapper,
  VideoPlaceholder,
  MutedWrapper,
  RaisedHand,
  ReconnectWarning,
} from "./styles";

import Microphone from "~/public/assets/icons/Microphone.svg";
import RaiseHandIcon from "~/public/assets/icons/RaiseHand.svg";

interface IProps {
  participant: RemoteParticipant | LocalParticipant;
  local?: boolean;
  isReconnecting?: boolean;
  isVisible: boolean;
  render: boolean;
}

const VideoWindowParticipant: FC<IProps> = ({
  participant,
  local = false,
  isReconnecting = false,
  isVisible,
  render,
}) => {
  const [user, setUser] = useState<TUser | null>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [hasCamera, setHasCamera] = useState<boolean>(true);
  const [hasRaisedHand, setHasRaisedHand] = useState<boolean>(false);
  const [isDominantSpeaker, setIsDominantSpeaker] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const dominantSpeaker = videoService((state) => state.dominantSpeaker);

  const startTrack = (track) => {
    if (!track?.kind) return;
    // console.warn("VideoWindowParticipant::startTrack():");

    switch (track.kind) {
      case "audio":
        track.attach(audioRef.current);
        setIsMuted(false);
        break;
      case "video":
        if (!track.name.includes("screen")) {
          track.attach(videoRef.current);
          setHasCamera(true);
        }
        break;
      case "data":
        track.on("message", (message) => {
          const messageData: TMessage = JSON.parse(message);
          if (messageData.type === EMessageType.RAISE_HAND) {
            //@ts-expect-error
            setHasRaisedHand(messageData.data.status);
          }
        });
        break;
    }
  };
  const stopTrack = (track) => {
    if (!track?.kind) return;
    // console.warn("VideoWindowParticipant::stopTrack():");

    // console.log(
    //   userService.getState().buildUserName(user),
    //   `isVisible: ${isVisible}`,
    //   `render: ${render}`
    // );

    switch (track.kind) {
      case "audio":
        track.detach();
        setIsMuted(true);
        break;
      case "video":
        if (!track.name.includes("screen")) {
          track.detach();
          setHasCamera(false);
        }
        break;
    }
  };

  const onTrackEnabled = (track) => {
    if (!track?.kind) return;
    // console.log("onTrackEnabled");
    track.kind === "audio" && setIsMuted(false);
  };
  const onTrackDisabled = (track) => {
    if (!track?.kind) return;
    // console.log("onTrackDisabled");
    track.kind === "audio" && setIsMuted(true);
  };

  useEffect(() => {
    if (!isVisible || !user) return;

    participant.videoTracks.forEach((publication) => {
      if (!publication) return;

      if (publication.isTrackEnabled && publication.trackName !== "screen") {
        startTrack(publication.track);
      }
    });
  }, [isVisible]);

  useEffect(() => {
    if (!user || local) return;

    participant.videoTracks.forEach(({ track }) => {
      if (!track) return;
      if (track.name !== "screen") {
        render ? startTrack(track) : stopTrack(track);
      }
    });
  }, [render]);

  useEffect(() => {
    dominantSpeaker === participant.sid
      ? setIsDominantSpeaker(true)
      : setIsDominantSpeaker(false);
  }, [dominantSpeaker, participant]);

  useEffect(() => {
    if (!participant) {
      debugService
        .getState()
        .logError("VideoWindowParticipant::useEffect(): Participant is null.");
      return;
    }

    const init = async () => {
      const user = await userService
        .getState()
        .getUserById(parseInt(participant.identity, 10));

      if (user) setUser(user);

      if (!local) {
        participant.tracks.forEach((publication) => {
          if (!publication) return;
          if (publication.isSubscribed) startTrack(publication.track);
        });

        participant.on("trackSubscribed", startTrack);
        participant.on("trackUnsubscribed", stopTrack);
      } else {
        participant.tracks.forEach((publication) => {
          if (!publication) return;
          if (publication.isTrackEnabled) startTrack(publication.track);
        });

        participant.on("trackPublished", ({ track }) => startTrack(track));
        participant.on("trackStopped", stopTrack);
      }
      // audio only
      setIsMuted(!participant.audioTracks.size);

      participant.on("trackEnabled", onTrackEnabled);
      participant.on("trackDisabled", onTrackDisabled);
    };

    init();

    return () => {
      if (participant.tracks) {
        participant.tracks.forEach(({ track }) => {
          if (!track) return;
          if (track.kind !== "data") track.detach();
        });
      }
    };
  }, [participant]);

  return (
    <StyledVideoWindowParticipant
      render={isVisible}
      dominantSpeaker={isDominantSpeaker}
    >
      {!local && <audio ref={audioRef} autoPlay={true} muted={isMuted} />}

      {isVisible && (
        <>
          <Video
            visible={hasCamera}
            ref={videoRef}
            autoPlay={true}
            playsInline
            muted={true}
            isLocal={local}
          />
          {user && (
            <VideoPlaceholder>
              <AvatarImage users={user} />
            </VideoPlaceholder>
          )}

          {isMuted && (
            <MutedWrapper>
              <Microphone />
            </MutedWrapper>
          )}
          {user && !local && (
            <TextWrapper>
              <RaisedHand isVisible={hasRaisedHand}>
                <RaiseHandIcon />
              </RaisedHand>
              <UserName users={[user]} bold />
            </TextWrapper>
          )}

          {isReconnecting && <ReconnectWarning>Reconnecting</ReconnectWarning>}
        </>
      )}
    </StyledVideoWindowParticipant>
  );
};

export default VideoWindowParticipant;
