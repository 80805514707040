import styled from "styled-components";

import { TextStyles } from "@/styles/typography";

export const StyledTextAreaWrapper = styled.div`
  align-content: end;
  display: grid;
  align-items: end;
  justify-content: center;
  height: ${({ maxHeight }) => (maxHeight ? maxHeight : "unset")};
  min-height: ${({ maxHeight }) =>
    maxHeight ? "var(--chat-panel-input-height)" : "unset"};
  overflow: ${(props) => (props.maxHeight ? "auto" : "hidden")};
  padding: ${(props) =>
    props.maxHeight ? "0.5rem 0 0.5rem 0" : "1.1rem 0 1.1rem 0"};
`;

export const StyledTextArea = styled.textarea`
  ${TextStyles};
  align-items: center;
  background: transparent;
  box-sizing: border-box;
  display: flex;
  grid-area: 1 / 1 / 2 / 2;
  overflow: hidden;
  padding: 0;
  resize: none;
  width: 100%;
`;
