import styled from "styled-components";

import { colors } from "@/styles/colors";
import { SmallHeadline } from "@/styles/typography";

export const StyledCloseEditorButton = styled(SmallHeadline)`
  align-items: center;
  background: ${colors.grey.low.hex};
  border-radius: 3rem;
  color: ${colors.white.default};
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.5rem 2.4rem 0.4rem 0.6rem;
  text-transform: uppercase;
  z-index: 1;
  cursor: pointer;
`;

export const StyledCloseEditorIcon = styled.span`
  align-items: center;
  border-radius: 100%;
  background: ${colors.grey.light.hex};
  color: ${colors.grey.darker.hex};
  display: flex;
  height: 3rem;
  justify-content: center;
  width: 3rem;
`;
