import { StyledSection } from "../styles";
import ChatsEmptyTitle from "./ChatsEmptyTitle";

import CollapseSection from "@/components/Dom/MainNav/CollapseSection";
import ChatsNavItem from "@/components/Dom/MainNav/NavItemList/ChatsNavItem";
import { StyledNavItemList } from "@/components/Dom/MainNav/NavItemList/style";

import chatService, { EChatType } from "@/services/ChatService";
import { ENavSection } from "@/services/NavService";
import { getNewMessageCount } from "@/utils/Chat";

import ChatBubbles from "~/public/assets/icons/ChatBubbles.svg";

const ChatSection = () => {
  const chats = chatService((state) =>
    state
      .getChatsByType(EChatType.PRIVATE)
      .sort((chatA, chatB) => chatB.lastActivity - chatA.lastActivity)
  );

  return (
    <CollapseSection
      icon={<ChatBubbles />}
      newMsgCount={getNewMessageCount(chats)}
      // @ts-expect-error typing issue
      title={chats?.length > 0 ? "Chats" : <ChatsEmptyTitle title="Chats" />}
      section={ENavSection.CHATS}
      hasItems={chats?.length > 0}
      hasPlusButton={true}
    >
      <StyledSection style={{ maxHeight: 185, overflow: "hidden scroll" }}>
        <StyledNavItemList>
          {chats.map((chat) => (
            <ChatsNavItem key={chat.id} chat={chat} />
          ))}
        </StyledNavItemList>
      </StyledSection>
    </CollapseSection>
  );
};

export default ChatSection;
