import { FC, HTMLAttributes } from "react";
import { FormProvider, useForm } from "react-hook-form";

import Button from "@/components/Dom/Common/Button";
import ProfilePanelContent from "@/components/Dom/OverviewPanel/PanelContent/ProfilePanelContent";

import authService from "@/services/AuthService";
import navService from "@/services/NavService";
import userService from "@/services/UserService";

import { Footer, Header, StyledProfilePanel } from "./styles";

type TProfilePanel = HTMLAttributes<HTMLDivElement>;

interface IProps {}

const ProfilePanel: FC<IProps & TProfilePanel> = () => {
  const form = useForm({ mode: "onChange" });

  const handleSignOut = async () => {
    authService.getState().signOut("/setup/signin");
  };

  const handleSubmit = () => {
    userService.getState().setOwnUser(form.getValues());
    userService.getState().setOwnUserData(form.getValues());
    navService.getState().setPanel(null);
  };

  return (
    <FormProvider {...form}>
      <StyledProfilePanel
        header={<Header title="Your profile" />}
        body={<ProfilePanelContent form={form} />}
        footer={
          <Footer>
            <Button
              outline
              text="Sign out"
              type="submit"
              fullWidth
              onClick={handleSignOut}
            />
            <Button
              text="Save and Close"
              type="submit"
              fullWidth
              onClick={handleSubmit}
            />
          </Footer>
        }
      />
    </FormProvider>
  );
};

export default ProfilePanel;
