import { useWindowWidth } from "@react-hook/window-size";
import { isMobile, isIE } from "react-device-detect";

import {
  StyledErrorOverlay,
  ContentWrapper,
} from "@/components/Dom/ErrorOverlay/styles";

import { BigHeadline, Text } from "@/styles/typography";

const ErrorOverlay = () => {
  const windowWidth = useWindowWidth();

  if (!windowWidth) return null;

  return isMobile || isIE || windowWidth < 769 ? (
    <StyledErrorOverlay>
      <ContentWrapper>
        <BigHeadline bold>
          Your browser or device is not officially supported
        </BigHeadline>
        <Text>For the best experience, please use Chrome on Desktop</Text>
      </ContentWrapper>
    </StyledErrorOverlay>
  ) : null;
};

export default ErrorOverlay;
