import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import Button from "@/components/Dom/Common/Button";

import authService from "@/services/AuthService";
import navService, {
  EOverviewPanel,
  TMetaNavContent,
} from "@/services/NavService";
import spaceService from "@/services/SpaceService";
import { TSpace } from "@/services/SpaceService/types";
import userService from "@/services/UserService";
import { useSasParams } from "@/utils/Azure";

import {
  StyledAvatar,
  StyledUserProfile,
  StyledOnlineIndicator,
  StyledFloatingBar,
  StyledFloatingProfile,
  StyledPrivacyLinks,
  StyledButtonRound,
} from "./styles";

import SignOut from "~/public/assets/icons/SignOut.svg";

type TAvatarImage = React.HTMLAttributes<HTMLButtonElement>;

interface IProps {}

const FloatingProfile: React.FC<IProps & TAvatarImage> = () => {
  const router = useRouter();
  const ownUser = userService((state) => state.ownUser);
  const [isVisible, setIsVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const currentOverviewPanel = navService.getState().currentOverviewPanel;
  // get meta nav content from space if we are in one or fallback to global one
  const metaNavContent = spaceService((state) =>
    state.currentSpaceId
      ? (state.getMetaNavContent(
          state.currentSpaceId as TSpace["id"]
        ) as TMetaNavContent)
      : navService.getState().metaNavContent
  );
  const termsConditions = useSasParams(metaNavContent.termsConditions?.url);
  const privacyPolicy = useSasParams(metaNavContent.privacyPolicy?.url);
  const faq = useSasParams(metaNavContent.faq?.url);

  useEffect(() => {
    setIsVisible(currentOverviewPanel !== EOverviewPanel.PROFILE);
  }, [currentOverviewPanel]);

  const handleOpenProfile = () => {
    navService.getState().setPanel(EOverviewPanel.PROFILE);
  };

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    if (router.query.module) setIsExpanded(false);
  };

  const handleSignOut = async () => {
    authService.getState().signOut("/setup/signin");
  };

  useEffect(() => {
    setIsExpanded(() => {
      if (router.query.module) return false;
      if (router.query.space) return true;
      if (router.asPath === "/") return true;
      return false;
    });
  }, [router.asPath]);

  if (!ownUser || !metaNavContent) return null;

  return (
    <StyledFloatingProfile
      data-visible={isVisible}
      data-expanded={isExpanded}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledFloatingBar>
        <StyledUserProfile>
          <StyledAvatar users={ownUser} />
          <StyledOnlineIndicator data-online={true} />
        </StyledUserProfile>

        <Button text={"My profile"} onClick={handleOpenProfile} />

        <StyledButtonRound
          icon={<SignOut />}
          onClick={handleSignOut}
          big={true}
        />
      </StyledFloatingBar>
      <StyledPrivacyLinks>
        {privacyPolicy.urlWithParams && (
          <a
            href={`${privacyPolicy.urlWithParams}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        )}

        {metaNavContent.legalNoticeUrl && (
          <a
            href={`${metaNavContent.legalNoticeUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Legal Notice
          </a>
        )}

        {termsConditions.urlWithParams && (
          <a
            href={`${termsConditions.urlWithParams}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>
        )}

        {faq.urlWithParams && (
          <a
            href={`${faq.urlWithParams}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            FAQ
          </a>
        )}
      </StyledPrivacyLinks>
    </StyledFloatingProfile>
  );
};

export default FloatingProfile;
