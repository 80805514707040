import styled from "styled-components";

import { colors } from "@/styles/colors";
import { Hover } from "@/styles/mixins";

export const StyledCta = styled.div`
  position: relative;
`;

export const StyledButton = styled.button`
  --color: ${colors.grey.text.hex};

  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: button;
  height: 100%;
  overflow: unset;
  position: relative;

  position: relative;
  ${Hover(`--color: ${colors.tangerine.normal.hex}`)}

  &:disabled {
    cursor: auto;
  }
`;

export const StyledNewMessageCount = styled.span`
  align-items: center;
  background-color: ${colors.orange.hex};
  border-radius: 100%;
  color: ${colors.white.default};
  display: flex;
  font-size: 0.9rem;
  height: 2rem;
  justify-content: center;
  left: 0.4rem;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 50%);
  top: 0;
  width: 2rem;
  z-index: 2;
`;
