import styled from "styled-components";

export const StyledSpaceList = styled.ul`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-items: stretch;
  padding: 0.5rem 0 1rem;
  min-width: 100%;
`;
