import UserName from "@/components/Dom/Common/UserName";

import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";
import videoService from "@/services/VideoService";

import { Text } from "@/styles/typography";

import {
  StyledShareParticipantControls,
  LeftWrapper,
  ScreenShareIcon,
  StyledExpandButton,
} from "./styles";

import Expand from "~/public/assets/icons/Expand.svg";
import ScreenShare from "~/public/assets/icons/ScreenShare.svg";

type TShareParticipantControls = {
  user: TUser;
};

const ShareParticipantControls = ({ user }: TShareParticipantControls) => {
  if (!user) return null;

  return (
    <StyledShareParticipantControls>
      <LeftWrapper>
        <ScreenShareIcon>
          <ScreenShare />
        </ScreenShareIcon>

        <Text bold>
          {userService.getState().isSelf(user.id) ? (
            "You are presenting"
          ) : (
            <span>
              <UserName users={[user]} applyFontStyle={false} /> is presenting
            </span>
          )}
        </Text>
      </LeftWrapper>

      <StyledExpandButton
        icon={<Expand />}
        onClick={videoService.getState().toggleExtendedScreenShare}
      />
    </StyledShareParticipantControls>
  );
};

export default ShareParticipantControls;
