import * as React from "react";
import { useState } from "react";

import { StyledNewChatPanel } from "@/components/Dom/OverviewPanel/Panels/NewChatPanel/styles";

import chatService from "@/services/ChatService";
import navService, { EOverviewPanel } from "@/services/NavService";

type TNewChatPanel = React.HTMLAttributes<HTMLButtonElement>;
interface IProps {}

const NewChatPanel: React.FC<IProps & TNewChatPanel> = () => {
  const [isCreatingChat, setIsCreatingChat] = useState(false);

  const handleClick = async (selectedUsers): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {
      setIsCreatingChat(true);
      try {
        const chat = await chatService
          .getState()
          .createChat(Array.from(selectedUsers));

        navService.getState().setPanel(EOverviewPanel.CHATS, chat.id, 1);

        resolve(true);
      } catch (error) {
        reject(error);
      }

      setIsCreatingChat(false);
    });
  };

  return (
    <StyledNewChatPanel
      ctaText="Start chat"
      title={"Start new chat with ..."}
      isLoading={isCreatingChat}
      handleClick={handleClick}
    />
  );
};

export default NewChatPanel;
