import { spaceSections } from "../panelsSection";
import LockedSpacePanel from "./LockedSpacePanel";

import Tabs from "@/components/Dom/Common/Tabs";
import PanelHeader from "@/components/Dom/OverviewPanel/PanelHeader";

import chatService from "@/services/ChatService";
import navService, { EOverviewPanel } from "@/services/NavService";
import spaceService from "@/services/SpaceService";

import { StyledSpacePanel } from "./styles";

const SpacePanel = () => {
  const sections = spaceSections;
  const messages = chatService((state) => state.messages);
  const { module, tabIndex } = navService((state) => ({
    module: state.overviewPanels.get(EOverviewPanel.SPACES),
    tabIndex: state.tabIndex,
  }));

  if (!module) return null;

  const title = module.title;
  const space = spaceService.getState().getSpaceById(module.space);

  // @ts-ignore
  const overtitle = space?.title || module.space?.title;

  const handleTabClick = (index) => {
    navService.setState({ tabIndex: index });
  };

  return (
    <>
      {!module.canAccess ? (
        <StyledSpacePanel
          header={<PanelHeader title={title} overtitle={overtitle} />}
          body={<LockedSpacePanel title={title} />}
        />
      ) : (
        <StyledSpacePanel
          header={
            <PanelHeader title={title} overtitle={overtitle}>
              <Tabs
                tabSectionIndex={tabIndex}
                onClick={handleTabClick}
                tabSections={sections.map((section) => {
                  return { icon: section.icon };
                })}
              />
            </PanelHeader>
          }
          body={sections[tabIndex].body}
          footer={sections[tabIndex].footer}
          updateParameters={[messages, tabIndex]}
          scrollToBottomOnChange
        />
      )}
    </>
  );
};

export default SpacePanel;
