import styled from "styled-components";

import ChatInput from "@/components/Dom/Chat/ChatInput";
import FlexAndGrow from "@/components/Dom/Common/FlexAndGrow";

export const StyledChatPanel = styled(FlexAndGrow)`
  --gradient-bottom-height: 7rem;
  --flex-grow-body-padding: var(--mainNav-overview-panel-padding);
  width: var(--mainNav-panel-width);
`;

export const StyledChatInput = styled(ChatInput)`
  z-index: 5;
`;
