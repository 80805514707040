import React from "react";
import { UseFormReturn } from "react-hook-form";

import ProfilePictureUpload from "@/components/Dom/Common/ProfilePictureUpload";
import ProfilePanelSection from "@/components/Dom/OverviewPanel/PanelContent/ProfilePanelSection";
import ProfilePanelSettingsSection from "@/components/Dom/OverviewPanel/PanelContent/ProfilePanelSettingsSection";

import userService from "@/services/UserService";

import { Hero, StyledProfilePanelContent, SectionsWrapper } from "./styles";

type TProfileContent = React.HTMLAttributes<HTMLDivElement>;

interface IProps {
  form: UseFormReturn;
}

const ProfilePanelContent: React.FC<IProps & TProfileContent> = ({ form }) => {
  const { ownUser, ownUserData } = userService((state) => ({
    ownUser: state.ownUser,
    ownUserData: state.ownUserData,
  }));

  if (!ownUser || !ownUserData) return <></>;

  const about = [
    { headline: "First name", value: ownUser.firstname },
    { headline: "Last name", value: ownUser.lastname },
  ];

  const companyDetails = [
    { headline: "Company", value: ownUserData.company },
    { headline: "Department", value: ownUserData.department },
    { headline: "Position", value: ownUserData.position },
  ];

  return (
    <StyledProfilePanelContent>
      <Hero>
        <ProfilePictureUpload
          user={{ firstname: ownUser.firstname, lastname: ownUser.lastname }}
        />
      </Hero>

      <SectionsWrapper>
        <ProfilePanelSettingsSection title="Settings" form={form} />

        <ProfilePanelSection title="About" data={about} />
        <ProfilePanelSection
          title="Company Details"
          data={companyDetails}
          form={form}
        />
      </SectionsWrapper>
    </StyledProfilePanelContent>
  );
};

export default ProfilePanelContent;
