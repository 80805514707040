import { FC, useEffect } from "react";

import chatService from "@/services/ChatService";
import { TModule } from "@/services/ModuleService/types";
import { useUsersInModule } from "@/utils/Module";

interface IModuleNavItemSubtitle {
  setSubtitle: any;
  module: TModule;
}

const ModuleNavItemSubtitle: FC<IModuleNavItemSubtitle> = ({
  setSubtitle,
  module,
}) => {
  const users = useUsersInModule(module.id);

  const chat = chatService
    .getState()
    .chats.find((chat) => chat.id == module.chat);

  useEffect(() => {
    if (!chat) return;

    setSubtitle(
      chat?.newMessageCount
        ? `${chat.newMessageCount} new ${
            chat.newMessageCount === 1 ? "message" : "messages"
          }`
        : users.length
        ? `${users.length} in this module`
        : null
    );
  }, [chat, users]);

  return null;
};

export default ModuleNavItemSubtitle;
