import { useMemo } from "react";
import AnimateHeight from "react-animate-height";

import ModuleNavItem from "../ModuleNavItem";

import { StyledNavItemList } from "@/components/Dom/MainNav/NavItemList/style";

import moduleService from "@/services/ModuleService";
import { TSpace } from "@/services/SpaceService/types";

import { StyledSpaceNavItem } from "./styles";

type TSpaceNavItem = {
  space: TSpace;
  expanded: boolean;
};

const SpaceNavItem = ({ space, expanded = false }: TSpaceNavItem) => {
  if (!space) return null;

  const modules = moduleService((state) => state.modules);
  const defaultModule = moduleService
    .getState()
    .getModuleById(space.defaultModule);

  const nonDefaultModules = useMemo(
    () =>
      modules.filter(
        (module) =>
          module.space === space.id && // module is in space
          module.id !== space.defaultModule // module is not default
      ),
    [modules]
  );

  if (!defaultModule) return null;

  return (
    <StyledSpaceNavItem
      data-open={expanded}
      data-title={space.title}
      key={`space-nav-item-${module.id}`}
    >
      <ModuleNavItem
        aria-controls={`space-${space.id}`}
        module={defaultModule}
        expanded={expanded}
        title={expanded ? defaultModule.title : space.title}
        sectionTitle={space.title}
      />
      {nonDefaultModules.length > 0 && (
        <AnimateHeight
          id={`space-${space.id}`}
          duration={500}
          height={expanded ? "auto" : 0}
        >
          <StyledNavItemList>
            {nonDefaultModules.map((module) => (
              <li key={`module-${module.id}`}>
                <ModuleNavItem module={module} />
              </li>
            ))}
          </StyledNavItemList>
        </AnimateHeight>
      )}
    </StyledSpaceNavItem>
  );
};

export default SpaceNavItem;
