import React from "react";

import { StyledSetupLinks, A } from "@/components/Dom/Setup/SetupLinks/styles";

import navService from "@/services/NavService";

const SetupLinks = () => {
  const metaNavContent = navService((state) => state.metaNavContent);

  const handleLoginClick = () => {
    navService.getState().toggleSignInFrom();
  };

  if (!metaNavContent) return null;

  return (
    <StyledSetupLinks>
      <A bold href="#" onClick={handleLoginClick}>
        Login
      </A>
      {metaNavContent.privacyPolicy && (
        <A
          bold
          href={`${metaNavContent.privacyPolicy.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </A>
      )}

      {metaNavContent.legalNoticeUrl && (
        <A
          bold
          href={`${metaNavContent.legalNoticeUrl}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Legal Notice
        </A>
      )}

      {metaNavContent.termsConditions && (
        <A
          bold
          href={`${metaNavContent.termsConditions.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </A>
      )}

      {metaNavContent.faq && (
        <A
          bold
          href={`${metaNavContent.faq.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          FAQ
        </A>
      )}
    </StyledSetupLinks>
  );
};

export default SetupLinks;
