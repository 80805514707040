import Picker, { IEmojiData } from "emoji-picker-react";
import React from "react";

import { StyledEmojiPicker } from "./styles";

interface IProps {
  onEmojiPick: (event, emojiObject) => void;
}

const EmojiPicker: React.FC<IProps> = ({ onEmojiPick }) => {
  const handleEmojiPick = (
    event: React.MouseEvent,
    emojiObject: IEmojiData
  ) => {
    onEmojiPick(event, emojiObject);
  };

  return (
    <StyledEmojiPicker>
      <Picker
        groupVisibility={{
          recently_used: false,
        }}
        disableSkinTonePicker={true}
        // @ts-ignore ts(2322): bug in library's typing that doesn't take in account this prop
        searchPlaceholder="Search"
        onEmojiClick={handleEmojiPick}
        preload={true}
      />
    </StyledEmojiPicker>
  );
};

export default EmojiPicker;
