import CallEndButton from "@/components/Dom/VideoChat/Common/CallEndButton";
import CameraButton from "@/components/Dom/VideoChat/Common/CameraButton";
import MicrophoneButton from "@/components/Dom/VideoChat/Common/MicrophoneButton";
import OptionsButton from "@/components/Dom/VideoChat/Common/OptionsButton";
import ParticipantsButton from "@/components/Dom/VideoChat/Common/ParticipantsButton";
import RaiseHandButton from "@/components/Dom/VideoChat/Common/RaiseHandButton";
import ScreenshareButton from "@/components/Dom/VideoChat/Common/ScreenshareButton";

import videoService from "@/services/VideoService";
import { TVideoGrid } from "@/services/VideoService/types";

import {
  StyledVideoWindowControls,
  ControlsWrapper,
  ButtonFlyout,
} from "./styles";

const VideoWindowControls = () => {
  const videoGrid = videoService((state) => state.videoGrid);

  const renderLeftSide = () => {
    if (videoGrid === TVideoGrid.SMALL)
      return (
        <ButtonFlyout position={"left"}>
          <ParticipantsButton />
        </ButtonFlyout>
      );
    else
      return (
        <ControlsWrapper>
          <ParticipantsButton />
        </ControlsWrapper>
      );
  };

  const renderRightSide = () => {
    if (videoGrid === TVideoGrid.SMALL)
      return (
        <ButtonFlyout position={"right"}>
          <OptionsButton />
        </ButtonFlyout>
      );
    else
      return (
        <ControlsWrapper>
          <OptionsButton />
        </ControlsWrapper>
      );
  };

  return (
    <StyledVideoWindowControls grid={videoGrid}>
      {renderLeftSide()}

      <ControlsWrapper>
        <CameraButton />

        <MicrophoneButton />

        <CallEndButton />

        <ScreenshareButton />

        <RaiseHandButton />
      </ControlsWrapper>

      {renderRightSide()}
    </StyledVideoWindowControls>
  );
};

export default VideoWindowControls;
