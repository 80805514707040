import { useEffect, useState } from "react";

import {
  StyledImage,
  StyledSetupImageGallery,
} from "@/components/Dom/SetupImageGallery/styles";

import { TStrapiImage } from "@/types/StrapiImage";

const SetupImageGallery = ({ data }: { data: TStrapiImage[] | null }) => {
  const [images, setImages] = useState<TStrapiImage[]>([]);
  const [visibleImage, setVisibleImage] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (data?.length) {
      setImages(data);
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    const interval = setInterval(() => {
      setVisibleImage((index) => {
        const newIndex = index + 1;
        return newIndex === data?.length ? 0 : newIndex;
      });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [data]);

  return (
    <StyledSetupImageGallery isVisible={isVisible}>
      {images.map((image, i) => (
        <StyledImage
          key={image.id}
          src={image.url}
          isVisible={i === visibleImage}
        />
      ))}
    </StyledSetupImageGallery>
  );
};

export default SetupImageGallery;
