import styled from "styled-components";

import { colors } from "@/styles/colors";

export const StyledSpaceNavItem = styled.li`
  position: relative;

  &:before {
    --vertical-gap: 0.4rem;
    --horizontal-gap: 0.6rem;
    --width: calc(var(--mainNav-avatar-size) + 2 * var(--horizontal-gap));
    content: "";
    background: ${colors.grey.lightest.hex};
    border-radius: var(--width);
    height: calc(100% + 2 * var(--vertical-gap));
    left: calc(var(--mainNav-left-spacing) - var(--horizontal-gap));
    opacity: 0;
    position: absolute;
    top: calc(-1 * var(--vertical-gap));
    width: var(--width);
  }

  &[data-open="true"]:before {
    opacity: var(--background-opacity);
  }
`;
