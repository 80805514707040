import videoService from "@/services/VideoService";

import { colors } from "@/styles/colors";

import { StyledRaiseHandButton } from "./styles";

import RaiseHand from "~/public/assets/icons/RaiseHand.svg";

const RaiseHandButton = () => {
  const hasRaisedHand = videoService(
    (state) => state.localParticipant.hasRaisedHand
  );

  const handleClick = () => {
    videoService.getState().toggleRaisedHand();
  };

  return (
    <StyledRaiseHandButton
      disabled={false}
      icon={<RaiseHand />}
      iconWidth={18.6}
      iconHeight={13.3}
      iconColor={hasRaisedHand ? colors.white.default : colors.grey.normal.hex}
      backgroundColor={
        hasRaisedHand ? colors.tangerine.normal.hex : colors.grey.lightest.hex
      }
      big={true}
      onClick={handleClick}
    />
  );
};

export default RaiseHandButton;
