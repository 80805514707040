import { FC, HTMLAttributes, useState } from "react";

import { StyledAddToCallPanel } from "@/components/Dom/OverviewPanel/Panels/AddToCallPanel/styles";

import navService, { EOverviewPanel } from "@/services/NavService";
import spaceService from "@/services/SpaceService";
import videoService from "@/services/VideoService";

type TAddToCall = HTMLAttributes<HTMLButtonElement>;
interface IProps {}

const AddToCallPanel: FC<IProps & TAddToCall> = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const currentSpaceId = spaceService((state) => state.currentSpaceId || 0);

  const { allUsersCallState } = videoService((state) => ({
    allUsersCallState: state.allUsersCallState,
  }));

  const handleClick = async (selectedUserIds) => {
    const { invite, currentRoomId } = videoService.getState();
    if (!currentRoomId) return;

    invite(currentRoomId, [...selectedUserIds]);
    navService.getState().setPanel(EOverviewPanel.ADD_TO_CALL);
  };

  const onUpdate = (selectedUserIds) => {
    const userIds = [...selectedUserIds];

    setIsButtonDisabled(() => {
      if (!userIds) return true;
      if (!currentSpaceId) return true;

      //Check if one of the users is already in a call
      let allUsersAvailable = true;
      userIds.forEach((userId) => {
        if (allUsersCallState.get(userId)) allUsersAvailable = false;
      });
      if (!allUsersAvailable) return true;

      return false;
    });
  };

  return (
    <StyledAddToCallPanel
      ctaText={"Add to call"}
      title="Add to call"
      handleClick={handleClick}
      disableButton={isButtonDisabled}
      onUpdate={onUpdate}
      onlyInCurrentModule={true}
    />
  );
};

export default AddToCallPanel;
