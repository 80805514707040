import styled from "styled-components";

import { ButtonRoundVideo } from "@/components/Dom/VideoChat/Common/styles";

import { colors } from "@/styles/colors";

export const StyledParticipantsButton = styled(ButtonRoundVideo)`
  &:disabled {
    background-color: ${(props) => props.backgroundColor};
  }
`;

export const StyledParticipantsButtonSize = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: ${(props) =>
    props.isActive ? colors.grey.lightest.hex : colors.grey.dark.hex};
  color: ${colors.grey.dark.hex};
  border-radius: 100%;
  position: absolute;
  top: 1rem;
  right: -0.9rem;
  font-size: 1.2rem;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-sans-serif);
  line-height: 1em;
  color: ${(props) =>
    props.isActive ? colors.grey.dark.hex : colors.white.default};
`;
