import { FC } from "react";

import CameraButton from "@/components/Dom/VideoChat/Common/CameraButton";
import MicrophoneButton from "@/components/Dom/VideoChat/Common/MicrophoneButton";
import RaiseHandButton from "@/components/Dom/VideoChat/Common/RaiseHandButton";
import ScreenshareButton from "@/components/Dom/VideoChat/Common/ScreenshareButton";
import { ControlsWrapper } from "@/components/Dom/VideoChat/VideoWindow/VideoWindowControls/styles";

import onboardingService, {
  TOnboardingStep,
} from "@/services/OnboardingService";
import { TVideoGrid } from "@/services/VideoService/types";

import { colors } from "@/styles/colors";

import {
  FakeButtonWrapper,
  OnboardingCallEndButton,
  StyledOnboardingVideoWindowControls,
} from "./styles";

import Phone from "~/public/assets/icons/Phone.svg";

interface IOnboardingVideoWindowControls {}

const OnboardingVideoWindowControls: FC<IOnboardingVideoWindowControls> = () => {
  const onClick = () => {
    onboardingService.getState().setStep(TOnboardingStep.MEDIA);
  };

  return (
    <StyledOnboardingVideoWindowControls grid={TVideoGrid.SMALL}>
      <ControlsWrapper>
        <FakeButtonWrapper>
          <CameraButton />
        </FakeButtonWrapper>

        <FakeButtonWrapper>
          <MicrophoneButton />
        </FakeButtonWrapper>

        <OnboardingCallEndButton
          icon={<Phone />}
          iconWidth={15}
          iconHeight={15}
          big={true}
          backgroundColor={colors.red.normal.hex}
          onClick={onClick}
        />

        <FakeButtonWrapper>
          <ScreenshareButton />
        </FakeButtonWrapper>

        <FakeButtonWrapper>
          <RaiseHandButton />
        </FakeButtonWrapper>
      </ControlsWrapper>
    </StyledOnboardingVideoWindowControls>
  );
};

export default OnboardingVideoWindowControls;
