import * as React from "react";

import AvatarImage from "@/components/Dom/Common/AvatarImage";
import {
  ProfileImage,
  StyledChatPanelProfileHero,
} from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelProfileHero/styles";

import { TUser } from "@/services/UserService/types";

const ChatPanelProfileHero = ({ user }: { user: TUser }) => {
  const { profileImage } = user;

  return (
    <StyledChatPanelProfileHero>
      {profileImage ? (
        <ProfileImage
          image={profileImage}
          options={{ aspectRatio: 130 / 240, size: "small" }}
        />
      ) : (
        <AvatarImage users={user} />
      )}
    </StyledChatPanelProfileHero>
  );
};

export default ChatPanelProfileHero;
