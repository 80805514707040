import styled from "styled-components";

import { StyledAvatarImage } from "@/components/Dom/Common/AvatarImage/styles";
import SasImage from "@/components/Dom/Common/SasImage";

export const StyledChatPanelProfileHero = styled.section`
  position: relative;
  --avatar-size: 10.7rem;
  margin-bottom: 2.6rem;

  ${StyledAvatarImage} {
    cursor: auto;
  }
`;

export const ProfileImage = styled(SasImage)`
  border-radius: 1.3rem;
`;
