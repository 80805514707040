import styled, { css } from "styled-components";

import { StyledCallEndButton } from "@/components/Dom/VideoChat/Common/CallEndButton/styles";

import { TVideoGrid } from "@/services/VideoService/types";

import { colors } from "@/styles/colors";

export const StyledVideoWindowControls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${colors.white.default};
  border-bottom-right-radius: var(--video-window-radius);
  border-bottom-left-radius: var(--video-window-radius);
  ${(props) =>
    props.grid === TVideoGrid.SMALL
      ? css`
          justify-content: center;
        `
      : css`
          justify-content: space-between;
        `}
`;

export const ControlsWrapper = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 1rem;
  padding: 1rem;
  min-width: 6rem;

  ${StyledCallEndButton} {
    margin: 0 3rem;
  }
`;

export const ButtonFlyout = styled.div`
  position: absolute;
  bottom: 7rem;
  z-index: 2;
  ${(props) =>
    props.position === "right"
      ? css`
          right: 1rem;
        `
      : css`
          left: 1rem;
        `}
`;
