import styled, { css } from "styled-components";

import UserName from "@/components/Dom/Common/UserName";

import { colors } from "@/styles/colors";
import { SmallHeadlineStyles, Text } from "@/styles/typography";

export const StyledUser = styled.div`
  position: relative;
  ${SmallHeadlineStyles};

  align-items: center;
  column-gap: 1.5rem;
  display: grid;
  grid-template-rows: 2rem 2rem;
  grid-template-columns: 4rem auto;
  cursor: pointer;

  > *:first-child {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    height: 100%;
    width: 100%;
  }

  &:before {
    content: "";
    opacity: 0;
    background-color: ${colors.grey.lightest.hex};
    border-radius: 4rem;
    height: calc(100% + 1rem);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s linear;
    width: calc(100% + 1rem);
    z-index: -1;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export const StyledUserName = styled(UserName)`
  ${(props) =>
    props.subtitle &&
    css`
      align-self: flex-end;
    `}

  &:not(:last-child) {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }

  &:last-child {
    grid-row: 1 / span 2;
  }
`;

export const Subtitle = styled(Text)`
  align-self: flex-start;
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
`;
