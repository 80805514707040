import NotificationCenter from "@/components/Dom/Notifications/NotificationCenter";

const Notifications = () => {
  return (
    <>
      <NotificationCenter position="top" />
      <NotificationCenter position="right" />
      <NotificationCenter position="bottom" />
    </>
  );
};

export default Notifications;
