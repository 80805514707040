import styled, { css } from "styled-components";

import { ease } from "@/styles/ease";

export const StyledSidebarPanel = styled.div`
  position: absolute;
  background-color: white;
  top: var(--mainNav-margin);
  left: var(--mainNav-margin);
  width: 39rem;
  border-radius: var(--sidebar-panel-border-radius);
  overflow: hidden;
  ${(props) =>
    !props.isVisible &&
    css`
      transform: translateX(calc(-100% - var(--mainNav-margin)));
    `}
  transition: transform 0.6s ${ease.inOutQuint};
`;
