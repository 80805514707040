// This component was written based on the following article
// https://medium.com/@lucasalgus/creating-a-custom-auto-resize-textarea-component-for-your-react-web-application-6959c0ad68bc

import React, { RefObject, useEffect, useState } from "react";

import { pxToRem } from "@/utils/Math";

import { StyledTextArea, StyledTextAreaWrapper } from "./styles";

interface IProps {
  name: string;
  maxHeight?: string | null;
  maxLength?: number;
  value: string;
  onBlur?: (event) => void;
  onChange: (value: string) => void;
  onKeyDown: (event) => void;
}

const TextArea = (
  {
    name,
    value,
    onBlur,
    onChange,
    onKeyDown,
    maxLength,
    maxHeight = null,
    ...others
  }: IProps,
  ref: RefObject<HTMLTextAreaElement>
) => {
  const textAreaRef = ref;
  // these two help with expanding the text area
  const [textAreaHeight, setTextAreaHeight] = useState("auto");

  useEffect(() => {
    if (!textAreaRef.current) return;

    // if textarea is empty, height is auto
    if (value === "") {
      setTextAreaHeight("auto");
      return;
    }

    // otherwise sets is to scroll height
    setTextAreaHeight(pxToRem(textAreaRef.current!.scrollHeight));
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaHeight("auto");
    onChange(e.target.value);
  };

  return (
    <StyledTextAreaWrapper maxHeight={maxHeight} {...others}>
      <StyledTextArea
        id={name}
        maxLength={maxLength}
        name={name}
        ref={textAreaRef}
        rows={1}
        style={{ height: textAreaHeight }}
        maxHeight={maxHeight}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={onKeyDown}
      />
    </StyledTextAreaWrapper>
  );
};

export default React.forwardRef(TextArea);
