import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { getSession } from "next-auth/client";
import { ReactNode, useEffect } from "react";

import AvatarReactions from "@/components/Dom/AvatarReactions";
import CommunicationAreaCta from "@/components/Dom/CommunicationAreaCta";
import Debug from "@/components/Dom/Debug";
import DiscoverQuest from "@/components/Dom/DiscoverQuest";
import VrButton from "@/components/Dom/EnterVrScreen";
import ErrorOverlay from "@/components/Dom/ErrorOverlay";
import LoadingScreen from "@/components/Dom/LoadingScreen";
import Notifications from "@/components/Dom/Notifications";
import PopupOverlay from "@/components/Dom/PopupOverlay";

import DynamicScene from "@/layouts/AppLayout/DynamicScene";
import DefaultLayout from "@/layouts/AppLayout/RouteLayout/DefaultLayout";
import HostLayout from "@/layouts/AppLayout/RouteLayout/HostLayout";
import SetupLayout from "@/layouts/AppLayout/RouteLayout/SetupLayout";
import authService from "@/services/AuthService";
import debugService from "@/services/DebugService";
import initService from "@/services/InitService";
import loadingScreenService from "@/services/LoadingScreenService";
import userService from "@/services/UserService";
import { extractTokenFromUrlBar } from "@/utils/Misc";
import { buildDefaultSpaceSlug } from "@/utils/Space";

const MediaOverlay = dynamic(() => import("@/components/Dom/MediaOverlay"), {
  ssr: false,
});

export const ROUTES = {
  HOME: "/",
  HOST: "/host",
  SETUP: "/setup",
  SETUP_SIGN_IN: "/setup/signin",
  SETUP_INFO: "/setup/info",
  SETUP_AVATAR: "/setup/avatar",
};
export interface IAppLayout {
  pageProps: AppProps["pageProps"];
  children: ReactNode;
  enableMap: boolean;
  enableScene: boolean;
}

const AppLayout = ({
  pageProps,
  children,
  enableMap,
  enableScene,
}: IAppLayout) => {
  const router = useRouter();

  const checkSessionAndRedirect = async (session) => {
    // Store direct to the session for token users
    if (router.query.direct) {
      sessionStorage.setItem("direct", "true");
    }

    // If no session found
    if (!session) {
      // If already on the signIn page - just reveal the page
      if (router.pathname === ROUTES.SETUP_SIGN_IN) {
        // get token from hash
        const token: string = extractTokenFromUrlBar();

        const { access_token } = router.query;

        if (!token && !access_token) {
          loadingScreenService.getState().fadeOut();
        }
      }
      // Else redirect to signIn page
      else {
        await router.push(ROUTES.SETUP_SIGN_IN);
      }
    }
    // If session found
    else {
      // Stay on the page
      try {
        const isOnHostPage = router.asPath.startsWith(ROUTES.HOST);
        await initService.getState().init(session, isOnHostPage);
      } catch (error) {
        debugService.getState().logError(error);

        authService.getState().signOut();
      }

      if (!userService.getState().isOwnUserValid()) {
        await router.push(ROUTES.SETUP_INFO);
        return;
      }

      if (router.query.direct) {
        await router.push(buildDefaultSpaceSlug());
        return;
      }
    }
  };

  const openingTokenLinkFromSignInPage = (): boolean => {
    return (
      window.location.href.includes(ROUTES.SETUP_SIGN_IN) &&
      window.location.hash.substr(1).length > 0 &&
      window.history?.state?.url.includes(ROUTES.SETUP_SIGN_IN)
    );
  };

  const listenToPopstate = (): void => {
    if (openingTokenLinkFromSignInPage()) {
      location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);

  useEffect(() => {
    if (!router.isReady) return;
    const init = async () => {
      const session = await getSession();
      await checkSessionAndRedirect(session);
    };

    init();
  }, [router.isReady]);

  return (
    <>
      {enableScene && <DynamicScene />}

      <>
        {(() => {
          switch (true) {
            case router.pathname.startsWith(ROUTES.SETUP):
              return (
                <SetupLayout pageProps={pageProps}>{children}</SetupLayout>
              );
            case router.pathname.startsWith(ROUTES.HOST):
              return <HostLayout>{children}</HostLayout>;
            default:
              return (
                <DefaultLayout enableMap={enableMap} enableScene={enableScene}>
                  {children}
                </DefaultLayout>
              );
          }
        })()}
      </>

      <MediaOverlay />
      <Notifications />
      <Debug />
      <PopupOverlay />
      <ErrorOverlay />
      <LoadingScreen />
      <AvatarReactions />
      <DiscoverQuest />
      <CommunicationAreaCta />
      <VrButton />
    </>
  );
};

export default AppLayout;
