import styled, { css } from "styled-components";

import ButtonRound from "@/components/Dom/Common/ButtonRound";
import {
  StyledScreenshareParticipant,
  Video,
} from "@/components/Dom/VideoChat/VideoWindow/ShareParticipant/styles";
import { StyledShareParticipantControls } from "@/components/Dom/VideoChat/VideoWindow/ShareParticipantControls/styles";

import { colors } from "@/styles/colors";
import { ease } from "@/styles/ease";

const HiddenStyle = css`
  visibility: hidden;
  pointer-events: none;
`;

const ExtendedStyle = css`
  left: calc(calc(var(--mainNav-margin) * 2) + var(--mainNav-width));
  max-width: calc(
    100vw - 34rem - calc(calc(var(--mainNav-margin) * 2) + var(--mainNav-width)) -
      calc(var(--mainNav-margin) * 2)
  );
`;

const FullScreenStyle = css`
  z-index: 9;
  height: 100%;
  top: 0;
  border-radius: 0;
`;

export const StyledVideoFullscreen = styled.div`
  position: fixed;
  top: var(--mainNav-margin);
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-items: center;
  z-index: 8;
  background-color: ${colors.black.hex};
  height: calc(100% - var(--mainNav-margin) * 2);
  border-radius: var(--mainNav-border-radius);

  ${(props) => props.isExtended && ExtendedStyle};

  ${(props) => !props.isVisible && HiddenStyle};

  ${(props) => props.isFullscreen && FullScreenStyle};

  ${StyledScreenshareParticipant} {
    background-color: transparent;
  }

  ${StyledShareParticipantControls} {
    position: fixed;
    top: 2rem;
    left: 2rem;
    right: auto;
    bottom: auto;
    background-color: rgba(${colors.white.rgba}, 0.6);
  }

  ${Video} {
    object-fit: contain;
  }
`;

const CloseButtonHiddenStyle = css`
  transition: transform 0.35s ${ease.inCubic};
  transform: scale(0);
`;

const CloseButtonVisibleStyle = css`
  transition: transform 0.35s ${ease.outCubic};
  transition-delay: 0.7s;
  transform: scale(1);
`;

export const CloseButton = styled(ButtonRound)`
  --svg-size: 1.4rem;
  background-color: ${colors.grey.lightest.hex};
  color: ${colors.grey.normal.hex};
  height: 4rem;
  position: fixed;
  right: 4rem;
  top: 2rem;
  width: 4rem;
  z-index: 9;

  ${(props) =>
    props.isVisible ? CloseButtonVisibleStyle : CloseButtonHiddenStyle};
`;
