import { FC } from "react";

import InfoNotification from "@/components/Dom/Notifications/InfoNotification";

import notificationService from "@/services/NotificationService";
import onboardingService, {
  TOnboardingStep,
} from "@/services/OnboardingService";

import { StyledOnboardingCallButton } from "./styles";

import Phone from "~/public/assets/icons/PhoneRotated.svg";

interface IOnboardingCallButton {}

const OnboardingCallButton: FC<IOnboardingCallButton> = () => {
  const onClick = () => {
    onboardingService.setState({ showCallWindow: true });

    const { currentNotificationId, setStep } = onboardingService.getState();
    if (currentNotificationId) {
      const { removeNotification } = notificationService.getState();

      removeNotification(currentNotificationId);
    }

    const notificationData = {
      headline: "Video Call",
      text:
        "Now we are in a video call. Nice to see you. During a call you can share your screen, de/activate your cam/mic, blur your background, change the video grid and much more.",
      subText:
        "When you’re done, hang up and move to the second tutorial zone.",
      buttonText: "Next 3/5",
      imageUrl: "/assets/images/Tutorial-Image-4.jpg",
    };

    const notificationId = notificationService
      .getState()
      .addNotification(
        <InfoNotification
          {...notificationData}
          onButtonClick={() => setStep(TOnboardingStep.MEDIA)}
        />,
        {
          autoRemove: false,
          position: "bottom",
        }
      );

    onboardingService.setState({ currentNotificationId: notificationId });
  };

  return (
    <StyledOnboardingCallButton big onClick={onClick}>
      <Phone />
    </StyledOnboardingCallButton>
  );
};

export default OnboardingCallButton;
