import { useEffect, useState } from "react";
import React, { useCallback } from "react";

import ChatPanelGroupList from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelGroupList";
import ChatPanelUserProfile from "@/components/Dom/OverviewPanel/PanelContent/ChatPanelUserProfile";

import debugService from "@/services/DebugService";
import navService, { EOverviewPanel } from "@/services/NavService";
import userService from "@/services/UserService";
import { TUser } from "@/services/UserService/types";

const ChatPanelProfile = () => {
  const { overviewPanelData } = navService((state) => ({
    overviewPanelData: state.overviewPanels.get(EOverviewPanel.CHATS),
  }));

  if (!overviewPanelData) return null;

  const cachedUsers = userService((state) => state.cachedUsers);
  const [users, setUsers] = useState<TUser[]>([]);

  const init = useCallback(async (overviewPanelData) => {
    try {
      const users: TUser[] = await userService
        .getState()
        .getUsersByIds(overviewPanelData.users);
      setUsers(users);
    } catch (error) {
      debugService
        .getState()
        .logError(`ChatPanelProfile::init(): Failed to get users = ${error}`);
    }
  }, []);

  useEffect(() => {
    if (!overviewPanelData?.users) return;
    init(overviewPanelData);
  }, [cachedUsers, overviewPanelData?.users]);

  if (!users[0]) return null;

  return (
    <div>
      {/* Group Profile */}
      {users.length > 1 ? (
        <ChatPanelGroupList users={users} />
      ) : (
        <ChatPanelUserProfile user={users[0]} />
      )}
    </div>
  );
};

export default ChatPanelProfile;
