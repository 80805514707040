import Link from "next/link";
import React from "react";

import { ROUTES } from "@/layouts/AppLayout";

import { StyledCloseEditorButton, StyledCloseEditorIcon } from "./styles";

import PencilCrossed from "~/public/assets/icons/PencilCrossed.svg";

const CloseEditorButton: React.FC = ({ ...others }) => {
  return (
    <Link href={ROUTES.HOME}>
      <StyledCloseEditorButton {...others} as={"a"}>
        <StyledCloseEditorIcon>
          <PencilCrossed />
        </StyledCloseEditorIcon>
        Close editor
      </StyledCloseEditorButton>
    </Link>
  );
};

export default CloseEditorButton;
