import { useEffect, useState } from "react";

import inputService from "@/services/InputService";

import { colors } from "@/styles/colors";

import { InputWrapper, StyledInput, InputButton } from "./styles";

const Input = ({
  placeholder = "",
  text = "",
  type = "text",
  disabled = false,
  showButton = false,
  buttonIcon = <></>,
  error = false,
  handleChange,
  handleClick,
  backgroundColor = colors.white.default,
  ...others
}) => {
  const [value, setValue] = useState(text);

  useEffect(() => {
    handleChange?.(value);

    return () => {
      inputService.getState().enableInput();
    };
  }, [value]);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <InputWrapper backgroundColor={backgroundColor} error={error} {...others}>
      <StyledInput
        size="mid"
        type={type}
        value={value}
        error={error}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(event) => setValue(event.target.value)}
        onFocus={inputService.getState().lockInput}
        onBlur={inputService.getState().enableInput}
      />
      {showButton && (
        <InputButton
          onClick={() => {
            if (value) handleClick?.(value);
          }}
        >
          {buttonIcon}
        </InputButton>
      )}
    </InputWrapper>
  );
};

export default Input;
