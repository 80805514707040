import { useWindowWidth } from "@react-hook/window-size/throttled";
import { HTMLAttributes, FC, useState, useEffect, useRef } from "react";

import TabSection, {
  TTabSectionData,
} from "@/components/Dom/Common/Tabs/TabSection";

import { calcIndicatorPosition, StyledTabs } from "./styles";

type TTabs = HTMLAttributes<HTMLDivElement>;

interface IProps {
  tabSectionIndex: number;
  tabSections: Array<TTabSectionData>;
  onClick: (index?: number) => void;
}

const Tabs: FC<IProps & TTabs> = ({
  tabSectionIndex,
  tabSections,
  onClick = () => {},
}) => {
  const [curIndex, setCurIndex] = useState(tabSectionIndex);
  const [totalWidth, setTotalWidth] = useState(0);
  const [animate, setAnimate] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    setCurIndex(tabSectionIndex);
  }, [tabSectionIndex]);

  useEffect(() => {
    if (!wrapperRef?.current) return;
    setTotalWidth(wrapperRef.current.getBoundingClientRect().width);

    // Wait for one tick to start animating, otherwise the tab bar
    // Will animate in from the left
    process.nextTick(() => {
      setAnimate(true);
    });
  }, [wrapperRef.current, windowWidth]);

  const handleClick = (index) => {
    setCurIndex(index);
    onClick(index);
  };

  return (
    <StyledTabs
      ref={wrapperRef}
      style={{
        "--transition-time": animate ? "0.3s" : 0,
        "--translateX": calcIndicatorPosition(
          tabSections.length,
          curIndex,
          totalWidth
        ),
      }}
    >
      {tabSections.map((tabSection, index) => (
        <TabSection
          key={index}
          icon={tabSection.icon}
          selected={curIndex === index}
          handleClick={handleClick}
          index={index}
        />
      ))}
    </StyledTabs>
  );
};

export default Tabs;
