import React, { useEffect, useRef, useState } from "react";

import EmojiPicker from "@/components/Dom/EmojiPicker";

import chatService, { MAX_CONTENT_LENGTH } from "@/services/ChatService";
import debugService from "@/services/DebugService";
import inputService from "@/services/InputService";

import {
  StyledWrapper,
  StyledInput,
  StyledTextArea,
  StyledButton,
  StyledEmojiButton,
} from "./styles";

import Send from "~/public/assets/icons/Send.svg";
import Smiley from "~/public/assets/icons/Smiley.svg";

type TChatInput = React.HTMLAttributes<HTMLDivElement>;

interface IProps {}

const ChatInput: React.FC<IProps & TChatInput> = () => {
  const [text, setText] = useState("");
  const [cursor, setCursor] = useState(0); // keeps track where the cursor is in order to add emojis at the right place
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!textAreaRef.current) return;

    if (isEmojiPickerOpen) {
      textAreaRef.current.focus();
    }
  }, [isEmojiPickerOpen]);

  useEffect(() => {
    return () => inputService.getState().enableInput();
  }, []);

  const updatesCursorLocation = () => {
    if (!textAreaRef.current) return;
    setCursor(textAreaRef.current?.selectionStart);
  };

  const handleChange = (value) => {
    setText(value);
  };

  const handleSelect = () => {
    inputService.getState().lockInput();
  };

  const handleBlur = () => {
    inputService.getState().enableInput();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!e.shiftKey && e.key === "Enter") handleSubmit(e);
  };

  const handleSubmit = async (e) => {
    const sendText = text;
    setText("");

    e.preventDefault();
    setIsEmojiPickerOpen(false);

    if (sendText.trim() === "" || sendText.length >= MAX_CONTENT_LENGTH) return;

    try {
      await chatService.getState().sendMessage(sendText);
    } catch (error) {
      debugService.getState().logError(error);
    }
  };

  const toggleEmojiPicker = () => {
    setIsEmojiPickerOpen(!isEmojiPickerOpen);
  };

  const handleEmojiPick = (_event, emojiObject) => {
    if (!textAreaRef.current) return;

    // add emoji at the cursor position
    handleChange(
      text.slice(0, cursor) + emojiObject.emoji + text.slice(cursor)
    );
  };

  return (
    <>
      {isEmojiPickerOpen && <EmojiPicker onEmojiPick={handleEmojiPick} />}
      <StyledWrapper>
        <StyledInput>
          <StyledEmojiButton
            data-open={isEmojiPickerOpen}
            onClick={toggleEmojiPicker}
          >
            <Smiley />
          </StyledEmojiButton>
          <StyledTextArea
            name="chat"
            value={text}
            onBlur={handleBlur}
            onChange={handleChange}
            onClick={updatesCursorLocation}
            onKeyUp={updatesCursorLocation}
            onKeyDown={handleKeyDown}
            onSelect={handleSelect}
            maxHeight={isEmojiPickerOpen && "var(--input-height)"}
            maxLength={MAX_CONTENT_LENGTH}
            ref={textAreaRef}
          />
          <StyledButton
            onClick={handleSubmit}
            disabled={text.length >= MAX_CONTENT_LENGTH}
          >
            <Send />
          </StyledButton>
        </StyledInput>
      </StyledWrapper>
    </>
  );
};

export default ChatInput;
