import { FC, HTMLAttributes, useEffect, useState } from "react";

import ButtonRound from "@/components/Dom/Common/ButtonRound";
import PanelCallButton from "@/components/Dom/OverviewPanel/PanelCallButton";

import navService, { TPanelData } from "@/services/NavService";

import {
  StyledPanelHeader,
  Headline,
  Left,
  Right,
  Grow,
  PanelHeaderTop,
  StyledOvertitle,
} from "./styles";

import ArrowLeft from "~/public/assets/icons/ArrowLeft.svg";

type TPanelHeader = HTMLAttributes<HTMLDivElement>;
interface IProps {
  showBackButton?: boolean;
  showCallButton?: boolean;
  title: string | Element;
  overtitle?: string;
}

const PanelHeader: FC<IProps & TPanelHeader> = ({
  showBackButton = false,
  showCallButton = false,
  title,
  overtitle,
  children,
  ...others
}) => {
  const [data, setData] = useState<TPanelData | null | undefined>();
  const { currentOverviewPanel } = navService((state) => ({
    currentOverviewPanel: state.currentOverviewPanel,
  }));

  useEffect(() => {
    if (currentOverviewPanel !== null) {
      setData(navService.getState().overviewPanels.get(currentOverviewPanel));
    }
  }, [currentOverviewPanel]);

  return (
    <StyledPanelHeader {...others}>
      <PanelHeaderTop>
        {showBackButton && (
          <Left>
            <ButtonRound
              icon={<ArrowLeft />}
              onClick={() => navService.getState().closeAllPanels()}
            />
          </Left>
        )}
        <Grow>
          {overtitle && <StyledOvertitle>{overtitle}</StyledOvertitle>}
          <Headline bold>{title}</Headline>
        </Grow>

        {showCallButton && data && (
          <Right>
            <PanelCallButton data={data} />
          </Right>
        )}
      </PanelHeaderTop>

      {children && <div>{children}</div>}
    </StyledPanelHeader>
  );
};

export default PanelHeader;
